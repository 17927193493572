import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as BatchIcon } from '../../static/images/icons/icon-batch.svg';
import Button from '../Button/Button';
import FileInput from './FileInput';

import csvTemplate from '../../static/onmission-csv-template.csv';

import {
    selectCommunitySavingState,
    selectCommunityApiErrors
} from '../../state/communities/reducers';
import * as communityTypes from '../../state/communities/types';

import usePrevious from '../../hooks/usePrevious';

const styles = makeStyles(theme => ({
    batch: {
        cursor: 'pointer',
        backgroundColor: '#dcdcdc',
        borderRadius: 20,
        padding: '12px 18px'
    },
    backdrop: {
        zIndex: 1300
    },
    paper: {
        width: 300,
        padding: 20,
        borderRadius: 20,
        boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)'
    },
    noOutline: {
        outline: 'none !important'
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 10,
        color: '#838b96',
        fontWeight: 700,
        marginBottom: 10
    },
    info: {
        fontSize: 12,
        marginBottom: 20
    },
    error: {
        fontSize: 11,
        color: theme.palette.error.main
    },
    mb20: {
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: 150
    }
}));

export default function BatchUpload() {
    const dispatch = useDispatch();
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState([]);
    const [error, setError] = useState('');

    // Redux
    const saveState = useSelector(state => selectCommunitySavingState(state));
    const apiErrors = useSelector(state => selectCommunityApiErrors(state));

    function handleChange(e) {
        const { files } = e.target;
        setValue([...files]);
    }

    function handleClick(e) {
        setAnchorEl(e.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function submitData() {
        const formData = new FormData();
        formData.append('csv', value[0]);

        dispatch({
            type: communityTypes.BATCH_UPLOAD_USERS,
            formData
        });
    }

    function onSubmit() {
    // Very basic front end validation - check if file extension is .csv
        const csvRegex = RegExp('^.*.(.csv)$');
        const isCsv = csvRegex.test(value[0].name);
        if (isCsv) {
            if (error) setError('');
            submitData();
        } else setError('You must select a .CSV file');
    }

    // A bit of magic required to close modal when appropriate
    const prevSaving = usePrevious(saveState);
    useEffect(() => {
        if (prevSaving && !saveState && !apiErrors) {
            handleClose();
        }
    }, [prevSaving, saveState, apiErrors]);

    const canSubmit = Boolean(value.length);

    return (
    <>
      <IconButton className={classes.batch} onClick={handleClick}>
          <BatchIcon />
      </IconButton>
      <Backdrop open={Boolean(anchorEl)} className={classes.backdrop}>
          <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              classes={{ paper: classes.paper }}
              anchorOrigin={{ vertical: 50, horizontal: -245 }}
              getContentAnchorEl={null}
              onClose={handleClose}
          >
              <Grid container className={classes.noOutline}>
                  <Grid item xs={12}>
                      <Typography align="center" className={classes.label}>
                Batch create users
                      </Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <Typography align="center" className={classes.info}>
                You can download our CSV template{' '}
                          <a
                              href={csvTemplate}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                          >
                  here.
                          </a>
                      </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.mb20}>
                      <FileInput onChange={handleChange} value={value} />
                      {error && (
                          <Typography align="right" className={classes.error}>
                              {error}
                          </Typography>
                      )}
                  </Grid>
                  <Grid item xs={12} className={classes.buttonContainer}>
                      <Button
                          label="Add"
                          className={classes.button}
                          onClick={onSubmit}
                          disabled={!canSubmit || saveState}
                          loading={saveState}
                      />
                  </Grid>
              </Grid>
          </Menu>
      </Backdrop>
    </>
    );
}
