import { takeLatest, call, put, select } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';
import {selectTeams} from './reducers';


function* getCommunityTeams({ payload }) {
    try {
        const  { communityUuid } = payload
        const { data } = yield call(actions.getCommunityTeams, communityUuid)
        yield put({ type: types.GET_TEAMS_SUCCESS, payload: data.results })
    } catch (error) {
        console.error(error)
    }
    
}

function* getAllCommunityTeams({ communityId, leaderReq }) {
    try {
        // Only show loading if no data exists
        const teams = yield select(selectTeams);

        if (!teams.length) {
            yield put({ type: types.TEAM_LOADING_STATE, state: true });
        }

        const results= yield call(actions.getAllTeams, communityId, leaderReq);

        yield put({ type: types.GET_ALL_TEAMS_SUCCESS, teams: results });
        yield put({ type: types.TEAM_LOADING_STATE, state: false });
    } catch (error) {
        yield put({
            type: types.GET_ALL_TEAMS_ERROR,
            errors: {
                key: types.GET_ALL_TEAMS_ERROR,
                message: error.response.data.message,
                errors: error.response.data.errors
            }
        });
        yield put({ type: types.TEAM_LOADING_STATE, state: false });
    }
}


export default [
    takeLatest(types.GET_TEAMS, getCommunityTeams),
    takeLatest(types.GET_ALL_TEAMS, getAllCommunityTeams)
]