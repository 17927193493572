import * as types from './types';

const initialState = {
    open: false,
    content: null,
    props: {
        variant: 'info'
    }
}

const snackReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SNACK:
            return {
                ...state,
                content: action.content,
                open: action.open,
                props: action.props,
            };
        
        case types.SET_SNACK_CONTENT:
            return {
                ...state,
                content: action.content,
            };

        case types.SET_SNACK_STATE:
            return {
                ...state,
                open: action.open
            };

        default:
            return state;
    }
}

export default snackReducer;

export const selectSnackState = state => state.snack;
