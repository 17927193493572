import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import * as modalTypes from '@/state/modal/types';

const styles = makeStyles({
  flex: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    margin: '0px 32px'
  },
  icon: {
    color: '#ffffff'
  }
});

export default function DialogPaper({ children, className }) {
  const classes = styles();
  const dispatch = useDispatch();

  function handleClose() {
    dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
  }

  return (
    <div>
      <div className={classes.flex}>
        <IconButton onClick={handleClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      </div>
      <Paper className={className}>{children}</Paper>
    </div>
  );
}

DialogPaper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

DialogPaper.defaultProps = {
  className: ''
};
