import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        cursor: 'pointer',
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        padding: '10px 20px',
        minHeight: 40,
        marginBottom: 10,
        display: 'flex',
        '&:hover': {
            backgroundColor: '#dcdcdc'
        }
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 12,
        color: '#223f63'
    }
});

const CommunityRowCard = ({ title, onClick }) => {
    const classes = useStyles();
    return (
        <div className={classes.container} onClick={onClick} role="presentation">
            <Typography className={classes.text}>{title}</Typography>
        </div>
    );
};

CommunityRowCard.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

CommunityRowCard.defaultProps = {
    onClick: null
};

export default CommunityRowCard;
