import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Button from '../../components/Button/Button';
import SearchInput from '../../components/SearchInput/SearchInput';
import Table from '../../components/Table/Table';
import UserRowCard from '../../components/RowCards/UserRowCard';

import { ReactComponent as AddIcon } from '../../static/images/icons/icon-add.svg';

import * as modals from '../../constants/modals';
import * as modalTypes from '../../state/modal/types';
import * as userTypes from '../../state/users/types';
import * as communityTypes from '../../state/communities/types';
import {
    selectUsersLoadingState,
    selectUsers,
    selectTotalUsers,
    selectUsersSavingState
} from '../../state/users/reducers';

import { sortByArchived } from '../../utils/helpers';

class UserList extends Component {
    state = {
        search: '',
        page: 0
    };

    componentDidMount() {
        const { page, search } = this.state;
        const { getUsers, getCommunities } = this.props;
        getUsers(page, search);
        getCommunities();
    }

    menuActions = row => {
        const isOfficer = row.roles.includes('officer');
        const actions = [
            {
                label: 'Archive',
                onClick: () => this.onArchiveUser(row)
            },
            {
                label: isOfficer ? 'Remove officer role' : 'Assign officer role',
                onClick: () => this.onOfficer(row)
            }
        ];

        return row.isDeleted ? null : actions;
    };

    onOfficer = user => {
        const { assignOfficer, removeOfficer } = this.props;
        const isOfficer = user.roles.includes('officer');
        if (isOfficer) {
            removeOfficer(user._id);
        } else assignOfficer(user._id);
    };

    onArchiveUser = user => {
        const { _id: userId } = user;
        const { setModalComponent, openModal, archiveUser } = this.props;
        setModalComponent(modals.PROMPT_BOOLEAN_MODAL, {
            onYes: () => archiveUser(userId),
            title: 'Are you sure you want to archive this user?',
            savingSelector: selectUsersSavingState
        });
        openModal();
    };

    onAddUser = () => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.ADD_USER_MODAL);
        openModal();
    };

    onSearchChange = search => {
        this.setState(
            {
                page: 0,
                search
            },
            this.getNewUserList
        );
    };

    onPageChange = page => {
        this.setState({ page }, this.getNewUserList);
    };

    getNewUserList = () => {
        const { page, search } = this.state;
        const { getUsers } = this.props;
        getUsers(page, search);
    };

    render() {
        const { search, page } = this.state;
        const { loadingState, users, totalUsers } = this.props;

        const noDataLabel = search ? 'No results found' : 'There is no user data!';

        const computedUsers = sortByArchived(users);

        return (
            <Container>
                <Typography
                    style={{
                        fontFamily: 'SFUIDisplay-Bold',
                        fontSize: 36,
                        color: '#072144',
                        marginBottom: 36
                    }}
                >
                    Users
                </Typography>
                <Box display="flex" marginBottom={2}>
                    <SearchInput
                        style={{ flex: 1 }}
                        placeholder="Search all users..."
                        onChange={this.onSearchChange}
                        value={search}
                    />
                    <Button
                        label="Add"
                        onClick={this.onAddUser}
                        icon={<AddIcon />}
                        style={{ width: 180, marginLeft: 20 }}
                    />
                </Box>
                <Table
                    data={computedUsers}
                    rowCard={UserRowCard}
                    loading={loadingState}
                    menuActions={this.menuActions}
                    noDataLabel={noDataLabel}
                    page={page}
                    onPageChange={this.onPageChange}
                    count={totalUsers}
                    serverSidePagination
                />
            </Container>
        );
    }
}

UserList.propTypes = {
    users: PropTypes.array.isRequired,
    loadingState: PropTypes.bool.isRequired,
    setModalComponent: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    totalUsers: PropTypes.number.isRequired,
    getCommunities: PropTypes.func.isRequired,
    assignOfficer: PropTypes.func.isRequired,
    removeOfficer: PropTypes.func.isRequired,
    archiveUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    loadingState: selectUsersLoadingState(state),
    users: selectUsers(state),
    totalUsers: selectTotalUsers(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUsers: (page, search) => ({
                type: userTypes.GET_USERS,
                page,
                search
            }),
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            }),
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            getCommunities: () => ({
                type: communityTypes.GET_COMMUNITIES,
                page: 0,
                search: ''
            }),
            assignOfficer: userId => ({
                type: userTypes.ASSIGN_OFFICER_ROLE,
                userId
            }),
            removeOfficer: userId => ({
                type: userTypes.REMOVE_OFFICER_ROLE,
                userId
            }),
            archiveUser: userId => ({
                type: userTypes.ARCHIVE_USER,
                userId
            })
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
