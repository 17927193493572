export const ADD_COMMUNITY_MODAL = 'modal/ADD_COMMUNITY_MODAL';
export const EDIT_COMMUNITY_MODAL = 'modal/EDIT_COMMUNITY_MODAL';
export const ASSIGN_LEADER_MODAL = 'modal/ASSIGN_LEADER_MODAL';
export const ADD_USER_MODAL = 'modal/ADD_USER_MODAL';
export const BATCH_ADD_USER_MODAL = 'modal/BATCH_ADD_USER_MODAL';

export const INVITE_USER_MODAL = 'modal/INVITE_USER';
export const ADD_USER_TO_COMMUNITY_MODAL = 'modal/ADD_USER_TO_COMMUNITY';

// Generic modals - safe to reuse
export const PROMPT_BOOLEAN_MODAL = 'modal/PROMPT_BOOLEAN';

export const API_ERROR_MODAL = 'API_ERROR_MODAL';
export const TEMPLATE_ASSIGNMENT_ERRORS_MODAL = 'TEMPLATE_ASSIGNMENT_ERRORS_MODAL';
export const TOOLKIT_CATEGORIES_MODAL = 'TOOLKIT_CATEGORIES_MODAL';
export const PREVIEW_TOOLKIT_MODAL = 'PREVIEW_TOOLKIT_MODAL';