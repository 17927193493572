import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import PopUpMenu from '../PopUpMenu/PopUpMenu';

import { selectSelectedCommunityId } from '../../state/communities/reducers';
import { checkAndGetPermissions } from '../../utils/helpers';

const useStyles = makeStyles({
    container: {
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        padding: '10px 20px',
        minHeight: 40,
        marginBottom: 10,
        display: 'flex'
    },
    details: {
        width: '100%',
        maxWidth: '25%'
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 12,
        color: '#223f63'
    },
    communityContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1
    },
    chip: {
        height: 20,
        borderRadius: 25,
        backgroundColor: 'none !important',
        color: '#ffffff',
        fontSize: 8,
        backgroundImage: 'linear-gradient(to left, #2e598d, #072144)',
        marginRight: 5
    },
    archived: {
        backgroundColor: '#f7f7f7 !important',
        color: '#c9c9c9 !important',
        cursor: 'not-allowed'
    }
});

const MemberRowCard = ({
    firstName,
    lastName,
    email,
    memberships,
    menuActions,
    isDeleted
}) => {
    const classes = useStyles();
    const userName = `${firstName} ${lastName}`;
    const communityId = useSelector(state => selectSelectedCommunityId(state));
    const { isLeader, isComAdmin } = checkAndGetPermissions(
        memberships,
        communityId
    );

    const textClass = clsx({
        [classes.details]: true,
        [classes.text]: true,
        [classes.archived]: isDeleted
    });

    return (
        <div className={classes.container}>
            <Typography className={textClass}>
                {userName}
            </Typography>
            <Typography className={textClass}>
                {email}
            </Typography>
            <div className={classes.communityContainer}>
                {!isDeleted && isLeader && <Chip className={classes.chip} label="Leader" />}
                {!isDeleted && isComAdmin && (
                    <Chip className={classes.chip} label="Community Admin" />
                )}
            </div>
            {menuActions && <PopUpMenu menuActions={menuActions} />}
        </div>
    );
};

MemberRowCard.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    memberships: PropTypes.array.isRequired,
    menuActions: PropTypes.array,
    isDeleted: PropTypes.bool.isRequired
};

MemberRowCard.defaultProps = {
    menuActions: null
};

export default MemberRowCard;
