import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = makeStyles(theme => ({
  w100: {
    width: "100%"
  },
  labelRoot: {
    fontFamily: "SFUIText-Light",
    color: "#838b96",
    zIndex: 1,
    fontSize: 10,
    fontWeight: 500,
    paddingTop: 7,
    paddingLeft: 21,
    pointerEvents: "none"
  },
  labelFocused: {
    color: "#838b96 !important"
  },
  labelShrink: {
    fontFamily: "SFUIText-SemiBold",
    fontSize: 12,
    paddingTop: 0,
    paddingLeft: 15
  },
  labelError: {
    color: `${theme.palette.error.main} !important`
  },
  inputRoot: {
    fontFamily: "SFUIText-Reg",
    backgroundColor: "#f4f4f4",
    color: "#223f63",
    borderRadius: 10,
    paddingLeft: 21,
    paddingRight: 21,
    fontSize: 12,
    minHeight: 40
  },
  whiteInput: {
    backgroundColor: "#ffffff !important"
  },
  inputAdornmentStart: {
    paddingLeft: "10px !important"
  },
  inputAdornmentEnd: {
    paddingRight: "10px !important"
  },
  inputFocused: {
    border: "solid 1px #223f63"
  },
  inputError: {
    border: `solid 1px ${theme.palette.error.main}`,
    color: theme.palette.error.main
  },
  helperRoot: {
    fontFamily: "SFUIText-Light",
    fontSize: 10
  },
  helperError: {
    textAlign: "end",
    paddingRight: 7
  }
}));

export default function TextInput({
  label,
  value,
  name,
  required,
  disabled,
  onChange,
  multiline,
  rows,
  error,
  errorMessage,
  startAdornment,
  endAdornment,
  whiteInput,
  formControlStyle
}) {
  const classes = styles();

  const hasErrorWithMessage = error && errorMessage !== "";

  return (
    <FormControl
      className={classes.w100}
      error={error}
      style={formControlStyle}
    >
      <InputLabel
        disabled={disabled}
        classes={{
          root: classes.labelRoot,
          focused: classes.labelFocused,
          shrink: classes.labelShrink,
          error: classes.labelError
        }}
      >
        {label}
      </InputLabel>
      <Input
        name={name}
        required={required}
        disabled={disabled}
        disableUnderline
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        value={value}
        multiline={multiline}
        rows={rows}
        classes={{
          root: clsx({
            [classes.inputRoot]: true,
            [classes.whiteInput]: whiteInput,
            [classes.inputAdornmentStart]: startAdornment !== null,
            [classes.inputAdornmentEnd]: endAdornment !== null
          }),
          focused: classes.inputFocused,
          error: classes.inputError
        }}
        onChange={onChange}
      />
      {hasErrorWithMessage && (
        <FormHelperText
          classes={{ root: classes.helperRoot, error: classes.helperError }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}

TextInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  whiteInput: PropTypes.bool,
  formControlStyle: PropTypes.object
};

TextInput.defaultProps = {
  label: "",
  required: false,
  error: false,
  errorMessage: "",
  startAdornment: null,
  endAdornment: null,
  onChange: null,
  value: "",
  name: "",
  disabled: false,
  multiline: false,
  rows: 3,
  whiteInput: false,
  formControlStyle: null
};
