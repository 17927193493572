import React, { useState, useEffect } from 'react';

import axiosConfig from '@/utils/axiosConfig';
import envConfig from '@/utils/envConfig';

import AppLoadingPage from '@/components/Auth/AppLoadingPage/AppLoadingPage';
import RouteGuardian from './system/RouteGuardian';

const App = () => {
    // Potentially do some set up here... env, axios etc
    const [initialized, setInitialized] = React.useState(false);

    // Set up axios before rendering app
    useEffect(() => {
        (async () => {
            const result = await envConfig();
            axiosConfig(result.isProd);
            setInitialized(true);
        })();
    }, []);

    return initialized ? <RouteGuardian /> : <AppLoadingPage />;
};

export default App;
