import PropTypes from 'prop-types'
import React, {useState} from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import {DialogTitle, ListItem, ListItemAvatar} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import * as toolkitTypes from '../../state/toolkits/types';

const EditToolkitActions = ({toolkit}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showDialog, setShowDialog] = useState(false)
    
    return (
    <>
      <IconButton style={{marginRight: 4}} onClick={() => setShowDialog(true)}>
          <MoreVertIcon />
      </IconButton>
      <Dialog onClose={() => setShowDialog(false)} open={showDialog}>
          <DialogTitle>Toolkit Actions</DialogTitle>
          <List style={{width: '20rem'}}>
              <ListItem
                  autoFocus
                  button
                  onClick={() => {
                      dispatch({
                          type: toolkitTypes.DELETE_TOOLKIT,
                          communityId: null,
                          toolkitId: toolkit.id,
                      })
                      setShowDialog(false)
                      history.push('/toolkits');
                  }}
              >
                  <ListItemAvatar>
                      <Avatar>
                          <DeleteIcon />
                      </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Delete Toolkit" />
              </ListItem>
          </List>
      </Dialog>
    </>
    )
}

EditToolkitActions.propTypes = {
    toolkit: PropTypes.object.isRequired
}

export default EditToolkitActions
