import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = makeStyles(theme => ({
    root: {
        textTransform: 'none',
        fontSize: 11,
        fontWeight: 500,
        borderRadius: 20,
        boxShadow: 'none',
        minHeight: 40,
        minWidth: 75,
        padding: `${theme.spacing(1)}px ${theme.spacing(2.25)}px`,
        outline: '0 !important',
    },
    disabled: {
        cursor: 'not-allowed !important',
        color: '#ffffff !important',
        backgroundSize: '200% 100%',
        backgroundPosition: 'center center',
        backgroundImage: `repeating-linear-gradient(
      45deg,
      #dcdcdc,
      #dcdcdc 3px,
      rgba(0, 0, 0, 0.05) 3px,
      rgba(0, 0, 0, 0.05) 6px
    ) !important`
    },
    '@keyframes progress': {
        from: { backgroundPosition: '0 0' },
        to: { backgroundPosition: '-40px 0px' }
    },
    loading: {
        animation: '$progress 2s linear infinite'
    },
    goldBackground: {
        color: '#ffffff !important',
        backgroundImage: 'linear-gradient(to left, #f5bd56, #a5721a)',
        '&:hover': {
            backgroundImage: 'linear-gradient(to left, #f5bd56, #f5bd56)'
        }
    },
    greyBackground: {
        backgroundColor: '#dcdcdc !important',
        color: '#223f63',
        '&:hover': {
            backgroundColor: 'none !important',
            backgroundImage: 'linear-gradient(to right, #dcdcdc -12%, #fefefe 114%)'
        }
    },
    blueBackground: {
        color: '#ffffff',
        backgroundImage: 'linear-gradient(to left, #254b7a, #12396c)',
        '&:hover': {
            backgroundImage: 'linear-gradient(to left, #254b7a, #254b7a)'
        }
    },
    circularProgress: {
        color: theme.palette.text.primary
    },
    iconStart: {
        '& svg': {
            marginRight: 6
        }
    },
    iconEnd: {
        '& svg': {
            marginLeft: 6
        }
    }
}));

export default function Button({
    style,
    className,
    loading,
    disabled,
    label,
    onClick,
    background,
    icon,
    iconPosition
}) {
    const classes = styles();

    // Select background
    const backgroundClasses = {
        gold: classes.goldBackground,
        grey: classes.greyBackground,
        blue: classes.blueBackground
    };

    const hasIcon = icon !== null;
    const iconStart = iconPosition === 'start';
    const labelClass = iconStart ? classes.iconStart : classes.iconEnd;

    return (
        <MuiButton
            variant="contained"
            disabled={disabled || loading}
            style={style}
            className={className}
            onClick={onClick}
            classes={{
                root: clsx(classes.root, backgroundClasses[background]),
                disabled: clsx(classes.disabled),
                label: hasIcon ? labelClass : null
            }}
        >
            {loading ? (
                <CircularProgress className={classes.circularProgress} size={16} />
            ) : (
        <>
          {icon && iconPosition === 'start' && icon}
          {label}
          {icon && iconPosition === 'end' && icon}
        </>
            )}
        </MuiButton>
    );
}

Button.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    loading: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    background: PropTypes.string,
    icon: PropTypes.node,
    iconPosition: PropTypes.string
};

Button.defaultProps = {
    style: null,
    className: null,
    loading: false,
    label: 'Button',
    onClick: null,
    background: 'gold',
    icon: null,
    iconPosition: 'start'
};
