import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const MenuProps = {
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    },
    anchorOrigin: {
        vertical: 0,
        horizontal: 'left'
    },
    getContentAnchorEl: null
};

const styles = makeStyles(theme => ({
    formControl: {
        marginTop: 16
    },
    w100: {
        width: '80%'
    },
    labelRoot: {
        fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
        color: '#838b96',
        zIndex: 1,
        fontSize: 10,
        fontWeight: 500,
        paddingTop: 7,
        paddingLeft: 21,
        pointerEvents: 'none'
    },
    labelFocused: {
        color: '#838b96 !important'
    },
    labelShrink: {
        fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
        fontSize: 12,
        paddingTop: 0,
        paddingLeft: 15
    },
    labelFormControl: {
        top: '-16px !important'
    },
    inputRoot: {
        borderRadius: 10,
        backgroundColor: '#f4f4f4',
        border: 'none !important',
        '& svg': {
            marginRight: 10
        }
    },
    select: {
        fontSize: 12,
        fontWeight: 300,
        paddingLeft: 10,
        padding: '10px 34px 10px 20px !important',
        border: 'solid 1px #dcdcdc',
        borderRadius: 10,
        '&:focus': {
            backgroundColor: '#f7f7f7 !important',
            border: 'solid 1px #223f63',
            borderRadius: '10px !important'
        }
    },
    menu: {
        padding: '0px 3px',
        borderRadius: 15
    },
    menuItem: {
        fontSize: 12,
        padding: '12px 16px',
        fontWeight: 300,
        borderRadius: 15,
    },
    menuItemText: {
        fontSize: 12,
        fontWeight: 300
    },
    centered: {
        justifyContent: 'center'
    }
}));

export default function Dropdown({
    label,
    options,
    selected,
    showNoneOption,
    onChange,
    placeholder,
    className,
    centered,
    shrink,
    name,
    disabled,
    style
}) {
    const classes = styles();

    const hasLabel = label !== '';
    const formControlClasses = clsx({
        [classes.w100]: true,
        [className]: className.trim().length > 0,
        [classes.formControl]: hasLabel
    });

    const menuItemTextClasses = clsx({
        [classes.menuItemText]: true,
        [classes.centered]: centered
    });

    return (
        <FormControl className={formControlClasses} style={style}>
            {hasLabel && (
                <InputLabel
                    shrink={shrink}
                    classes={{
                        root: classes.labelRoot,
                        formControl: classes.labelFormControl,
                        shrink: classes.labelShrink,
                        focused: classes.labelFocused
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <Select
                name={name}
                displayEmpty
                value={selected}
                IconComponent={KeyboardArrowDownIcon}
                input={<InputBase classes={{ root: classes.inputRoot }} />}
                classes={{ select: classes.select }}
                onChange={onChange}
                disabled={disabled}
                renderValue={value => {
                    if (!value && !label) return placeholder;
                    const op = options.find(o => o.value === value);
                    const OptionIcon = op ? op.icon : null;
                    const opLabel = OptionIcon ? (
                        <div style={{ display: 'flex' }}>
                            <ListItemIcon>
                                <OptionIcon />
                            </ListItemIcon>{' '}
                            <ListItemText
                                primary={op.label || value}
                                classes={{
                                    primary: menuItemTextClasses
                                }}
                            />
                        </div>
                    ) : (
                        op.label
                    );

                    return op ? opLabel : value;
                }}
                MenuProps={{
                    ...MenuProps,
                    classes: {
                        paper: classes.menu
                    },
                    style: {
                        zIndex: 1400
                    }
                }}
            >
                {showNoneOption && (
                    <MenuItem value="" className={clsx(menuItemTextClasses, classes.menuItem)}>
                        <em>None</em>
                    </MenuItem>
                )}
                {options.map((option, idx) => {
                    const OptionIcon = option.icon || null;
                    return (
                        <MenuItem key={`dropdownItem-${idx}`} value={option.value || option} className={classes.menuItem}>
                            {OptionIcon && (
                                <ListItemIcon>
                                    <OptionIcon />
                                </ListItemIcon>
                            )}
                            <ListItemText
                                primary={option.label || option}
                                classes={{
                                    primary: menuItemTextClasses
                                }}
                            />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

Dropdown.propTypes = {
    label: PropTypes.string,
    options: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            })
        ),
        PropTypes.array
    ]).isRequired,
    showNoneOption: PropTypes.bool,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    centered: PropTypes.bool,
    shrink: PropTypes.bool,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object
};

Dropdown.defaultProps = {
    label: '',
    showNoneOption: false,
    placeholder: 'Select an option...',
    className: '',
    centered: false,
    shrink: false,
    name: '',
    disabled: false,
    style: {}
};
