import axios from 'axios';

export const getCommunities = async (page, search) => {
    try {
        const result = await axios.get('/v1/communities', {
            params: {
                limit: 10,
                skip: page * 10,
                search
            }
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const createCommunity = async community => {
    try {
        const result = await axios.post('/v1/communities', community);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getCommunity = async communityId => {
    try {
        const result = await axios.get(`/v1/communities/${communityId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getCommunityUsers = async communityId => {
    try {
        const result = await axios.get(`/v1/communities/${communityId}/users`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const updateCommunity = async (communityId, payload) => {
    try {
        const result = await axios.put(`/v1/communities/${communityId}`, payload);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const inviteUserToCommunity = async (communityId, payload) => {
    try {
        const result = await axios.post(
            `/v1/communities/${communityId}/users`,
            payload
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeUserFromCommunity = async (communityId, userId) => {
    try {
        const result = await axios.delete(
            `/v1/communities/${communityId}/users/${userId}`
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const addCommunityAdmin = async (communityId, userId) => {
    try {
        const result = await axios.patch(
            `/v1/communities/${communityId}/communityAdmins/${userId}`
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeCommunityAdmin = async (communityId, userId) => {
    try {
        const result = await axios.delete(
            `/v1/communities/${communityId}/communityAdmins/${userId}`
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const addLeaderToUser = async (communityId, userId, leaders) => {
    try {
        const result = await axios.put(
            `/v1/communities/${communityId}/users/${userId}/leaders`,
            { leaders }
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeLeaderFromUser = async (communityId, userId, leaderId) => {
    try {
        const result = await axios.delete(
            `/v1/communities/${communityId}/users/${userId}/leaders/${leaderId}`,
        )
        return result.data;
    } catch (error) {
        throw error;
    }
}

export const batchUpload = async (communityId, formData) => {
    try {
        const result = await axios.post(
            `/v1/communities/${communityId}/users/batch`,
            formData
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getCommunityLeaders = async communityId => {
    try {
        const result = await axios.get(
            `/v1/communities/${communityId}/users?type=leader`
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};
