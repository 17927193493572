import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as EditIcon } from '../../static/images/icons/icon-edit.svg';

const styles = makeStyles({
    container: {
        marginTop: 40
    },
    editButton: {
        cursor: 'pointer',
        backgroundColor: '#dcdcdc',
        borderRadius: 20,
        padding: '5px 18px'
    },
    title: {
        fontSize: 26,
        fontFamily: 'SFUIDisplay-Bold',
        marginRight: 20
    },
    description: {
        marginTop: 20,
        fontSize: 14,
        fontFamily: 'SFUIDisplay-Light'
    }
});

export default function CommunityDetailsHeader({ title, description, onEdit }) {
    const classes = styles();
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.title}>{title}</Typography>
                <IconButton className={classes.editButton} onClick={onEdit}>
                    <EditIcon />
                </IconButton>
            </Grid>
            {description && (
                <Grid item xs={6}>
                    <Typography className={classes.description}>
                        {description}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

CommunityDetailsHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    onEdit: PropTypes.func.isRequired
};

CommunityDetailsHeader.defaultProps = {
    description: ''
};
