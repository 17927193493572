import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }}
        >
            <CircularProgress color="secondary" size={72} />
        </div>
    );
}

export default Spinner