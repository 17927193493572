import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as MoreIcon } from '../../static/images/icons/icon-more.svg';


const styles = makeStyles({
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    paperRoot: {
        borderRadius: 15
    },
    menuItemRoot: {
        fontSize: 12,
        '&:not(:last-child)': {
            borderBottom: '1px solid rgba(34, 63, 99, 0.1)'
        }
    }
});

export default function PopUpMenu({ menuActions }) {
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState(null);

    function handleClick(e) {
        setAnchorEl(e.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <>
            <div className={classes.flex}>
                <IconButton onClick={handleClick}>
                    <MoreIcon />
                </IconButton>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.paperRoot }}
            >
                {menuActions.map(({ onClick, label }) => (
                    <MenuItem
                        key={label}
                        onClick={e => {
                            handleClose();
                            onClick(e);
                        }}
                        classes={{ root: classes.menuItemRoot }}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

PopUpMenu.propTypes = {
    menuActions: PropTypes.array.isRequired
};
