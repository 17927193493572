import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles({
    tableCell: {
        padding: 0,
        border: 0
    },
    '@keyframes glow': {
        '0%, 100%': { opacity: 0.5 },
        '50%': { opacity: 1 }
    },
    container: {
        color: '#f7f7f7',
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        padding: '10px 20px',
        minHeight: 40,
        display: 'flex',
        marginBottom: 10,
        animation: '$glow 1.5s ease-in-out infinite'
    }
});

export default function UserCardLoading() {
    const classes = styles();
    return (
        <TableRow>
            <TableCell scope="row" className={classes.tableCell}>
                <Typography className={classes.container}>Loading</Typography>
            </TableCell>
        </TableRow>
    );
}
