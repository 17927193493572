import * as types from './types';

const initialState = {
    loadingState: false,
    savingState: false,
    userId: '',
    timestamp: null,
    passwordRequested: false,
    passwordReset: false,
    apiErrors: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_LOADING_STATE:
            return {
                ...state,
                loadingState: action.state
            };

        case types.AUTH_SAVING_STATE:
            return {
                ...state,
                savingState: action.state
            };

        case types.AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                userId: action.userId,
                timestamp: new Date().toISOString()
            };

        case types.AUTH_LOGOUT_SUCCESS:
            return {
                ...state,
                userId: '',
                loggedInUser: null,
                timestamp: null
            };

        case types.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordRequested: true
            };

        case types.CLEAR_RESET_PASSWORD_REQUESTED:
            return {
                ...state,
                passwordRequested: false
            };

        case types.SET_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordReset: true
            };

        case types.CLEAR_RESET_PASSWORD:
            return {
                ...state,
                passwordReset: false
            };

        case types.RESET_PASSWORD_ERROR:
        case types.SET_NEW_PASSWORD_ERROR:
        case types.AUTH_LOGIN_ERROR:
            return {
                ...state,
                apiErrors: action.errors
            };

        default:
            return state;
    }
};

export default authReducer;

export const selectAuthLoadingState = state => state.auth.loadingState;
export const selectAuthSavingState = state => state.auth.savingState;
export const selectLoggedInUser = state => state.auth.userId;
export const selectAuthTimestamp = state => state.auth.timestamp;
export const selectPasswordRequested = state => state.auth.passwordRequested;
export const selectPasswordReset = state => state.auth.passwordReset;
export const selectAuthApiErrors = state => state.auth.apiErrors;
export const selectIsOfficer = state =>
    state.auth.loggedInUser
        ? state.auth.loggedInUser.roles.some(role => role === 'officer')
        : false;
