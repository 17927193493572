import * as types from './types';

const initialState = {
    loadingState: false,
    savingState: false,
    communities: [],
    totalCommunities: 0,
    selectedCommunity: null,
    communityUsers: [],
    batchResults: null,
    apiErrors: null,
    leaderUsers: [],
    communityLeaders: [],
    communityLeadersLoadingState: false,
    leaderUsersLoadingState: true,
};

const communityReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.COMMUNITY_SAVING_STATE:
            return {
                ...state,
                savingState: action.state
            };

        case types.COMMUNITY_LOADING_STATE:
            return {
                ...state,
                loadingState: action.state
            };

        case types.GET_COMMUNITIES_SUCCESS:
            return {
                ...state,
                communities: action.communities,
                totalCommunities: action.totalCommunities
            };

        case types.GET_COMMUNITY_SUCCESS:
            return {
                ...state,
                selectedCommunity: action.selectedCommunity,
                communityUsers: action.communityUsers
            };

        case types.CREATE_COMMUNITY_SUCCESS:
            return {
                ...state,
                communities: [...state.communities, action.community],
                apiErrors: null
            };

        case types.UPDATE_COMMUNITY_SUCCESS:
            return {
                ...state,
                selectedCommunity: action.selectedCommunity,
                apiErrors: null
            };

        case types.REMOVE_USER_SUCCESS:
            return {
                ...state,
                communityUsers: action.users
            };

        case types.INVITE_USER_SUCCESS:
            return {
                ...state,
                communityUsers: [...state.communityUsers, action.user]
            };

        case types.ADD_LEADERS_TO_USER_SUCCESS:
            return {
                ...state,
                communityUsers: state.communityUsers.map(user => {
                    if (user._id === action.user._id) return action.user;
                    return user;
                })
            };

        case types.REMOVE_LEADER_FROM_USER_SUCCESS:
            return {
                ...state,
                communityUsers: state.communityUsers.map(user => {
                    if (user._id === action.user._id) return action.user;
                    return user;
                })
            };

        case types.COMMUNITY_USERS_LOADING_STATE:
            return {
                ...state,
                communityUsersLoadingState: action.state
            };

        case types.BATCH_UPLOAD_USERS_SUCCESS:
            return {
                ...state,
                batchResults: action.batchResults,
                communityUsers: action.communityUsers
            };

        case types.CLEAR_BATCH_RESULTS:
            return {
                ...state,
                batchResults: null
            };

        case types.REMOVE_USER_ADMIN_SUCCESS:
        case types.SET_USER_ADMIN_SUCCESS:
            return {
                ...state,
                communityUsers: action.users
            };

        case types.BATCH_UPLOAD_USERS_ERROR:
        case types.ADD_LEADERS_TO_USER_ERROR:
        case types.INVITE_USER_ERROR:
        case types.CREATE_COMMUNITY_ERROR:
        case types.UPDATE_COMMUNITY_ERROR:
            return {
                ...state,
                apiErrors: action.errors
            };

        case types.CLEAR_COMMUNITY_API_ERRORS:
            return {
                ...state,
                apiErrors: null
            };

        case types.GET_COMMUNITY_USERS_SUCCESS:
            return {
                ...state,
                communityUsers: action.users
            };

        case types.COMMUNITY_LEADERS_LOADING_STATE:
            return {
                ...state,
                communityLeadersLoadingState: action.state
            };

        case types.GET_COMMUNITY_LEADERS_SUCCESS:
            return {
                ...state,
                communityLeaders: action.leaders
            };

        default:
            return state;
    }
};

export default communityReducer;

export const selectCommunityLoadingState = state => state.community.loadingState;
export const selectCommunitySavingState = state => state.community.savingState;
export const selectCommunityApiErrors = state => state.community.apiErrors;
export const selectCommunities = state => state.community.communities;
export const selectTotalCommunities = state => state.community.totalCommunities;
export const selectSelectedCommunity = state => state.community.selectedCommunity;
export const selectSelectedCommunityId = state =>
    state.community.selectedCommunity ? state.community.selectedCommunity._id : null;
export const selectCommunityUsers = state => state.community.communityUsers;
export const selectBatchResults = state => state.community.batchResults;
export const selectCommunityUsersLoadingState = state => state.community.communityUsersLoadingState;
export const selectMemberById = (state, id) => state.community.leaderUsers.find(u => u.id === id);
export const selectCommunityLeaders = state => state.community.communityLeaders;
