import validate from 'validate.js';

const constraints = {
    // Login constraint
    login: {
        email: {
            email: {
                message: '^Please enter a valid email.'
            },
            presence: {
                message: '^You must enter your email.',
                allowEmpty: false
            }
        },
        password: {
            presence: {
                message: '^You must enter your password.',
                allowEmpty: false
            }
        }
    },

    // Email constraint
    email: {
        email: {
            email: {
                message: '^Please enter a valid email.'
            },
            presence: {
                message: '^You must enter your email.',
                allowEmpty: false
            }
        }
    },

    // Create user constraints
    createUser: {
        firstName: {
            presence: {
                message: '^You must include a first name.',
                allowEmpty: false
            }
        },
        lastName: {
            presence: {
                message: '^You must include a last name',
                allowEmpty: false
            }
        },
        email: {
            email: {
                message: '^Please enter a valid email.'
            },
            presence: {
                message: '^You must enter an email.',
                allowEmpty: false
            }
        }
    },

    // Create user in community (communityAdmin)
    createUserInCommunity: {
        password: {
            presence: {
                message: '^You must include a password.',
                allowEmpty: false
            }
        },
        firstName: {
            presence: {
                message: '^You must include a first name.',
                allowEmpty: false
            }
        },
        lastName: {
            presence: {
                message: '^You must include a last name',
                allowEmpty: false
            }
        },
        email: {
            email: {
                message: '^Please enter a valid email.'
            },
            presence: {
                message: '^You must enter an email.',
                allowEmpty: false
            }
        }
    },

    // Set new password constraint
    newPassword: {
        password: {
            presence: {
                message: '^You must enter a new password',
                allowEmpty: false
            }
        },
        confirm: {
            presence: {
                message: '^You must confirm your password',
                allowEmpty: false
            },
            equality: {
                attribute: 'password',
                message: '^Your passwords do not match.'
            }
        }
    },

    // Edit community
    editCommunity: {
        title: {
            presence: {
                message: '^The community must have a name.',
                allowEmpty: false
            }
        }
    },

    // Create toolkit constraints
    createToolkit: {
        title: {
            presence: {
                message: '^You must include a title.',
                allowEmpty: false
            }
        },
        description: {
            presence: {
                message: '^You must include a summary',
                allowEmpty: false
            }
        },
    },
};

// Helpers
export const getErrorMessage = (errors, field) => {
    if (errors === null) return '';
    if (errors[field]) return errors[field][0];
    if (Array.isArray(errors)) {
        const apiError = errors.find(e => e.key === field);
        if (apiError) return apiError.message;
    }
    return '';
};

export const parseApiErrors = (apiErrors, type) => {
    if (apiErrors === null) return null;
    const { key, errors } = apiErrors;
    if (key === type) return errors;
    return null;
};

export const validateLogin = formValues => {
    const errors = validate({ ...formValues }, constraints.login);
    return errors;
};

export const validateEmail = email => {
    const error = validate({ email }, constraints.email, { format: 'flat' });
    return error;
};

export const validateCreateUser = formValues => {
    const error = validate({ ...formValues }, constraints.createUser);
    return error;
};

export const validateCreateUserInCommunity = formValues => {
    const error = validate({ ...formValues }, constraints.createUserInCommunity);
    return error;
}

export const validateEditCommunity = formValues => {
    const error = validate({ ...formValues }, constraints.editCommunity);
    return error;
};

export const validateNewPassword = formValues => {
    const error = validate({ ...formValues }, constraints.newPassword);
    return error;
};

export const validateCreateToolkit = formValues => {
    const error = validate({ ...formValues }, constraints.createToolkit);
    return error;
};
