import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Fuse from 'fuse.js';
import usePrevious from '../../hooks/usePrevious';
import * as modalTypes from '../../state/modal/types';
import Button from '../../components/Button/Button';
import SearchInput from '../../components/SearchInput/SearchInput';
import EditableToolkitCategoriesTree
    from '../../components/EditableToolkitCategoriesTree/EditableToolkitCategoriesTree';
import TextInput from '../../components/TextInput/TextInput';
import Dropdown from '../../components/Dropdown/Dropdown';
import {selectToolkitCategories, selectToolkitCategoriesLoadingState} from '../../state/toolkitCategories/reducers';
import * as toolkitCategoryTypes from '../../state/toolkitCategories/types';
import Spinner from '../../components/Spinner/Spinner';
import {groupCategories} from '../../utils/toolkitHelpers';

const useStyles = makeStyles({
    gridContainer: {
        maxWidth: 450,
        flex: 1,
        margin: 'auto'
    },
    title: {
        fontSize: 24,
        fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
        color: '#223f63',
        marginBottom: 20
    },
    message: {
        fontSize: 16
    },
    buttonGroup: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    },
    button: {
        width: 150,
        margin: '0px 10px'
    },
});

export default function ToolkitCategoriesModal({
    title,
    message,
    cancelText,
    confirmText,
    onCancel,
    onSubmit,
    savingSelector,
    nodes,
    onEditNode,
    onDeleteNode
}){
    // Hooks
    const classes = useStyles();
    const dispatch = useDispatch();

    // Redux
    const saving = savingSelector ? useSelector(state => savingSelector(state)) : null;
    const prevSaving = usePrevious(saving);
    const loading = useSelector(state => selectToolkitCategoriesLoadingState(state));
    const toolkitCategories = useSelector(state => selectToolkitCategories(state));

    // States
    const [search, setSearch] = useState('');
    const [category, setCategory] = useState({name: ''});
    const [position, setPosition] = useState('');
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        dispatch({
            type: toolkitCategoryTypes.GET_TOOLKIT_CATEGORIES,
        });
    },[])

    // Close when redux save state goes from true to false
    useEffect(() => {
        if (prevSaving && !saving) closeModal();
    }, [saving, prevSaving]);

    // Event handlers
    const closeModal = () => {
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
    };

    const handleCancel = () => {
        if (onCancel !== null) return onCancel();
        return closeModal();
    };

    const handleSubmit = async () => {
        if (!position || !category.name) {
            setErrors({})
            return
        }

        await dispatch({
            type: toolkitCategoryTypes.CREATE_TOOLKIT_CATEGORY,
            payload: {...category, parent: position !== 'parent' ? position : ''}
        })

        setCategory({name: ''})
        setPosition('')
    };

    const onEditCategory = async (categoryData) => {
        await dispatch({
            type: toolkitCategoryTypes.EDIT_TOOLKIT_CATEGORY,
            toolkitCategoryId: categoryData.id,
            payload: {name: categoryData.name, parent: categoryData.parent}
        })
    }

    const onDeleteCategory = async (categoryData) => {
        await dispatch({
            type: toolkitCategoryTypes.DELETE_TOOLKIT_CATEGORY,
            toolkitCategoryId: categoryData.id,
        })
    }

    // Helper constants
    const hasMessage = message.trim().length > 0;

    const getSubcategoryOptions = () => {
        const options = [];
        const categories = groupCategories(toolkitCategories);

        for(let counter = 0; counter < categories.length; ++counter) {
            options.push({
                label: `Subcategory:${categories[counter].name}`,
                value: categories[counter].id
            });
            if (categories[counter]?.children) {
                for(let childNodeCounter = 0; childNodeCounter < categories[counter].children.length; ++childNodeCounter) {
                    options.push({
                        label: `Subcategory:${categories[counter].children[childNodeCounter].name}`,
                        value: categories[counter].children[childNodeCounter].id
                    });
                }
            }
        }

        return options
    }

    const onSearchChange = e => {
        setSearch(e);
    };

    const searchCategories = u => {
        const fuseInstance = new Fuse(u, {
            shouldSort: true,
            tokenize: true,
            threshold: 0.2,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 3,
            keys: ['name']
        });

        return fuseInstance.search(search);
    };

    const hasSearch = search.trim().length > 0;
    const computedNodes = hasSearch ? searchCategories(toolkitCategories) : toolkitCategories;

    if (loading) {
        return (
            <div style={{marginTop: '1rem'}}>
                <Spinner />
            </div>
        )
    }

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid item md={12}>
                <Typography align="center" className={classes.title}>
                    {title}
                </Typography>
                <div>
                    <SearchInput
                        value={search}
                        onChange={onSearchChange}
                        showFocus={false}
                        placeholder="Search all categories"
                        style={{ backgroundColor: '#ffffff' }}
                    />
                </div>
                <Divider style={{
                    marginTop: '1rem',
                    marginBottom: '1rem'
                }}
                />
                {computedNodes.length > 0 ? (
                    <div style={{height: 250, overflowY: 'scroll'}}>
                        <EditableToolkitCategoriesTree
                            nodes={groupCategories(computedNodes)}
                            onEditNode={onEditCategory}
                            onDeleteNode={onDeleteCategory}
                        />
                    </div>
                ) : hasSearch ? 'No categories found.' : 'No categories yet.'}
            </Grid>
            <Grid item md={12}>
                <Divider style={{
                    marginTop: '1rem',
                    marginBottom: '1rem'
                }}
                />
            </Grid>
            <Grid item md={12}>
                <TextInput
                    label="Create a new category"
                    value={category.name}
                    name="categoryName"
                    placeholder="Category name"
                    className={classes.label}
                    onChange={(e) => {
                        const { value } = e.target;

                        setCategory({name: value})
                    }}
                    formControlStyle={{marginTop: 0}}
                />
            </Grid>
            <Grid item md={12} style={{marginTop: '.5rem'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'start',
                    width: '60%',
                }}
                >
                    <Dropdown
                        options={[
                            {
                                label: 'Parent category',
                                value: 'parent'
                            },
                            ...getSubcategoryOptions()
                        ]}
                        selected={position}
                        onChange={e => {
                            setPosition(e.target.value);
                        }}
                    />
                </div>
            </Grid>
            <Grid item md={12} className={classes.buttonGroup}>
                <Button
                    iconPosition="start"
                    icon={<AddIcon />}
                    label={confirmText}
                    onClick={handleSubmit}
                    loading={saving}
                    disabled={saving || !position || !category.name}
                />
            </Grid>
        </Grid>
    );
}

ToolkitCategoriesModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    savingSelector: PropTypes.func,
    nodes: PropTypes.array,
    onEditNode: PropTypes.func,
    onDeleteNode: PropTypes.func,
};

ToolkitCategoriesModal.defaultProps = {
    title: 'Edit Categories',
    message: '',
    cancelText: 'Close',
    confirmText: 'Add new Category',
    onCancel: null,
    savingSelector: null,
    onSubmit: null,
    nodes: [],
    onEditNode: () => {},
    onDeleteNode: () => {},
};