export const GET_ALL_TOOLKITS = 'toolkits/GET_ALL_TOOLKITS';
export const GET_ALL_TOOLKITS_SUCCESS = 'toolkits/GET_ALL_TOOLKITS_SUCCESS';
export const GET_ALL_TOOLKITS_ERROR = 'toolkits/GET_ALL_TOOLKITS_ERROR';

export const GET_TEAM_TOOLKITS = 'toolkits/GET_TEAM_TOOLKITS';
export const GET_TEAM_TOOLKITS_SUCCESS = 'toolkits/GET_TEAM_TOOLKITS_SUCCESS';
export const GET_TEAM_TOOLKITS_ERROR = 'toolkits/GET_TEAM_TOOLKITS_ERROR';

export const ADD_TOOLKIT_TO_TEAM = 'toolkits/ADD_TOOLKIT_TO_TEAM';
export const ADD_TOOLKIT_TO_TEAM_SUCCESS = 'toolkits/ADD_TOOLKIT_TO_TEAM_SUCCESS';
export const ADD_TOOLKIT_TO_TEAM_ERROR = 'toolkits/ADD_TOOLKIT_TO_TEAM_ERROR';

export const REMOVE_TOOLKIT_FROM_TEAM = 'toolkits/REMOVE_TOOLKIT_FROM_TEAM';
export const REMOVE_TOOLKIT_FROM_TEAM_SUCCESS = 'toolkits/REMOVE_TOOLKIT_FROM_TEAM_SUCCESS';
export const REMOVE_TOOLKIT_FROM_TEAM_ERROR = 'toolkits/REMOVE_TOOLKIT_FROM_TEAM_ERROR';

export const CREATE_TOOLKIT = 'toolkits/CREATE_TOOLKIT';
export const CREATE_TOOLKIT_SUCCESS = 'toolkits/CREATE_TOOLKIT_SUCCESS';
export const CREATE_TOOLKIT_ERROR = 'toolkits/CREATE_TOOLKIT_ERROR';
export const CLEAR_TOOLKIT_CREATED = 'toolkits/CLEAR_TOOLKIT_CREATED';

export const EDIT_TOOLKIT = 'toolkits/EDIT_TOOLKIT';
export const EDIT_TOOLKIT_SUCCESS = 'toolkits/EDIT_TOOLKIT_SUCCESS';
export const EDIT_TOOLKIT_ERROR = 'toolkits/EDIT_TOOLKIT_ERROR';

export const GET_TOOLKITS = 'toolkits/GET_TOOLKITS';
export const GET_TOOLKITS_SUCCESS = 'toolkits/GET_TOOLKITS_SUCCESS';
export const GET_TOOLKITS_ERROR = 'toolkits/GET_TOOLKITS_ERROR';

export const GET_TOOLKIT = 'toolkits/GET_TOOLKIT';
export const GET_TOOLKIT_SUCCESS = 'toolkits/GET_TOOLKIT_SUCCESS';
export const GET_TOOLKIT_ERROR = 'toolkits/GET_COMMUNITY_USERS_ERROR';

export const GET_USER_TOOLKITS = 'toolkits/GET_USER_TOOLKITS';
export const GET_USER_TOOLKITS_SUCCESS = 'toolkits/GET_USER_TOOLKITS_SUCCESS';
export const GET_USER_TOOLKITS_ERROR = 'toolkits/GET_USER_TOOLKITS_ERROR';

export const ASSIGN_TOOLKITS = 'toolkits/ASSIGN_TOOLKITS';
export const ASSIGN_TOOLKITS_SUCCESS = 'toolkits/ASSIGN_TOOLKITS_SUCCESS';
export const ASSIGN_TOOLKITS_ERROR = 'toolkits/ASSIGN_TOOLKITS_ERROR';

export const REMOVE_TOOLKIT_FROM_USER = 'toolkits/REMOVE_TOOLKIT_FROM_USER';
export const REMOVE_TOOLKIT_FROM_USER_SUCCESS = 'toolkits/REMOVE_TOOLKIT_FROM_USER_SUCCESS';
export const REMOVE_TOOLKIT_FROM_USER_ERROR = 'toolkits/REMOVE_TOOLKIT_FROM_USER_ERROR';

export const TOOLKIT_LOADING_STATE = 'toolkits/TOOLKIT_LOADING_STATE';
export const TOOLKIT_SAVING_STATE = 'toolkits/TOOLKIT_SAVING_STATE';
export const SET_TOOLKIT = 'toolkits/SET_TOOLKIT';

export const DELETE_TOOLKIT = 'toolkits/DELETE_TOOLKIT';
export const DELETE_TOOLKIT_SUCCESS = 'toolkits/DELETE_TOOLKIT_SUCCESS';
export const DELETE_TOOLKIT_ERROR = 'toolkits/DELETE_TOOLKIT_ERROR';