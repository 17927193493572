import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Button from '../../components/Button/Button';

import {
    selectCommunityUsers,
    selectSelectedCommunityId,
    selectCommunitySavingState,
    selectCommunityApiErrors
} from '../../state/communities/reducers';
import { selectAllOfficers } from '../../state/users/reducers';
import * as communityTypes from '../../state/communities/types';
import * as modalTypes from '../../state/modal/types';

import {
    checkAndGetPermissions,
    getUserCommunityLeaders
} from '../../utils/helpers';
import { usePrevious } from '../../utils/customHooks';

const useStyles = makeStyles({
    modalTitle: {
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 24,
        marginBottom: 5
    },
    modalInfo: {
        fontFamily: 'SFUIDisplay-Light',
        fontSize: 14,
        marginBottom: 20
    },
    container: {
        borderRadius: 20,
        backgroundColor: '#e3e3e3',
        maxHeight: 575,
        padding: 20
    },
    inner: {
        overflow: 'auto',
        maxHeight: 535
    },
    innerTitle: {
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 16,
        color: '#223f63',
        marginBottom: 10
    },
    listItem: {
        borderRadius: 10,
        backgroundColor: '#f4f4f4',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        marginBottom: 10,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#545c68',
            '& p': {
                color: '#ffffff !important'
            }
        }
    },
    selected: {
        backgroundColor: '#323a46',
        color: '#ffffff !important'
    },
    name: {
        fontFamily: 'SFUIDisplay-SemiBold',
        fontSize: 12
    },
    firstName: {
        width: '25%'
    },
    lastName: {
        width: '75%'
    },
    noData: {
        fontSize: 12,
        fontFamily: 'SFUIDisplay-Light'
    }
});

const Card = ({ firstName, lastName, onClick, selected }) => {
    const classes = useStyles();
    return (
        <div
            className={clsx({
                [classes.listItem]: true,
                [classes.selected]: selected
            })}
            onClick={onClick}
            role="presentation"
        >
            <Typography noWrap className={clsx(classes.name, classes.firstName)}>
                {firstName}
            </Typography>
            <Typography noWrap className={clsx(classes.name, classes.lastName)}>
                {lastName}
            </Typography>
        </div>
    );
};

Card.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired
};

const AssignLeaderModal = ({ selectedUser }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    // Redux
    const saveState = useSelector(state => selectCommunitySavingState(state));
    const communityId = useSelector(state => selectSelectedCommunityId(state));
    const communityUsers = useSelector(state => selectCommunityUsers(state));
    const officers = useSelector(state => selectAllOfficers(state));
    const apiErrors = useSelector(state => selectCommunityApiErrors(state));
    const currentLeaders = getUserCommunityLeaders(selectedUser, communityId);

    const [selectedLeaders, setSelectedLeaders] = useState(
        currentLeaders.map(leader => leader._id)
    );

    const prevSaving = usePrevious(saveState);
    useEffect(() => {
        if (prevSaving && !saveState && !apiErrors) {
            dispatch({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            });
        }
    }, [prevSaving, saveState, apiErrors]);

    // Compute all the data for display
    const leaders = communityUsers.filter(user => {
        const { isLeader } = checkAndGetPermissions(user.memberships, communityId);
        return isLeader;
    });

    const availableLeaders = leaders.filter(l => l._id !== selectedUser._id);

    // TODO: Need endpoint for getting all platform officers
    const availableOfficers = officers.filter(l => l._id !== selectedUser._id);

    // Boolean checks for displaying data
    const hasLeaders = availableLeaders.length > 0;
    const hasOfficers = availableOfficers.length > 0;

    // Add/remove leader Ids to local state
    const handleClick = id => {
        const exists = selectedLeaders.includes(id);
        if (exists)
            setSelectedLeaders(selectedLeaders.filter(leader => leader !== id));
        else setSelectedLeaders([...selectedLeaders, id]);
    };

    // Submit the payload!
    const handleSubmit = () => {
        dispatch({
            type: communityTypes.ADD_LEADERS_TO_USER,
            userId: selectedUser._id,
            leaders: selectedLeaders,
            previousLeaders: currentLeaders.map(leader => leader._id)
        });
    };

    return (
        <>
            <Typography align="center" className={classes.modalTitle}>
                Assign Leaders
            </Typography>
            <Typography align="center" className={classes.modalInfo}>
                Select a leader/s or officer/s from the list below
            </Typography>
            <div className={classes.container}>
                <div className={classes.inner}>
                    <Typography className={classes.innerTitle}>
                        All leaders in this community
                    </Typography>

                    {hasLeaders ? (
                        availableLeaders.map(leader => (
                            <Card
                                key={leader._id}
                                selected={selectedLeaders.includes(leader._id)}
                                firstName={leader.firstName}
                                lastName={leader.lastName}
                                onClick={() => handleClick(leader._id)}
                            />
                        ))
                    ) : (
                        <Typography
                            className={classes.noData}
                            style={{ marginBottom: 10 }}
                        >
                            No leaders available for this user!
                        </Typography>
                    )}

                    <Typography className={classes.innerTitle}>
                        All officers
                    </Typography>

                    {hasOfficers ? (
                        availableOfficers.map(officer => (
                            <Card
                                key={officer._id}
                                selected={selectedLeaders.includes(officer._id)}
                                firstName={officer.firstName}
                                lastName={officer.lastName}
                                onClick={() => handleClick(officer._id)}
                            />
                        ))
                    ) : (
                        <Typography className={classes.noData}>
                            No officers available for this user!
                        </Typography>
                    )}
                </div>
            </div>
            {(hasLeaders || hasOfficers) && (
                <Box marginTop={2} display="flex" justifyContent="center">
                    <Button
                        style={{ width: 150 }}
                        label="Save"
                        onClick={handleSubmit}
                        loading={saveState}
                        disabled={saveState}
                    />
                </Box>
            )}
        </>
    );
};

AssignLeaderModal.propTypes = {
    selectedUser: PropTypes.object
};

AssignLeaderModal.defaultProps = {
    selectedUser: null
};

export default AssignLeaderModal;
