import axios from 'axios';
import getV2BaseUrl from '../../utils/getV2BaseUrl';

let API_V2_BASE_URL;

export const createToolkitCategory = async toolkitCategory => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.post(`${API_V2_BASE_URL}/v1/toolkit-categories`, toolkitCategory);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const editToolkitCategory = async (toolkitCategoryId, toolkitCategory) => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.put(`${API_V2_BASE_URL}/v1/toolkit-categories/${toolkitCategoryId}`, toolkitCategory);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getToolkitCategories = async () => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.get(`${API_V2_BASE_URL}/v1/toolkit-categories`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const deleteToolkitCategory = async toolkitCategoryId => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.delete(`${API_V2_BASE_URL}/v1/toolkit-categories/${toolkitCategoryId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};