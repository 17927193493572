import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as authServices from '@/state/auth/services';
import { selectLoggedInUser, selectAuthTimestamp } from '@/state/auth/reducers';

class AuthGuardian extends Component {
    componentDidMount() {
        // Check secure
        // Check logged in
        // Check token
        // Check permissions
        this.loggedInCheck();
        this.tokenCheck();
    }

    componentDidUpdate() {
        // Check secure
        // Check logged in
        // Check token
        // Check permissions
        this.loggedInCheck();
        this.tokenCheck();
    }

    tokenCheck = () => {
        const { history } = this.props;

        // Check if a token exists in localstorage
        const idToken = authServices.getLocalStore('idToken');
        const refreshToken = authServices.getLocalStore('refreshToken');

        // If either token is null, redirect to login
        if (idToken === null || refreshToken === null) history.push('/');
    };

    loggedInCheck = () => {
        const { history } = this.props;
        const _loggedIn = authServices.getLocalStore('loggedIn');
        const loggedIn = _loggedIn === 'true';
        if (!loggedIn) history.push('/');
    };

    render() {
        const { children } = this.props;
        return children;
    }
}

// Pass these as props to force a rerender whenever they change
const mapStateToProps = state => ({
    userUuid: selectLoggedInUser(state),
    authTimestamp: selectAuthTimestamp(state)
});

export default connect(mapStateToProps, null)(withRouter(AuthGuardian));
