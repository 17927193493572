export const AUTH_LOGIN = 'auth/AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'auth/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'auth/AUTH_LOGIN_ERROR';

export const AUTH_LOGOUT = 'auth/AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'auth/AUTH_LOGOUT_SUCCESS';

export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'auth/RESET_PASSWORD_ERROR';
export const CLEAR_RESET_PASSWORD_REQUESTED = 'auth/CLEAR_RESET_PASSWORD_REQUESTED';

export const SET_NEW_PASSWORD = 'auth/SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'auth/SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_ERROR = 'auth/SET_NEW_PASSWORD_ERROR';
export const CLEAR_RESET_PASSWORD = 'auth/CLEAR_RESET_PASSWORD';

export const AUTH_LOADING_STATE = 'auth/AUTH_LOADING_STATE';
export const AUTH_SAVING_STATE = 'auth/AUTH_SAVING_STATE';
