import React from 'react';
import Container from '@material-ui/core/Container';

export default function PageContainer({ children }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                backgroundColor: ' #eeeeee',
                minHeight: '100vh',
                padding: '40px 40px'
            }}
        >
            <Container>{children}</Container>
        </div>
    );
}
