import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Table from '../Table/Table';

const useStyles = makeStyles({
    '@keyframes glow': {
        '0%, 100%': { opacity: 0.5 },
        '50%': { opacity: 1 }
    },
    loadingBackground: {
        color: '#f7f7f7',
        height: 40,
        backgroundColor: '#f7f7f7',
        animation: '$glow 1.5s ease-in-out infinite'
    }
});

const CommunityDetailsLoading = () => {
    const classes = useStyles();
    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            width: '30%',
                            height: 40,
                            marginBottom: 10,
                            marginTop: 10
                        }}
                        className={classes.loadingBackground}
                    >
                        Loading
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        style={{ width: '40%', height: 50, marginBottom: 10 }}
                        className={classes.loadingBackground}
                    >
                        Loading
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography className={classes.loadingBackground}>
                        Loading
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: 10
                    }}
                >
                    <Typography
                        className={classes.loadingBackground}
                        style={{ width: '40%' }}
                    >
                        Loading
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table
                        loading
                        data={[]}
                        rowCard={() => null}
                        page={0}
                        onPageChange={() => null}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CommunityDetailsLoading;
