import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '../Checkbox/Checkbox';
import rectangle from '../../static/images/rectangle.png';
import {ReactComponent as TrashIcon} from '../../static/images/icons/icon-trash.svg';

const styles = makeStyles({
    container: {
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        padding: '10px 20px',
        minHeight: 80,
        marginBottom: 10,
        display: 'flex',
        cursor: 'pointer'
    },
    profileContainer: {
        display: 'flex',
        alignItems: 'center',
        // borderRight: '2px solid #dadada',
        width: '100%',
        maxWidth: 193,
        paddingRight: 15
    },
    leaderContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        borderRight: '2px solid #dadada',
        width: '100%',
        maxWidth: '35%',
        padding: '0px 20px'
    },
    title: {
        fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
        fontSize: 14,
        cursor: 'pointer'
    },
    description: {
        fontFamily: 'SFUIText-Reg, Roboto, Helvetica, Arial, sans-serif',
        fontSize: 12,
        cursor: 'pointer'
    },
    teamContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        flex: 1,
        padding: '0px 20px'
    },
    teamChip: {
        padding: '0px 10px',
        backgroundColor: '#adb6c1',
        color: '#ffffff',
        fontSize: 10,
        marginRight: 4
    },
    menuContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    paperRoot: {
        borderRadius: 15
    },
    menuItemRoot: {
        fontSize: 12,
        '&:not(:last-child)': {
            borderBottom: '1px solid rgba(34, 63, 99, 0.1)'
        }
    },
    officerChip: {
        height: 20,
        borderRadius: 25,
        backgroundColor: 'none !important',
        color: '#ffffff',
        fontSize: 8,
        backgroundImage: 'linear-gradient(to left, #2e598d, #072144)',
        marginRight: 5
    },
    wrap: ({ size }) => ({
        position: 'relative',
        height: size,
        width: size
    }),
    image: ({ size, image, borderRadius, shadow, computedLoading }) => ({
        position: 'absolute',
        height: size,
        width: size,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: `url(${image})`,
        borderRadius,
        boxShadow: shadow ? '0 2px 4px -2px rgba(0, 0, 0, 0.2)' : 'none',
        backgroundColor: '#ffffff',
        '&::after': {
            content: '\'\'',
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: computedLoading ? 'rgba(0,0,0,0.5)' : 'transparent',
            borderRadius
        }
    }),
    circular: {
        padding: 10
    },
    categories: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        flex: 1,
    }
});

export default function ToolkitCard({
    id,
    onClick,
    title,
    description,
    thumbnail,
    toolkitCategories,
    onCheck,
    isChecked,
    enableCheckbox,
    onRemove,
    enableDelete
}) {
    const [checked, setChecked] = useState(false);
    // Hooks
    const classes = styles({
        size: 60,
        image: thumbnail || rectangle,
        borderRadius: 10,
        shadow: true,
        computedLoading: false
    });

    useEffect(() => {
        setChecked(isChecked)
    }, [isChecked])

    return (
        <div className={classes.container} role="presentation" key={`row${id}`}>
            <div className={classes.profileContainer}>
                {enableCheckbox && (<div>
                    <Checkbox
                        checked={checked}
                        onChange={() => {
                            setChecked(!checked)
                            onCheck()
                        }}
                    />
                </div>)}
                <div onClick={onClick}>
                    <div className={classes.wrap}>
                        <div className={classes.image} onClick={onClick} role="presentation" />
                    </div>
                </div>
                <div onClick={onClick} style={{marginLeft: '1rem'}}>
                    <Typography noWrap className={classes.title}>
                        {title}
                    </Typography>
                    <Typography noWrap className={classes.description}>
                        {description}
                    </Typography>
                </div>
            </div>
            {toolkitCategories.length > 0 && (
                <div onClick={onClick} className={classes.categories}>
                    {toolkitCategories.map(tc => (<Chip className={classes.teamChip} label={tc.name} />))}
                </div>
            ) || (<div onClick={onClick} style={{width: '100%'}} />)}
            {enableDelete && (
                <IconButton onClick={onRemove} data-test-id="button-leader-remove" style={{left: '60%'}}>
                    <TrashIcon />
                </IconButton>
            )}
        </div>
    );
}

ToolkitCard.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    categories: PropTypes.array,
    toolkitCategories: PropTypes.array,
    menuActions: PropTypes.array,
    readOnly: PropTypes.bool,
    onClick: PropTypes.func,
    onCheck: PropTypes.func,
    onRemove: PropTypes.func,
    isChecked: PropTypes.bool,
    enableCheckbox: PropTypes.bool,
    enableDelete: PropTypes.bool,
};

ToolkitCard.defaultProps = {
    menuActions: null,
    readOnly: false,
    categories: [],
    toolkitCategories: [],
    onClick: null,
    onCheck: null,
    onRemove: null,
    isChecked: false,
    enableCheckbox: true,
    enableDelete: false,
    thumbnail: '',
};