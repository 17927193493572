import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';

import { ReactComponent as TickIcon } from '../../static/images/icons/icon-tick.svg';

const styles = makeStyles(theme => ({
    root: {
        userSelect: 'none'
    },
    label: {
        fontSize: 12,
        fontWeight: 300,
        color: theme.palette.text.secondary,
        fontFamily: 'SFUIText-Light'
    },
    labelUnchecked: {
        color: '#838b96 !important'
    },
    unchecked: {
        width: 18,
        height: 18,
        borderRadius: 4,
        backgroundColor: '#ffffff'
    },
    checked: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export default function Checkbox({
    label,
    checked,
    value,
    onChange,
    disabled,
    className,
    name
}) {
    const classes = styles();

    const uncheckedIcon = <div className={classes.unchecked} />;

    const checkedIcon = (
        <div className={clsx(classes.unchecked, classes.checked)}>
            <TickIcon />
        </div>
    );

    const control = (
        <MuiCheckbox
            name={name}
            size="small"
            icon={uncheckedIcon}
            checkedIcon={checkedIcon}
            checked={checked}
            color="primary"
            onChange={onChange}
            value={value}
            disabled={disabled}
        />
    );

    return (
        <FormControlLabel
            label={label}
            disabled={disabled}
            control={control}
            classes={{
                root: classes.root,
                label: clsx({
                    [classes.label]: true,
                    [classes.labelUnchecked]: !checked
                })
            }}
            className={className}
        />
    );
}

Checkbox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string
};

Checkbox.defaultProps = {
    label: '',
    checked: false,
    value: '',
    disabled: false,
    onChange: null,
    className: '',
    name: ''
};
