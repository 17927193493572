import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';
import usePrevious from '../../hooks/usePrevious';
import * as modalTypes from '../../state/modal/types';
import Button from '../../components/Button/Button';
import {contentParser, flattenCategories} from '../../utils/toolkitHelpers';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';

const useStyles = makeStyles({
    gridContainer: {
        maxWidth: 450,
        flex: 1,
        margin: 'auto'
    },
    title: {
        fontSize: 24,
        fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
        color: '#223f63',
        marginBottom: 20
    },
    message: {
        fontSize: 16
    },
    buttonGroup: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    },
    button: {
        width: 150,
        margin: '0px 10px'
    },
    toolkitContainer: {
        overflowY: 'scroll',
        maxHeight: 1000,
        height: 450,
    },
    thumbnail: ({thumbnail}) => ({
        backgroundImage: `url(${thumbnail})`,
        backgroundColor: '#1C1C1C',
        height: 250,
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }),
    content: {
        minHeight: 250,
        paddingBottom: '1rem',
        backgroundColor: '#3E3E3E',
        marginTop: '-2rem',
        border: '1px solid grey',
    }
});

export default function PreviewToolkitModal({
    title,
    cancelText,
    onCancel,
    savingSelector,
    toolkit
}){
    // Hooks
    const classes = useStyles({
        thumbnail: toolkit?.thumbnail || ''
    });
    const dispatch = useDispatch();

    // Redux
    const saving = savingSelector ? useSelector(state => savingSelector(state)) : null;
    const prevSaving = usePrevious(saving);

    // Close when redux save state goes from true to false
    useEffect(() => {
        if (prevSaving && !saving) closeModal();
    }, [saving, prevSaving]);

    // Event handlers
    const closeModal = () => {
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
    };

    const handleCancel = () => {
        if (onCancel !== null) return onCancel();
        return closeModal();
    };

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid item md={12}>
                <Typography align="center" className={classes.title}>
                    {title}
                </Typography>
            </Grid>
            <Grid item md={12} className={classes.toolkitContainer}>
                <div className={classes.thumbnail} />
                <div className={classes.content}>
                    <div style={{
                        paddingTop: '1rem',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                    }}
                    >
                        <div style={{marginTop: '2rem'}}>
                            <small style={{color: '#B6B6B6'}}>{flattenCategories(toolkit.categories).join(', ')}</small>
                            <h1 style={{
                                color: '#848484',
                                fontfamily: 'SFUIDisplay-Reg',
                                marginTop: '-0.2rem'
                            }}
                            >
                                {toolkit.title}
                            </h1>
                        </div>
                        <div style={{marginTop: '1rem', color: '#FFFFFF'}}>
                            <p style={{display: 'none'}}>{JSON.stringify(toolkit.content)}</p>
                            <RichTextEditor
                                defaultValue={contentParser(toolkit.content) || []}
                                readOnly
                            />
                        </div>
                        {toolkit.video && (<div style={{marginTop: '2rem'}}>
                            <small style={{color: '#B6B6B6'}}>Video</small>
                            <div style={{height: 200, marginTop: '0.5rem'}}>
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    url={toolkit.video}
                                    onReady={() => {
                                    }}
                                    onError={(e) => {
                                        if (e.type === 'error') {
                                            console.error('Invalid video URL.')
                                        }
                                    }}
                                />
                            </div>
                        </div>)}
                    </div>
                </div>
            </Grid>
            <Grid item md={12} className={classes.buttonGroup}>
                <Button
                    background="grey"
                    label={cancelText}
                    onClick={handleCancel}
                />
            </Grid>
        </Grid>
    );
}

PreviewToolkitModal.propTypes = {
    title: PropTypes.string,
    cancelText: PropTypes.string,
    onCancel: PropTypes.func,
    savingSelector: PropTypes.func,
    toolkit: PropTypes.object.isRequired,
};

PreviewToolkitModal.defaultProps = {
    title: 'Preview',
    cancelText: 'Close',
    onCancel: null,
    savingSelector: null,
};