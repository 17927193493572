import { createSelector } from 'reselect'
import * as types from './types';
import { upsertArrayByKey } from '../../utils/helpers';

const initialState = {
    loadingAllToolkits: false,
    teamToolkits: {},
    fetchingTeamToolkits: [],
    allToolkits: [],
    updatingToolkits: [],
    loadingState: false,
    savingState: false,
    toolkitCreated: false,
    toolkitsAssigned: false,
    toolkits: [],
    userToolkits: [],
    apiErrors: null,
    selectedToolkit: undefined
};

const communityReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.GET_ALL_TOOLKITS:
            return {
                ...state,
                loadingAllToolkits: true 
            }
        case types.GET_ALL_TOOLKITS_SUCCESS: 
            return {
                ...state,
                loadingAllToolkits: false, 
                allToolkits: action.payload
            }
        
        case types.GET_ALL_TOOLKITS_ERROR:
            return {
                ...state,
                loadingAllToolkits: false, 
            }


        case types.GET_TEAM_TOOLKITS:
            return {
                ...state,
                fetchingTeamToolkits: [...state.fetchingTeamToolkits, action.payload._id]
            }
        case types.GET_TEAM_TOOLKITS_SUCCESS:
            return {
                ...state,
                fetchingTeamToolkits: state.fetchingTeamToolkits.filter(id => id !== action.payload.team),
                teamToolkits: {
                    ...state.teamToolkits,
                    [action.payload.teamId]: action.payload.toolkits
                }
            }
        case types.GET_TEAM_TOOLKITS_ERROR:
            return {
                ...state,
                fetchingTeamToolkits: state.fetchingTeamToolkits.filter(id => id !== action.payload.team)
            }

        case types.ADD_TOOLKIT_TO_TEAM:
            return {
                ...state,
                updatingToolkits: [...state.updatingToolkits, action.payload.toolkitId]
            }
        case types.ADD_TOOLKIT_TO_TEAM_SUCCESS:
            return {
                ...state,
                teamToolkits: {
                    ...state.teamToolkits,
                    [action.payload.teamId]: upsertArrayByKey('id', state.teamToolkits[action.payload.teamId], action.payload.toolkits)
                },
                updatingToolkits: state.updatingToolkits.filter(id => id !== action.payload.toolkitId)

            }
        case types.ADD_TOOLKIT_TO_TEAM_ERROR:
            return {
                ...state,   
                updatingToolkits: state.updatingToolkits.filter(id => id !== action.payload.toolkitId)
            }


        case types.REMOVE_TOOLKIT_FROM_TEAM:
            return {
                ...state,
                updatingToolkits: [...state.updatingToolkits, action.payload.toolkitId],
            }

        case types.REMOVE_TOOLKIT_FROM_TEAM_SUCCESS:
            return {
                ...state,
                updatingToolkits: state.updatingToolkits.filter(id => id !== action.payload.toolkitId),
                teamToolkits: {
                    ...state.teamToolkits,
                    [action.payload.teamId]: action.payload.toolkits
                }
            }

        case types.REMOVE_TOOLKIT_FROM_TEAM_ERROR:
            return {
                ...state,
                updatingToolkits: state.updatingToolkits.filter(id => id !== action.payload.toolkitId)
            }

        case types.TOOLKIT_LOADING_STATE:
            return {
                ...state,
                loadingState: action.state
            };

        case types.TOOLKIT_SAVING_STATE:
            return {
                ...state,
                savingState: action.state
            };

        case types.CREATE_TOOLKIT_SUCCESS:
            return {
                ...state,
                toolkitCreated: true,
                apiErrors: null
            };

        case types.EDIT_TOOLKIT_SUCCESS:
            return {
                ...state,
                apiErrors: null
            };

        case types.DELETE_TOOLKIT_SUCCESS:
            return {
                ...state,
                apiErrors: null
            };

        case types.ASSIGN_TOOLKITS_SUCCESS:
            return {
                ...state,
                apiErrors: null
            };

        case types.CLEAR_TOOLKIT_CREATED:
            return {
                ...state,
                toolkitCreated: false
            };

        case types.GET_TOOLKITS_SUCCESS:
            return {
                ...state,
                toolkits: action.toolkits
            };

        case types.GET_USER_TOOLKITS_SUCCESS:
            return {
                ...state,
                userToolkits: action.userToolkits
            };

        case types.GET_TOOLKIT_SUCCESS:
            return {
                ...state,
                selectedToolkit: action.toolkit
            };

        case types.REMOVE_TOOLKIT_FROM_USER_SUCCESS:
            return {
                ...state,
                userToolkits: action.userToolkits
            };

        case types.CREATE_TOOLKIT_ERROR:
        case types.EDIT_TOOLKIT_ERROR:
        case types.ASSIGN_TOOLKITS_ERROR:
        case types.GET_TOOLKITS_ERROR:
        case types.GET_USER_TOOLKITS_ERROR:
        case types.REMOVE_TOOLKIT_FROM_USER_ERROR:
        case types.DELETE_TOOLKIT_ERROR:
            return {
                ...state,
                apiErrors: action.errors
            };
        default:
            return state;
    }
}

export default communityReducer;

export const allToolkitSelector = state => state.toolkit.allToolkits;
export const teamToolkitSelector = state => state.toolkit.teamToolkits
export const updatingToolkitsSelector = state => state.toolkit.updatingToolkits
const idSelector = (_, id) => id;

export const selectToolkitsForTeam = createSelector(
    [allToolkitSelector, teamToolkitSelector, updatingToolkitsSelector, idSelector],
    (allToolkits, teamToolkits, updatingToolkits, teamID) => {
        const assignedToolkits = teamToolkits[teamID] ? teamToolkits[teamID].map(t => t._id) : [];
        return allToolkits
            .filter(t => t.isPublished)
            .map(t => ({
                ...t,
                inCommunity: assignedToolkits.includes(t._id),
                isLoading: updatingToolkits.includes(t._id)
            }))
    }
)

export const selectToolkits = state => state.toolkit.toolkits;
export const selectToolkitsLoadingState = state => state.toolkit.loadingState;
export const selectUserToolkits = state => state.toolkit.userToolkits;
export const selectSelectedToolkit = state => state.toolkit.selectedToolkit;
