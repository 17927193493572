export default {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: '#e4e4e4',
        color: '#ffffff'
    },
    logo: {
        height: 75,
        width: 70,
        marginBottom: 80
    },
    bottomLink: {
        textTransform: "uppercase",
        fontWeight: 700,
        fontSize: 12,
        marginTop: 40,
        "& a": {
            color: "#838b96",
            "&:hover": {
                color: "#e2edfc"
            }
        }
    },
    appContainer: {
        position: 'relative',
        overflowX: 'hidden'
    },
    appContent: {
        paddingTop: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#fefefe'
    },
    appStores: {
        marginBottom: 161
    },
    apple: {
        marginRight: 15
    }
};
