import React, { Component } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';

import AuthenticatedRoute from './AuthenticatedRoute';
import Route404 from '../containers/Route404';

import * as authServices from '@/state/auth/services';

import routes from '@/constants/routes';

import SnackWrapper from '@/components/SnackWrapper/SnackWrapper';

class RouteGuardian extends Component {
    componentDidMount() {
        const {
            history,
            location: { pathname }
        } = this.props;
        if (pathname === '/') {
            const idToken = authServices.getLocalStore('idToken');
            // Attempt to get user role/permission and send them to the default landing
        }
    }

    render() {
        return (
            <>
                <Switch>
                    {routes.map(route => {
                        if (route.authenticated)
                            return <AuthenticatedRoute {...route} />;
                        return <Route exact {...route} />;
                    })}
                    <Route component={Route404} />
                </Switch>
                <SnackWrapper />
            </>
        );
    }
}

export default withRouter(RouteGuardian);
