import * as types from './types';
import * as communityTypes from '../communities/types';

const initialState = {
    open: false,
    component: null,
    props: {},
}

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MODAL_SET_COMPONENT:
            return {
                ...state,
                open: action.open || state.open,
                component: action.component,
                props: action.props,
            };

        case types.MODAL_SET_OPEN_STATE:
            return {
                ...state,
                open: action.state
            }

        case communityTypes.REMOVE_USER_SUCCESS:
        case communityTypes.REMOVE_USER_ERROR:
            return {
                ...state,
                open: false,
            }

        case types.ERROR_MODAL:
            return {
                ...state,
                open: true,
                component: types.API_ERROR_MODAL,
                props: {
                    code: action.error?.response?.data?.code || '',
                    message: action.error?.response?.data?.message || 'Something went wrong'
                }
            };
    
        default:
            return state;
    }
}

export default modalReducer;

export const selectModalState = state => state.modal.open;
export const selectModalComponent = state => state.modal.component;
export const selectModalProps = state => state.modal.props;