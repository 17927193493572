import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = makeStyles({
    label: {
        width: '100%',
        cursor: 'pointer'
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        height: 40,
        width: '100%',
        borderRadius: 10,
        backgroundColor: '#f4f4f4',
        display: 'flex',
        alignItems: 'center',
        padding: 7
    },
    inputText: {
        fontSize: 12,
        color: '#223f63',
        width: 'fit-content',
        flex: 1
    },
    browse: {
        width: 70,
        height: 25,
        borderRadius: 8,
        backgroundColor: '#dcdcdc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 3
    },
    browseText: {
        fontSize: 11,
        fontWeight: 500
    }
});

export default function FileInput({ onChange, value }) {
    const classes = styles();

    const inputText = value.length
        ? value.map(f => f.name).join(', ')
        : 'Upload a CSV file';

    return (
        <div className={classes.flexCenter}>
            <label htmlFor="csv-upload" className={classes.label}>
                <div className={classes.input}>
                    <Typography className={classes.inputText} noWrap>
                        {inputText}
                    </Typography>
                    <div className={classes.browse}>
                        <Typography className={classes.browseText}>
                            Browse
                        </Typography>
                    </div>
                </div>
                <input
                    id="csv-upload"
                    style={{ display: 'none' }}
                    type="file"
                    accept=".csv"
                    onChange={onChange}
                />
            </label>
        </div>
    );
}

FileInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired
};
