import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Button from '../../components/Button/Button';
import SearchInput from '../../components/SearchInput/SearchInput';
import Table from '../../components/Table/Table';
import CommunityRowCard from '../../components/RowCards/CommunityRowCard';
import { ReactComponent as AddIcon } from '../../static/images/icons/icon-add.svg';

import * as modals from '../../constants/modals';
import * as modalTypes from '../../state/modal/types';
import * as communityTypes from '../../state/communities/types';
import * as userTypes from '../../state/users/types';

import {
    selectCommunities,
    selectCommunityLoadingState,
    selectTotalCommunities
} from '../../state/communities/reducers';

class CommunityList extends Component {
    state = {
        search: '',
        page: 0
    };

    componentDidMount() {
        const { getCommunities, getUsers } = this.props;
        const { search, page } = this.state;
        getCommunities(page, search);

        // Get users here for modal
        getUsers();
    }

    onViewDetails = rowData => {
        const { history } = this.props;
        const { _id } = rowData;
        history.push(`/communities/${_id}`);
    };

    onSearchChange = search => {
        this.setState(
            {
                page: 0,
                search
            },
            this.getNewCommunityList
        );
    };

    onPageChange = page => {
        this.setState({ page }, this.getNewCommunityList);
    };

    getNewCommunityList = () => {
        const { page, search } = this.state;
        const { getCommunities } = this.props;
        getCommunities(page, search);
    };

    onAddCommunity = () => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.ADD_COMMUNITY_MODAL);
        openModal();
    };

    render() {
        const { search, page } = this.state;
        const { communities, loadingState, totalCommunities } = this.props;
        return (
            <Container>
                <Typography
                    style={{
                        fontFamily: 'SFUIDisplay-Bold',
                        fontSize: 36,
                        color: '#072144',
                        marginBottom: 36
                    }}
                >
                    Communities
                </Typography>
                <Box display="flex" marginBottom={2}>
                    <SearchInput
                        style={{ flex: 1 }}
                        placeholder="Search all communities…."
                        onChange={this.onSearchChange}
                        value={search}
                    />

                    <Button
                        label="Add"
                        style={{ width: 180, marginLeft: 120 }}
                        onClick={this.onAddCommunity}
                        icon={<AddIcon />}
                    />
                </Box>

                <Table
                    loading={loadingState}
                    data={communities}
                    rowCard={CommunityRowCard}
                    noDataMessage="No data found"
                    count={totalCommunities}
                    page={page}
                    onPageChange={this.onPageChange}
                    onRowClick={this.onViewDetails}
                    serverSidePagination
                />
            </Container>
        );
    }
}

CommunityList.propTypes = {
    history: PropTypes.object.isRequired,
    communities: PropTypes.array.isRequired,
    totalCommunities: PropTypes.number.isRequired,
    loadingState: PropTypes.bool.isRequired,
    setModalComponent: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    getCommunities: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    communities: selectCommunities(state),
    totalCommunities: selectTotalCommunities(state),
    loadingState: selectCommunityLoadingState(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            }),
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            getCommunities: (page, search) => ({
                type: communityTypes.GET_COMMUNITIES,
                page,
                search
            }),
            getUsers: () => ({
                type: userTypes.GET_USERS
            })
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CommunityList);
