import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fuse from 'fuse.js';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CommunityDetailsLoading from '../../components/CommunityDetailsLoading/CommunityDetailsLoading';
import ToolkitsTab from '../../components/Communities/ToolkitsTab';

import BackToParent from '../../components/BackToParent/BackToParent';
import CommunityDetailsHeader from '../../components/CommunityDetailsHeader/CommunityDetailsHeader';
import SearchInput from '../../components/SearchInput/SearchInput';
import Button from '../../components/Button/Button';
import Table from '../../components/Table/Table';
import MemberRowCard from '../../components/RowCards/MemberRowCard';
import { ReactComponent as AddIcon } from '../../static/images/icons/icon-add.svg';
import { ReactComponent as BatchIcon } from '../../static/images/icons/icon-batch.svg';
import * as modals from '../../constants/modals';
import * as modalTypes from '../../state/modal/types';
import * as communityTypes from '../../state/communities/types';
import * as userTypes from '../../state/users/types';
import {
    selectSelectedCommunity,
    selectCommunityLoadingState,
    selectCommunitySavingState,
    selectCommunityUsers
} from '../../state/communities/reducers';

import { checkAndGetPermissions, sortByCreated } from '../../utils/helpers';

const fuseOpts = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['firstName', 'lastName']
};

class CommunityDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 0,
            selectedTab: 0
        };

        const communityId = this.getIdFromRoute(props);
        const { getCommunity, getOfficers } = props;
        getCommunity(communityId);
        getOfficers();
    }

    getIdFromRoute = props => {
        const {
            match: { params }
        } = props;
        const { uuid } = params;
        return uuid;
    };

    onEditCommunity = () => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.EDIT_COMMUNITY_MODAL);
        openModal();
    };

    onAdd = () => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.ADD_USER_TO_COMMUNITY_MODAL);
        openModal();
    };

    onAssignLeader = row => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.ASSIGN_LEADER_MODAL, { selectedUser: row });
        openModal();
    };

    onRemove = row => {
        const { setModalComponent, openModal, removeUserFromCommunity } = this.props;
        setModalComponent(modals.PROMPT_BOOLEAN_MODAL, {
            message: `You are about to remove ${row.firstName} ${row.lastName} from the community...`,
            savingSelector: selectCommunitySavingState,
            onYes: () => removeUserFromCommunity(row._id)
        });
        openModal();
    };

    onPageChange = page => {
        this.setState({ page });
    };

    onSearchChange = search => {
        this.setState({ search });
    };

    onSetAdmin = (userId, communityId, isAdmin) => {
        const { setCommunityAdmin, removeCommunityAdmin } = this.props;
        if (isAdmin) removeCommunityAdmin(userId, communityId);
        else setCommunityAdmin(userId, communityId);
    };

    onBatchAdd = () => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.BATCH_ADD_USER_MODAL);
        openModal();
    };

    menuActions = row => {
        const { memberships } = row;
        const { selectedCommunity } = this.props;
        const { _id } = selectedCommunity;
        const { isComAdmin } = checkAndGetPermissions(memberships, _id);
        const actions = [
            {
                onClick: () => this.onSetAdmin(row._id, _id, isComAdmin),
                label: isComAdmin ? 'Remove community admin' : 'Make community admin'
            },
            {
                onClick: () => this.onRemove(row),
                label: 'Remove from community'
            },
            {
                onClick: () => this.onAssignLeader(row),
                label: 'Assign leaders'
            }
        ];
        return row.isDeleted ? null : actions;
    };

    searchUsers = users => {
        const { search } = this.state;
        const fuseInstance = new Fuse(users, fuseOpts);
        return fuseInstance.search(search);
    };

    render() {
        const { page, search, selectedTab } = this.state;
        const { loadingState, selectedCommunity, communityUsers } = this.props;

        const userResults =
            search.trim().length > 0
                ? this.searchUsers(communityUsers)
                : communityUsers;

        const sortedUsers = sortByCreated(userResults).filter(u => !u.isDeleted);
        const archivedUsers = sortedUsers.filter(u => u.isDeleted);
        const computedUsers = [...sortedUsers, ...archivedUsers];

        return (
            <Container>
                <BackToParent text="Back to communities" route="/communities" />
                {loadingState || selectedCommunity == null ? (
                    <CommunityDetailsLoading />
                ) : (
                    <>
                        <CommunityDetailsHeader
                            title={selectedCommunity.title}
                            description={selectedCommunity.description}
                            onEdit={this.onEditCommunity}
                        />
                        
                        <Box marginTop={2} marginBottom={2}>
                            <Tabs
                                
                                value={selectedTab}
                                onChange={(_, selectedTab) => this.setState({ selectedTab })}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Users" />
                                <Tab label="Toolkits" />
                            </Tabs>
                        </Box>
                        { selectedTab === 0 &&
                            <>
                            <Box display="flex" marginBottom={2} marginTop={4}>
                                <SearchInput
                                    style={{ flex: 1 }}
                                    placeholder="Search all members in this community..."
                                    value={search}
                                    onChange={this.onSearchChange}
                                />
                                <IconButton
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: '#dcdcdc',
                                        borderRadius: 20,
                                        padding: '12px 18px',
                                        marginLeft: 120
                                    }}
                                    onClick={this.onBatchAdd}
                                >
                                    <BatchIcon />
                                </IconButton>
                                <Button
                                    style={{ marginLeft: 20, width: 180 }}
                                    label="Add"
                                    icon={<AddIcon />}
                                    onClick={this.onAdd}
                                />
                            </Box>
                            <Table
                                loading={loadingState}
                                data={computedUsers}
                                rowCard={MemberRowCard}
                                count={communityUsers.length}
                                menuActions={this.menuActions}
                                page={page}
                                onPageChange={this.onPageChange}
                            />
                            </>
                        }

                        {selectedTab === 1 &&
                            <ToolkitsTab />
                        }
                    </>
                )}
            </Container>
        );
    }
}

CommunityDetails.propTypes = {
    getCommunity: PropTypes.func.isRequired,
    loadingState: PropTypes.bool.isRequired,
    selectedCommunity: PropTypes.object,
    communityUsers: PropTypes.array.isRequired,
    setModalComponent: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    setCommunityAdmin: PropTypes.func.isRequired,
    removeUserFromCommunity: PropTypes.func.isRequired,
    getOfficers: PropTypes.func.isRequired,
    removeCommunityAdmin: PropTypes.func.isRequired
};

CommunityDetails.defaultProps = {
    selectedCommunity: null
};

const mapStateToProps = state => ({
    loadingState: selectCommunityLoadingState(state),
    selectedCommunity: selectSelectedCommunity(state),
    communityUsers: selectCommunityUsers(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            }),
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            getCommunity: communityId => ({
                type: communityTypes.GET_COMMUNITY,
                communityId
            }),
            setCommunityAdmin: (userId, communityId) => ({
                type: communityTypes.SET_USER_ADMIN,
                userId,
                communityId
            }),
            removeCommunityAdmin: (userId, communityId) => ({
                type: communityTypes.REMOVE_USER_ADMIN,
                userId,
                communityId
            }),
            removeUserFromCommunity: userId => ({
                type: communityTypes.REMOVE_USER,
                userId
            }),
            getOfficers: () => ({
                type: userTypes.GET_OFFICERS
            })
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CommunityDetails);
