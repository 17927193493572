import { takeLatest, call, put, select } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';

import * as communityActions from '../communities/actions';

import * as snackTypes from '../snack/types';
import * as modalTypes from '../modal/types';

import { selectUsers } from './reducers';

function* getUsers({ page, search }) {
    try {
        yield put({ type: types.USERS_LOADING_STATE, state: true });

        const result = yield call(actions.getUsers, page, search);
        const {
            data: { results, total }
        } = result;

        yield put({
            type: types.GET_USERS_SUCCESS,
            users: results,
            totalUsers: total
        });

        yield put({ type: types.USERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({
            type: types.GET_USERS_ERROR,
            errors: {
                key: types.GET_USERS_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
    }
}

function* createUser(action) {
    try {
        yield put({ type: types.USERS_SAVING_STATE, state: true });
        const result = yield call(actions.createUser, action.payload);
        yield put({ type: types.CREATE_USER_SUCCESS, user: result.data });

        yield put({ type: types.USERS_SAVING_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User successfully created',
            open: true,
            props: { variant: 'success' }
        });
    } catch (error) {
        yield put({ type: types.USERS_SAVING_STATE, state: false });
        // Send api errors to API
        yield put({
            type: types.CREATE_USER_ERROR,
            errors: {
                key: types.CREATE_USER_ERROR,
                errors: error.response.data.errors
            }
        });

        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* inviteUserToCommunity({ communityId, payload }) {
    try {
        yield put({ type: types.USERS_SAVING_STATE, state: true });
        const result = yield call(
            communityActions.inviteUserToCommunity,
            communityId,
            payload
        );
        const {
            data: { user }
        } = result;
        yield put({ type: types.INVITE_USER_TO_COMMUNITY_SUCCESS, user });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User has been invited to the community!',
            open: true,
            props: { variant: 'success' }
        });
        yield put({ type: types.USERS_SAVING_STATE, state: false });
    } catch (error) {
        yield put({
            type: types.INVITE_USER_TO_COMMUNITY_ERROR,
            errors: {
                key: types.INVITE_USER_TO_COMMUNITY_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.USERS_SAVING_STATE, state: false });
    }
}

function* getAllOfficers() {
    try {
        yield put({ type: types.USERS_LOADING_STATE, state: true });

        const result = yield call(actions.getUsers, 0, 'officer', 999999);
        const {
            data: { results }
        } = result;
        yield put({ type: types.GET_OFFICERS_SUCCESS, officers: results });

        yield put({ type: types.USERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({
            type: types.GET_OFFICERS_ERROR,
            errors: {
                key: types.GET_OFFICERS_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
    }
}

function* addOfficerRole({ userId }) {
    try {
        yield put({ type: types.USERS_SAVING_STATE, state: true });

        const result = yield call(actions.assignOfficer, userId);
        const { data } = result;

        yield put({ type: types.ASSIGN_OFFICER_ROLE_SUCCESS, user: data });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User successfully promoted to officer',
            open: true,
            props: { variant: 'success' }
        });

        yield put({ type: types.USERS_SAVING_STATE, state: false });
    } catch (error) {
        yield put({
            type: types.ASSIGN_OFFICER_ROLE_ERROR,
            errors: {
                key: types.ASSIGN_OFFICER_ROLE_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.USERS_SAVING_STATE, state: false });
    }
}

function* removeOfficerRole({ userId }) {
    try {
        yield put({ type: types.USERS_SAVING_STATE, state: true });

        const result = yield call(actions.removeOfficer, userId);
        const { data } = result;

        yield put({ type: types.REMOVE_OFFICER_ROLE_SUCCESS, user: data });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Officer role removed from user',
            open: true,
            props: { variant: 'success' }
        });

        yield put({ type: types.USERS_SAVING_STATE, state: false });
    } catch (error) {
        yield put({
            type: types.REMOVE_OFFICER_ROLE_ERROR,
            errors: {
                key: types.REMOVE_OFFICER_ROLE_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.USERS_SAVING_STATE, state: false });
    }
}

function* archiveUser({ userId }) {
    try {
        yield put({ type: types.USERS_SAVING_STATE, state: true });

        const result = yield call(actions.archiveUser, userId);
        const { data } = result;
        // response doesnt contain any user data

        const users = yield select(selectUsers);
        const updatedUsers = users.map(user => {
            if (user._id === data._id) return data;
            return user;
        });
        yield put({ type: types.ARCHIVE_USER_SUCCESS, users: updatedUsers });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User has been successfully archived',
            open: true,
            props: { variant: 'success' }
        });

        yield put({ type: types.USERS_SAVING_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
    } catch (error) {
        yield put({
            type: types.ARCHIVE_USER_ERROR,
            errors: {
                key: types.ARCHIVE_USER_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.USERS_SAVING_STATE, state: false });
    }
}

export default [
    takeLatest(types.CREATE_USER, createUser),
    takeLatest(types.GET_USERS, getUsers),
    takeLatest(types.INVITE_USER_TO_COMMUNITY, inviteUserToCommunity),
    takeLatest(types.ASSIGN_OFFICER_ROLE, addOfficerRole),
    takeLatest(types.REMOVE_OFFICER_ROLE, removeOfficerRole),
    takeLatest(types.GET_OFFICERS, getAllOfficers),
    takeLatest(types.ARCHIVE_USER, archiveUser)
];
