export const CREATE_TOOLKIT_CATEGORY = 'toolkits/CREATE_TOOLKIT_CATEGORY';
export const CREATE_TOOLKIT_CATEGORY_SUCCESS = 'toolkits/CREATE_TOOLKIT_CATEGORY_SUCCESS';
export const CREATE_TOOLKIT_CATEGORY_ERROR = 'toolkits/CREATE_TOOLKIT_CATEGORY_ERROR';

export const EDIT_TOOLKIT_CATEGORY = 'toolkits/EDIT_TOOLKIT_CATEGORY';
export const EDIT_TOOLKIT_CATEGORY_SUCCESS = 'toolkits/EDIT_TOOLKIT_CATEGORY_SUCCESS';
export const EDIT_TOOLKIT_CATEGORY_ERROR = 'toolkits/EDIT_TOOLKIT_CATEGORY_ERROR';

export const GET_TOOLKIT_CATEGORIES = 'toolkits/GET_TOOLKIT_CATEGORIES';
export const GET_TOOLKIT_CATEGORIES_SUCCESS = 'toolkits/GET_TOOLKIT_CATEGORIES_SUCCESS';
export const GET_TOOLKIT_CATEGORIES_ERROR = 'toolkits/GET_TOOLKIT_CATEGORIES_ERROR';

export const DELETE_TOOLKIT_CATEGORY = 'toolkits/DELETE_TOOLKIT_CATEGORY';
export const DELETE_TOOLKIT_CATEGORY_SUCCESS = 'toolkits/DELETE_TOOLKIT_CATEGORY_SUCCESS';
export const DELETE_TOOLKIT_CATEGORY_ERROR = 'toolkits/DELETE_TOOLKIT_CATEGORY_ERROR';

export const TOOLKIT_CATEGORY_LOADING_STATE = 'toolkits/TOOLKIT_CATEGORY_LOADING_STATE';
export const TOOLKIT_CATEGORY_SAVING_STATE = 'toolkits/TOOLKIT_CATEGORY_SAVING_STATE';
export const SET_TOOLKIT_CATEGORY = 'toolkits/SET_TOOLKIT_CATEGORY';