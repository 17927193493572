import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '../../components/Button/Button';
import FileInput from '../../components/FileInput/FileInput';

import {
    selectCommunitySavingState,
    selectBatchResults
} from '../../state/communities/reducers';
import * as communityTypes from '../../state/communities/types';

const styles = makeStyles(theme => ({
    modalTitle: {
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 24,
        marginBottom: 5
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 10,
        color: '#838b96',
        fontWeight: 700,
        marginBottom: 10
    },
    info: {
        fontSize: 12,
        marginBottom: 20
    },
    error: {
        fontSize: 11,
        color: theme.palette.error.main
    },
    mb20: {
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: 150
    },
    resultContainer: {
        overflow: 'auto',
        width: '100%',
        maxHeight: 575
    },
    resultTitle: {
        marginBottom: 10,
        fontSize: 16
    },
    user: {
        cursor: 'pointer',
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        padding: '10px 20px',
        minHeight: 40,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 12,
        color: '#223f63'
    }
}));

const BatchAddUserModal = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const [value, setValue] = useState([]);
    const [error, setError] = useState('');

    const clearBatchResults = () => {
        dispatch({
            type: communityTypes.CLEAR_BATCH_RESULTS
        });
    };

    // Clear results on mount
    useEffect(() => {
        clearBatchResults();
    }, []);

    // Redux
    const saveState = useSelector(state => selectCommunitySavingState(state));
    const batchResults = useSelector(state => selectBatchResults(state));

    const handleChange = e => {
        const { files } = e.target;
        setValue([...files]);
    };

    const submitData = () => {
        const formData = new FormData();
        formData.append('csv', value[0]);

        dispatch({
            type: communityTypes.BATCH_UPLOAD_USERS,
            formData
        });
    };

    const onSubmit = () => {
        // Very basic front end validation - check if file extension is .csv
        const csvRegex = RegExp('^.*.(.csv)$');
        const isCsv = csvRegex.test(value[0].name);
        if (isCsv) {
            if (error) setError('');
            submitData();
        } else setError('You must select a .CSV file');
    };

    const canSubmit = Boolean(value.length);
    const showForm = batchResults === null;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography align="center" className={classes.modalTitle}>
                    {showForm ? 'Batch add users' : 'Success!'}
                </Typography>
            </Grid>
            {showForm ? (
                <>
                    <Grid item xs={12}>
                        <Typography align="center" className={classes.info}>
                            You can download our CSV template here.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.mb20}>
                        <FileInput onChange={handleChange} value={value} />
                        {error && (
                            <Typography align="right" className={classes.error}>
                                {error}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} className={classes.buttonContainer}>
                        <Button
                            label="Add"
                            className={classes.button}
                            onClick={onSubmit}
                            disabled={!canSubmit || saveState}
                            loading={saveState}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <div className={classes.resultContainer}>
                        {batchResults.newUsers.length > 0 && (
                            <Grid item xs={12}>
                                <Typography className={classes.resultTitle}>
                                    New users invited to platform/community
                                </Typography>
                                {batchResults.newUsers.map(user => (
                                    <Typography
                                        key={user._id}
                                        className={classes.user}
                                    >{`${user.firstName} ${user.lastName} (${user.email})`}</Typography>
                                ))}
                            </Grid>
                        )}
                        {batchResults.existingUsers.length > 0 && (
                            <Grid item xs={12}>
                                <Typography className={classes.resultTitle}>
                                    Existing users added to community
                                </Typography>
                                {batchResults.existingUsers.map(user => (
                                    <Typography
                                        key={user._id}
                                        className={classes.user}
                                    >{`${user.firstName} ${user.lastName} (${user.email})`}</Typography>
                                ))}
                            </Grid>
                        )}
                        {batchResults.invalidUsers.length > 0 && (
                            <Grid item xs={12}>
                                <Typography className={classes.resultTitle}>
                                    Invalid users
                                </Typography>
                                {batchResults.invalidUsers.map(user => (
                                    <Typography
                                        key={user._id}
                                        className={classes.user}
                                    >{`${user.firstName} ${user.lastName} (${user.email})`}</Typography>
                                ))}
                            </Grid>
                        )}
                    </div>
                    <Grid item xs={12} className={classes.buttonContainer}>
                        <Button
                            style={{ marginTop: 10, marginBottom: 10 }}
                            label="Upload another CSV"
                            className={classes.button}
                            onClick={clearBatchResults}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default BatchAddUserModal;
