import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as Logo } from '../../static/images/logoMark.svg';
import SetNewPasswordCard from '../../components/Auth/SetNewPasswordCard/SetNewPasswordCard';
import AuthConfirmationCard from '../../components/Auth/AuthConfirmationCard/AuthConfirmationCard';

import * as authTypes from '../../state/auth/types';
import {
    selectAuthSavingState,
    selectPasswordReset
} from '../../state/auth/reducers';

import { validateNewPassword } from '../../constants/validation';

import authStyles from './authStyles';

const styles = makeStyles({
    ...authStyles
});

export default function SetNewPassword() {
    const classes = styles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(true);
    const [values, setValues] = useState({
        password: '',
        confirm: ''
    });
    const [errors, setErrors] = useState(null);

    // Redux state
    const saving = useSelector(state => selectAuthSavingState(state));
    const passwordReset = useSelector(state => selectPasswordReset(state));

    useEffect(() => {
        // Set redux passwordReset to false on mount
        dispatch({ type: authTypes.CLEAR_RESET_PASSWORD });
    }, []);

    // Successful submit - show confirmation
    useEffect(() => {
        if (passwordReset) setShowForm(false);
        else setShowForm(true);
    }, [passwordReset]);

    // Deconstruct for readability
    const { password, confirm } = values;

    useEffect(() => {
        // If they hit this route without a code - redirect them
        // const params = queryString.parse(location.search);
        // if (params.code === undefined) history.push('/');
    });

    function handleChange(e) {
        const { name, value } = e.target;

        // If they have submitted and had errors - check on change and update accordingly
        if (errors !== null) {
            const hasError = validateNewPassword({ ...values, [name]: value });
            if (!hasError) setErrors(null);
            else setErrors(hasError);
        }

        setValues({
            ...values,
            [name]: value
        });
    }

    function handleSubmit() {
        const { code } = queryString.parse(location.search);
        // Check for errors before sending
        const errorMessages = validateNewPassword(values);
        if (errorMessages === undefined) {
            setErrors(null);
            // Everything is groovy, submit the payload
            dispatch({
                type: authTypes.SET_NEW_PASSWORD,
                password,
                passwordConfirm: confirm,
                code
            });
        } else setErrors(errorMessages);
    }

    return (
        <div style={{ backgroundColor: '#e4e4e4' }}>
            <Container className={classes.container}>
                <Grid container direction="column" justify="center" align="center">
                    <Grid item xs={12}>
                        <Logo className={classes.logo} />
                    </Grid>
                    <Grid item xs={12}>
                        {showForm ? (
                            <SetNewPasswordCard
                                password={password}
                                confirm={confirm}
                                errors={errors}
                                onChange={handleChange}
                                onSubmit={handleSubmit}
                                saving={saving}
                            />
                        ) : (
                            <AuthConfirmationCard
                                title="Success!"
                                copy="Your password has been updated. Please log in with your new credentials."
                            />
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
