import axios from 'axios';

const getV2BaseUrl = async () => {
    const isProd = 'NODE_ENV' in process.env && process.env.NODE_ENV === 'production';

    if (isProd) {
        if (!window?.__env__?.V2_BASE_URL) {
            const result = await axios.get(`${window.location.origin}/config`);
            const { data } = result;

            window.__env__ = {
                BASE_URL: data.baseUrl,
                CMS_URL: data.cmsUrl,
                PORTAL_URL: data.portalUrl,
                SET_PASSWORD_ROUTE: data.setPasswordRoute,
                V2_BASE_URL: data.v2BaseUrl,
            };
        }

        return window.__env__.V2_BASE_URL;
    }

    return process.env.REACT_APP_V2_BASE_URL;
    
};

export default getV2BaseUrl;