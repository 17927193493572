import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './PaginationActions';

import TableLoading from './TableLoading';

const styles = makeStyles({
    paper: {
        borderRadius: 10,
        padding: '18px 15px'
    },
    tableCell: {
        padding: 0,
        border: 0,
        marginBottom: 10
    },
    memberCard: {
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        padding: 20,
        minHeight: 80
    },
    noBorder: {
        border: 0
    },
    noData: {
        fontFamily: 'SFUIDisplay-Bold',
        fontWeight: 700,
        fontSize: 24
    }
});

export default function StyledTable({
    data,
    loading,
    menuActions,
    noDataMessage,
    rowCard: RowCard,
    page,
    onPageChange,
    count,
    onRowClick,
    serverSidePagination
}) {
    const classes = styles();

    const handleChangePage = (event, newPage) => {
        onPageChange(newPage);
    };

    let paginatedTableData = [];
    if (serverSidePagination) {
        paginatedTableData = data;
    } else paginatedTableData = data ? data.slice(page * 10, page * 10 + 10) : null;
    const hasData =
        paginatedTableData !== null ? paginatedTableData.length > 0 : false;
    const emptyRows = Math.min(10 - (hasData ? paginatedTableData.length : 0));

    return (
        <Paper className={classes.paper} elevation={4}>
            <Table className={classes.table} aria-label="simple table">
                <TableBody>
                    {loading ? (
                        <TableLoading />
                    ) : (
                        <>
                            {paginatedTableData &&
                                paginatedTableData.map(row => (
                                    <TableRow key={row._id}>
                                        <TableCell
                                            scope="row"
                                            className={classes.tableCell}
                                        >
                                            {RowCard && (
                                                <RowCard
                                                    {...row}
                                                    menuActions={
                                                        menuActions &&
                                                        menuActions(row)
                                                    }
                                                    onClick={() => onRowClick(row)}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {emptyRows > 0 && hasData && (
                                <TableRow style={{ height: 63 * emptyRows }}>
                                    <TableCell
                                        colSpan={6}
                                        className={classes.noBorder}
                                    />
                                </TableRow>
                            )}
                            {!hasData && (
                                <TableRow>
                                    <TableCell className={classes.noBorder}>
                                        <Box
                                            height={50 * emptyRows}
                                            alignItems="center"
                                            justifyContent="center"
                                            display="flex"
                                            padding={2}
                                        >
                                            <Typography
                                                align="center"
                                                className={classes.noData}
                                            >
                                                {noDataMessage}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                            {hasData && (
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10]}
                                        colSpan={3}
                                        count={count || data.length}
                                        rowsPerPage={10}
                                        page={page}
                                        classes={{
                                            root: classes.noBorder
                                        }}
                                        onChangePage={handleChangePage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
        </Paper>
    );
}

StyledTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowCard: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    menuActions: PropTypes.func,
    noDataMessage: PropTypes.string,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    count: PropTypes.number,
    onRowClick: PropTypes.func,
    serverSidePagination: PropTypes.bool
};

StyledTable.defaultProps = {
    loading: false,
    menuActions: null,
    noDataMessage: 'No data to display',
    count: null,
    onRowClick: null,
    serverSidePagination: false
};
