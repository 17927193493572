import axios from 'axios';
import getV2BaseUrl from '../../utils/getV2BaseUrl';

let API_V2_BASE_URL;

export const getAllToolkits = async (page, search) => {
    try {
        const result = await axios.get('/v1/toolkits', {
            params: {
                limit: 10,
                skip: page * 10,
                search
            }
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getTeamToolkits = async (communityUuid, teamId) => {
    try {
        const result = await axios.get(`/v1/communities/${communityUuid}/teams/${teamId}/toolkits`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const addToolkitToTeam = async (communityId, teamId, toolkitId) => {
    try {
        const result = await axios.post(`/v1/communities/${communityId}/teams/${teamId}/toolkits/${toolkitId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
}

export const remoteToolkitFromTeam = async (communityId, teamId, toolkitId) => {
    try {
        const result = await axios.delete(`/v1/communities/${communityId}/teams/${teamId}/toolkits/${toolkitId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
}

export const createToolkit = async (toolkit) => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.post(`${API_V2_BASE_URL}/v1/toolkits`, toolkit, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const editToolkit = async (toolkitId, toolkit) => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.put(`${API_V2_BASE_URL}/v1/toolkits/${toolkitId}`, toolkit, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getToolkits = async () => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.get(`${API_V2_BASE_URL}/v1/toolkits`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getUserToolkits = async (userId) => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.get(`${API_V2_BASE_URL}/v1/toolkits/user/${userId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getToolkit = async (toolkitId) => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.get(`${API_V2_BASE_URL}/v1/toolkits/${toolkitId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const assignToolkits = async (toolkits, users) => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.put(`${API_V2_BASE_URL}/v1/toolkits/assign`, {toolkits, users});
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeToolkitFromUser = async (toolkitId, userId) => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.put(`${API_V2_BASE_URL}/v1/toolkits/${toolkitId}/remove/user/${userId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const deleteToolkit = async (toolkitId) => {
    try {
        API_V2_BASE_URL = await getV2BaseUrl();
        const result = await axios.delete(`${API_V2_BASE_URL}/v1/toolkits/${toolkitId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};
