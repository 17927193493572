import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '../../components/Button/Button';
import TextInput from '../../components/TextInput/TextInput';
import Autocomplete from '../../components/Autocomplete/Autocomplete';

import {
    selectUsersApiErrors,
    selectUsersSavingState,
    selectUsers
} from '../../state/users/reducers';
import { selectCommunities } from '../../state/communities/reducers';
import * as modalTypes from '../../state/modal/types';
import * as userTypes from '../../state/users/types';
import * as communityTypes from '../../state/communities/types';

import {
    validateCreateUser,
    getErrorMessage,
    parseApiErrors
} from '../../constants/validation';
import { usePrevious } from '../../utils/customHooks';

const useStyles = makeStyles({
    modalTitle: {
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 24,
        marginBottom: 30
    },
    mb5: {
        marginBottom: 5
    },
    mb20: {
        marginBottom: 20
    },
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: 125
    }
});

const AddUserModal = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        closeModal,
        createUser,
        apiErrors,
        saveState,
        inviteUser,
        allUsers
    } = props;
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: ''
    });
    const [community, setCommunity] = useState(null);
    const [errors, setErrors] = useState(null);

    // Redux
    const communities = useSelector(state => selectCommunities(state));

    const mappedSuggestions = communities.map(c => ({
        key: c._id,
        label: c.title
    }));

    // A bit of magic required to close modal when appropriate
    const prevSaving = usePrevious(saveState);
    useEffect(() => {
        if (prevSaving && !saveState && !apiErrors) {
            closeModal();
        }
    }, [prevSaving, saveState, apiErrors]);

    // Deconstruct for readability
    const { firstName, lastName, email } = values;

    function handleChange(e) {
        const { name, value } = e.target;

        // If they have submitted and had errors - check on change and update accordingly
        if (errors !== null) {
            const hasError = validateCreateUser({ ...values, [name]: value });
            if (!hasError) setErrors(null);
            else setErrors(hasError);
        }

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSubmit = () => {
        // Check for errors before submitting
        const emailExists = allUsers.some(user => user.email === email);

        const errorMessages = validateCreateUser(values);

        if (errorMessages === undefined && !emailExists) {
            setErrors(null);
            // Everythings alright - submit payload
            if (community !== null) {
                // Dispatch add/invite to community
                inviteUser(community, { ...values, permissions: [], leaders: [] });
            } else {
                // Dispatch create user without community
                createUser({ firstName, lastName, email });
            }
        } else {
            let newErrors;

            if (errorMessages) {
                newErrors = {
                    ...errorMessages
                };
            }

            if (emailExists) {
                newErrors = {
                    ...newErrors,
                    email: ['Email already exists in the platform']
                };
            }

            setErrors(newErrors);
        }
    };

    const onSearchChange = search => {
        dispatch({
            type: communityTypes.GET_COMMUNITIES,
            page: 0,
            search
        });
    };

    const handleSelect = id => {
        setCommunity(id);
    };

    const computedErrors =
        errors ||
        parseApiErrors(apiErrors, userTypes.CREATE_USER_ERROR) ||
        parseApiErrors(apiErrors, userTypes.INVITE_USER_TO_COMMUNITY_ERROR);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography align="center" className={classes.modalTitle}>
                    Add a user
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.mb20}>
                <TextInput
                    name="firstName"
                    label="First name"
                    value={firstName}
                    onChange={handleChange}
                    error={Boolean(getErrorMessage(computedErrors, 'firstName'))}
                    errorMessage={getErrorMessage(computedErrors, 'firstName')}
                />
            </Grid>
            <Grid item xs={12} className={classes.mb20}>
                <TextInput
                    name="lastName"
                    label="Last name"
                    value={lastName}
                    onChange={handleChange}
                    error={Boolean(getErrorMessage(computedErrors, 'lastName'))}
                    errorMessage={getErrorMessage(computedErrors, 'lastName')}
                />
            </Grid>
            <Grid item xs={12} className={classes.mb5}>
                <TextInput
                    name="email"
                    label="Email address"
                    value={email}
                    onChange={handleChange}
                    error={Boolean(getErrorMessage(computedErrors, 'email'))}
                    errorMessage={getErrorMessage(computedErrors, 'email')}
                />
            </Grid>
            <Grid item xs={12} className={classes.mb20}>
                <Autocomplete
                    placeholder="Search/select a community (optional)"
                    suggestions={mappedSuggestions}
                    onChangeText={onSearchChange}
                    onSelected={handleSelect}
                />
            </Grid>
            <Grid item xs={12} className={classes.flex}>
                <Button
                    label="Add"
                    onClick={handleSubmit}
                    className={classes.button}
                    disabled={saveState}
                    loading={saveState}
                />
            </Grid>
        </Grid>
    );
};

AddUserModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    saveState: PropTypes.bool.isRequired,
    apiErrors: PropTypes.array,
    allUsers: PropTypes.array.isRequired,
    inviteUser: PropTypes.func.isRequired
};

AddUserModal.defaultProps = {
    apiErrors: null
};

const mapStateToProps = state => ({
    apiErrors: selectUsersApiErrors(state),
    saveState: selectUsersSavingState(state),
    allUsers: selectUsers(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            }),
            createUser: payload => ({ type: userTypes.CREATE_USER, payload }),
            inviteUser: (communityId, payload) => ({
                type: userTypes.INVITE_USER_TO_COMMUNITY,
                communityId,
                payload
            })
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
