// Icons
import { ReactComponent as MembersIcon } from '../static/images/icons/icon-members.svg';
import { ReactComponent as CommunityListIcon } from '../static/images/icons/icon-community.svg';
import { ReactComponent as ToolkitIcon } from '../static/images/icons/icon-toolkit.svg';

// Auth
import Login from '../containers/Auth/Login';
import ResetPassword from '../containers/Auth/ResetPassword';
import SetNewPassword from '../containers/Auth/SetNewPassword';

// Communities
import Communities from '../containers/Communities/CommunityList';
import CommunityDetails from '../containers/Communities/CommunityDetails';

// Users
import Users from '../containers/Users/UserList';

// Toolkits
import ToolkitList from '../containers/Toolkits/ToolkitList';
// import ToolkitDetail from '../containers/Toolkits/ToolkitDetail';
import CreateToolkit from '../components/CreateToolkit/CreateToolkit';
import EditToolkit from '../components/EditToolkit/EditToolkit';

export default [
    {
        key: 'login',
        path: '/',
        title: 'Login',
        icon: null,
        component: Login,
        authenticated: false,
        navMenu: false,
        roles: [],
        permissions: []
    },
    {
        key: 'reset-password',
        path: '/reset-password',
        title: 'Reset Password',
        icon: null,
        component: ResetPassword,
        authenticated: false,
        navMenu: false,
        roles: [],
        permissions: []
    },
    {
        key: 'set-password',
        path: '/set-password',
        title: 'Set Password',
        icon: null,
        component: SetNewPassword,
        authenticated: false,
        navMenu: false,
        roles: [],
        permissions: []
    },
    {
        key: 'communityDetail',
        path: '/communities/:uuid',
        title: 'Community Details',
        component: CommunityDetails,
        authenticated: true,
        navMenu: false,
        roles: [],
        permissions: [],
        parent: '/communities'
    },
    {
        key: 'communities',
        path: '/communities',
        title: 'Communities',
        icon: CommunityListIcon,
        component: Communities,
        authenticated: true,
        navMenu: true,
        roles: [],
        permissions: [],
    },
    {
        key: 'members',
        path: '/members',
        title: 'Users',
        icon: MembersIcon,
        component: Users,
        authenticated: true,
        navMenu: true,
        roles: [],
        permissions: []
    },
    // {
    //     key: 'toolkitDetail',
    //     path: '/toolkits/:toolkitUuid',
    //     parent: '/toolkits',
    //     title: 'Tool kit',
    //     icon: MembersIcon,
    //     component: ToolkitDetail,
    //     authenticated: true,
    //     navMenu: false,
    //     roles: [],
    //     permissions: []
    // },
    {
        key: 'toolkits-add',
        path: '/toolkits/add',
        icon: null,
        component: CreateToolkit,
        authenticated: true,
        navMenu: false,
        roles: [],
        permissions: []
    },
    {
        key: 'toolkits-edit',
        path: '/toolkits/:toolkitId/edit',
        icon: null,
        component: EditToolkit,
        authenticated: true,
        navMenu: false,
        roles: [],
        permissions: []
    },
    {
        key: 'toolkits',
        path: '/toolkits',
        title: 'Toolkit',
        icon: ToolkitIcon,
        component: ToolkitList,
        authenticated: true,
        navMenu: true,
        roles: [],
        permissions: []
    },
];
