import { takeLatest, call, put, select } from 'redux-saga/effects';
import {push} from 'connected-react-router';

import * as actions from './actions';
import * as types from './types';

import * as snackTypes from '../snack/types';
import * as modalTypes from '../modal/types';
import {selectUserToolkits} from './reducers';


function* getAllToolkits(action) {
    try {
        const { data } = yield actions.getAllToolkits();
        // TODO: Pagination
        yield put({ type: types.GET_ALL_TOOLKITS_SUCCESS, payload: data.results })
    } catch (error) {
        yield put({ type: types.GET_ALL_TOOLKITS_ERROR, error })
    }
}


function* getTeamToolkits(action) {
    const { communityId, teamId } = action.payload
    try {
        const { data } = yield actions.getTeamToolkits(communityId, teamId);
        const payload = { toolkits: data, teamId }
        yield put({ type: types.GET_TEAM_TOOLKITS_SUCCESS, payload })
    } catch (error) {
        console.log('ERROR', error)
        yield put({ type: types.GET_TEAM_TOOLKITS_ERROR, error })
    }
}

function* addToolkitToTeam(action) {
    const { communityId, teamId, toolkitId } = action.payload
    try {
        const { data } = yield actions.addToolkitToTeam(communityId, teamId, toolkitId)
        const payload = { toolkits: data, communityId, teamId, toolkitId }
        yield put({ type: types.ADD_TOOLKIT_TO_TEAM_SUCCESS, payload })
    } catch (error) {
        yield put({ type: types.ADD_TOOLKIT_TO_TEAM_ERROR, error, communityId, teamId, toolkitId })
    }
}

function* removeToolkitFromTeam(action) {
    const { communityId, teamId, toolkitId } = action.payload
    try {
        const { data } = yield actions.remoteToolkitFromTeam(communityId, teamId, toolkitId)
        const payload = { toolkits: data, communityId, teamId, toolkitId }
        yield put({ type: types.REMOVE_TOOLKIT_FROM_TEAM_SUCCESS, payload })
    } catch (error) {
        console.log(error)
        const payload = { communityId, teamId, toolkitId }
        yield put({ type: types.REMOVE_TOOLKIT_FROM_TEAM_ERROR, error, payload })
    }
}

function* createToolkit({ payload, communityId }) {
    try {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: true });

        const toolkit = yield call(actions.createToolkit, payload);

        yield put({ type: types.CREATE_TOOLKIT_SUCCESS,toolkit });

        yield put({ type: types.GET_TOOLKITS});

        // Display success snack
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Toolkit successfully created.',
            open: true,
            props: { variant: 'success' }
        });

        // yield put(
        //     push(
        //         '/toolkits'
        //     )
        // );
    } catch (error) {
        // Display the error message modal
        yield put({
            type: modalTypes.ERROR_MODAL,
            error
        });
        yield put({
            type: types.CREATE_TOOLKIT_ERROR,
            errors: {
                key: types.CREATE_TOOLKIT_ERROR,
                message: error.response.data.message,
                errors: error.response.data.errors
            }
        });
    } finally {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: false });
    }
}

function* getToolkits({ showProgress = false }) {
    try {
        yield put({ type: types.TOOLKIT_LOADING_STATE, state: true });

        const results = yield call(actions.getToolkits);

        yield put({ type: types.GET_TOOLKITS_SUCCESS, toolkits: results });
    } catch (err) {
        yield put({
            type: modalTypes.ERROR_MODAL,
            err
        });
        yield put({
            type: types.GET_TOOLKITS_ERROR,
            errors: {
                key: types.GET_TOOLKITS_ERROR,
                message: err.response.data.message,
                errors: err.response.data.errors
            }
        });
    } finally {
        yield put({ type: types.TOOLKIT_LOADING_STATE, state: false });
    }
}

function* getUserToolkits({ showProgress = false, userId }) {
    try {
        if (showProgress) {
            yield put({ type: types.TOOLKIT_LOADING_STATE, state: true });
        }

        const results = yield call(actions.getUserToolkits, userId);

        yield put({ type: types.GET_USER_TOOLKITS_SUCCESS, userToolkits: results });
    } catch (err) {
        yield put({
            type: modalTypes.ERROR_MODAL,
            err
        });
        yield put({
            type: types.GET_USER_TOOLKITS_ERROR,
            errors: {
                key: types.GET_USER_TOOLKITS_ERROR,
                message: err.response.data.message,
                errors: err.response.data.errors
            }
        });
    } finally {
        yield put({ type: types.TOOLKIT_LOADING_STATE, state: false });
    }
}

function* assignToolkits({ toolkits, users, showSnack = true}) {
    try {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: true });

        const result = yield call(actions.assignToolkits, toolkits, users);

        yield put({ type: types.ASSIGN_TOOLKITS_SUCCESS });

        if (showSnack) {
            yield put({
                type: snackTypes.SET_SNACK,
                content: 'Toolkits successfully assigned to users.',
                open: true,
                props: { variant: 'success' }
            });
        }
    } catch (err) {
        yield put({
            type: modalTypes.ERROR_MODAL,
            err
        });
        yield put({
            type: types.ASSIGN_TOOLKITS_ERROR,
            errors: {
                key: types.ASSIGN_TOOLKITS_ERROR,
                message: err.response.data.message,
                errors: err.response.data.errors
            }
        });
    } finally {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: false });
    }
}

function* removeUserFromToolkit({ toolkitId, userId}) {
    try {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: true });

        const updatedToolkit = yield call(actions.removeToolkitFromUser, toolkitId, userId);

        const userToolkits = yield select(selectUserToolkits);

        yield put({
            type: types.REMOVE_TOOLKIT_FROM_USER_SUCCESS,
            userToolkits: userToolkits.filter(ut => ut.id !== updatedToolkit.id)
        });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User successfully removed from toolkit.',
            open: true,
            props: { variant: 'success' }
        });
    } catch (err) {
        yield put({
            type: modalTypes.ERROR_MODAL,
            err
        });
        yield put({
            type: types.REMOVE_TOOLKIT_FROM_USER_ERROR,
            errors: {
                key: types.REMOVE_TOOLKIT_FROM_USER_ERROR,
                message: err.response.data.message,
                errors: err.response.data.errors
            }
        });
    } finally {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: false });
    }
}

function* getToolkit({ showProgress = false, toolkitId }) {
    try {
        yield put({ type: types.TOOLKIT_LOADING_STATE, state: true });

        const result = yield call(actions.getToolkit, toolkitId);

        yield put({ type: types.GET_TOOLKIT_SUCCESS, toolkit: result });
    } catch (err) {
        yield put({
            type: modalTypes.ERROR_MODAL,
            err
        });
        yield put({
            type: types.GET_TOOLKIT_ERROR,
            errors: {
                key: types.GET_TOOLKIT_ERROR,
                message: err.response.data.message,
                errors: err.response.data.errors
            }
        });
    } finally {
        yield put({ type: types.TOOLKIT_LOADING_STATE, state: false });
    }
}

function* editToolkit({ payload, communityId, toolkitId }) {
    try {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: true });

        const toolkit = yield call(actions.editToolkit, toolkitId, payload);

        yield put({ type: types.EDIT_TOOLKIT_SUCCESS });

        yield put({ type: types.GET_TOOLKITS});

        // Display success snack
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Toolkit successfully updated.',
            open: true,
            props: { variant: 'success' }
        });

        yield put(
            push(
                '/toolkits'
            )
        );
    } catch (error) {
        yield put({
            type: modalTypes.ERROR_MODAL,
            error
        });

        yield put({
            type: types.CREATE_TOOLKIT_ERROR,
            errors: {
                key: types.CREATE_TOOLKIT_ERROR,
                message: error.response.data.message,
                errors: error.response.data.errors
            }
        });
    } finally {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: false });
    }
}

function* deleteToolkit({ toolkitId, communityId }) {
    try {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: true });

        yield call(actions.deleteToolkit, toolkitId);

        yield put({ type: types.DELETE_TOOLKIT_SUCCESS });

        yield put({ type: types.GET_TOOLKITS});

        // Display success snack
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Toolkit successfully deleted.',
            open: true,
            props: { variant: 'success' }
        });

        // yield put(
        //     push(
        //         `/c/${communityId}/toolkits`
        //     )
        // );
    } catch (error) {
        yield put({
            type: modalTypes.ERROR_MODAL,
            error
        });

        yield put({
            type: types.EDIT_TOOLKIT_ERROR,
            errors: {
                key: types.EDIT_TOOLKIT_ERROR,
                message: error.response.data.message,
                errors: error.response.data.errors
            }
        });
    } finally {
        yield put({ type: types.TOOLKIT_SAVING_STATE, state: false });
    }
}

export default [
    takeLatest(types.GET_ALL_TOOLKITS, getAllToolkits),
    takeLatest(types.GET_TEAM_TOOLKITS, getTeamToolkits),
    takeLatest(types.ADD_TOOLKIT_TO_TEAM, addToolkitToTeam),
    takeLatest(types.REMOVE_TOOLKIT_FROM_TEAM, removeToolkitFromTeam),
    takeLatest(types.CREATE_TOOLKIT, createToolkit),
    takeLatest(types.GET_TOOLKITS, getToolkits),
    takeLatest(types.GET_USER_TOOLKITS, getUserToolkits),
    takeLatest(types.ASSIGN_TOOLKITS, assignToolkits),
    takeLatest(types.REMOVE_TOOLKIT_FROM_USER, removeUserFromToolkit),
    takeLatest(types.GET_TOOLKIT, getToolkit),
    takeLatest(types.EDIT_TOOLKIT, editToolkit),
    takeLatest(types.DELETE_TOOLKIT, deleteToolkit),
];
