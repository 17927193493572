import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    inputRoot: {
        fontFamily: 'SFUIText-Reg',
        backgroundColor: '#f4f4f4',
        color: '#223f63',
        borderRadius: 10,
        paddingLeft: 21,
        paddingRight: 21,
        fontSize: 12,
        minHeight: 40
    },
    labelRoot: {
        fontFamily: 'SFUIText-Light',
        color: '#838b96',
        zIndex: 1,
        fontSize: 10,
        fontWeight: 500,
        paddingTop: 7,
        paddingLeft: 21,
        pointerEvents: 'none'
    },
    labelFocused: {
        color: '#838b96 !important'
    },
    labelShrink: {
        fontFamily: 'SFUIText-SemiBold',
        fontSize: 12,
        paddingTop: 0,
        paddingLeft: 15
    },
    mr5: {
        marginRight: 5
    },
    helperError: {
        textAlign: 'end',
        paddingRight: 7,
        fontFamily: 'SFUIText-Light',
        fontSize: 10
    }
});

const LabAutocomplete = props => {
    const classes = useStyles();
    const {
        placeholder,
        suggestions,
        onChangeText,
        onSelected,
        loading,
        error,
        errorMessage
    } = props;

    function handleChange(e) {
        const { value } = e.target;
        onChangeText(value);
    }

    function handleSelect(e, value) {
        if (value) {
            const { key } = value;
            onSelected(key);
        } else onSelected(null);
    }

    return (
        <div style={{ width: '100%' }}>
            <Autocomplete
                id="boss-autocomplete"
                options={suggestions}
                getOptionLabel={option => option.label}
                loading={loading}
                onChange={handleSelect}
                classes={{
                    endAdornment: classes.mr5
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={placeholder}
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        error={error}
                        helperText={errorMessage}
                        FormHelperTextProps={{
                            classes: {
                                root: classes.helperError
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused,
                                shrink: classes.labelShrink
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot
                            },
                            disableUnderline: true
                        }}
                    />
                )}
            />
        </div>
    );
};

LabAutocomplete.propTypes = {
    placeholder: PropTypes.string,
    suggestions: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ),
    onChangeText: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
};

LabAutocomplete.defaultProps = {
    placeholder: 'Type to search',
    suggestions: [],
    loading: false,
    error: false,
    errorMessage: ''
};

export default LabAutocomplete;
