// Check user has permissions for given community - return object
export function checkAndGetPermissions(memberships, communityId) {
    const membership = memberships.find(m =>
        Object.keys(m).find(key => key === communityId)
    );
    return {
        isLeader: membership
            ? membership[communityId].permissions.includes('leader')
            : false,
        isComAdmin: membership
            ? membership[communityId].permissions.includes('admin')
            : false
    };
}

export function getUserCommunityLeaders(user, communityId) {
    const { communityLeaders } = user;
    if (communityLeaders) {
        const hasLeaders = Object.keys(communityLeaders).some(
            key => key === communityId
        );
        if (hasLeaders) return communityLeaders[communityId];
        return [];
    }
    return [];
}

export const sortByArchived = array => {
    return array.sort((a, b) => a.isDeleted - b.isDeleted);
};

export const sortByCreated = array =>
    array.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

export const upsertArrayByKey = (key, arr1, arr2) => arr1
    // Remove object if key is in the keys of object 1
    .filter(i => !arr2.map(o => o[key]).includes(i[key]))
    .concat(...arr2)
    .sort((a,b) => a[key] - b[key])

export default checkAndGetPermissions;

