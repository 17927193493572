import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '../../components/Button/Button';
import TextInput from '../../components/TextInput/TextInput';

import {
    selectCommunityApiErrors,
    selectCommunitySavingState,
    selectSelectedCommunityId,
    selectCommunityUsers
} from '../../state/communities/reducers';
import * as modalTypes from '../../state/modal/types';
import * as communityTypes from '../../state/communities/types';

import {
    validateCreateUser,
    getErrorMessage,
    parseApiErrors
} from '../../constants/validation';
import { usePrevious } from '../../utils/customHooks';

const useStyles = makeStyles({
    modalTitle: {
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 24,
        marginBottom: 5
    },
    text: {
        fontFamily: 'SFUIDisplay-Light',
        fontSize: 14,
        marginBottom: 20
    },
    mb30: {
        marginBottom: 30
    },
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: 125
    }
});

const AddUserToCommunityModal = props => {
    const classes = useStyles();
    const {
        closeModal,
        inviteUser,
        apiErrors,
        saveState,
        communityId,
        communityUsers
    } = props;
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        permissions: [],
        leaders: []
    });
    const [errors, setErrors] = useState(null);

    // A bit of magic required to close modal when appropriate
    const prevSaving = usePrevious(saveState);
    useEffect(() => {
        if (prevSaving && !saveState && !apiErrors) {
            closeModal();
        }
    }, [prevSaving, saveState, apiErrors]);

    // Deconstruct for readability
    const { firstName, lastName, email } = values;

    function handleChange(e) {
        const { name, value } = e.target;

        // If they have submitted and had errors - check on change and update accordingly
        if (errors !== null) {
            const hasError = validateCreateUser({ ...values, [name]: value });
            if (!hasError) setErrors(null);
            else setErrors(hasError);
        }

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSubmit = () => {
        // Check for errors before submitting
        const emailExists = communityUsers.some(user => user.email === email);

        const errorMessages = validateCreateUser(values);
        if (errorMessages === undefined && !emailExists) {
            setErrors(null);
            // Everythings alright - submit payload
            inviteUser(communityId, values);
        } else
            setErrors({
                ...errorMessages,
                email: emailExists ? ['Email already exists in community'] : []
            });
    };

    const computedErrors =
        errors || parseApiErrors(apiErrors, communityTypes.INVITE_USER_ERROR);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography align="center" className={classes.modalTitle}>
                    Add/Invite a user
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" className={classes.text}>
                    If this user already exists, we will add them to the community -
                    otherwise they will get an invitation to the platform!
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.mb30}>
                <TextInput
                    name="firstName"
                    label="First name"
                    value={firstName}
                    onChange={handleChange}
                    error={Boolean(getErrorMessage(computedErrors, 'firstName'))}
                    errorMessage={getErrorMessage(computedErrors, 'firstName')}
                />
            </Grid>
            <Grid item xs={12} className={classes.mb30}>
                <TextInput
                    name="lastName"
                    label="Last name"
                    value={lastName}
                    onChange={handleChange}
                    error={Boolean(getErrorMessage(computedErrors, 'lastName'))}
                    errorMessage={getErrorMessage(computedErrors, 'lastName')}
                />
            </Grid>
            <Grid item xs={12} className={classes.mb30}>
                <TextInput
                    name="email"
                    label="Email address"
                    value={email}
                    onChange={handleChange}
                    error={Boolean(getErrorMessage(computedErrors, 'email'))}
                    errorMessage={getErrorMessage(computedErrors, 'email')}
                />
            </Grid>
            <Grid item xs={12} className={classes.flex}>
                <Button
                    label="Add"
                    onClick={handleSubmit}
                    className={classes.button}
                    disabled={saveState}
                    loading={saveState}
                />
            </Grid>
        </Grid>
    );
};

AddUserToCommunityModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    inviteUser: PropTypes.func.isRequired,
    saveState: PropTypes.bool.isRequired,
    apiErrors: PropTypes.array,
    communityId: PropTypes.string.isRequired,
    communityUsers: PropTypes.array.isRequired
};

AddUserToCommunityModal.defaultProps = {
    apiErrors: null
};

const mapStateToProps = state => ({
    apiErrors: selectCommunityApiErrors(state),
    saveState: selectCommunitySavingState(state),
    communityId: selectSelectedCommunityId(state),
    communityUsers: selectCommunityUsers(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            }),
            inviteUser: (communityId, payload) => ({
                type: communityTypes.INVITE_USER,
                communityId,
                payload
            })
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToCommunityModal);
