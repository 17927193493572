import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../../utils/history';

import * as authServices from './services';
import * as authActions from './actions';
import * as authTypes from './types';
import * as userActions from '../users/actions';

function* handleLogin(payload) {
    try {
        yield put({ type: authTypes.AUTH_LOADING_STATE, state: true });

        const response = yield call(authActions.attemptLogin, payload);
        const { data } = response;

        if (payload.remember) {
            authServices.setLocalStore('email', payload.email);
        } else authServices.removeLocalStore('email');

        // Login success - set local storage
        yield call(authServices.setAuthToken, data);

        const tokenData = yield call(authServices.getDataFromToken, data);
        const { user_id } = tokenData;

        // Get the user object and check permissions
        const userResponse = yield call(userActions.getUser, user_id);
        const isAdmin = userResponse.data.roles.includes('admin');

        if (isAdmin) {
            yield put({ type: authTypes.AUTH_LOGIN_SUCCESS, userId: user_id });
        } else {
            yield put({
                type: authTypes.AUTH_LOGIN_ERROR,
                errors: {
                    key: authTypes.AUTH_LOGIN_ERROR,
                    errors: null,
                    message:
                        'You do not have the correct permissions to access this area. If you think this is in error, please contact an admin.'
                }
            });
        }

        yield put({ type: authTypes.AUTH_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: authTypes.AUTH_LOADING_STATE, state: false });

        yield put({
            type: authTypes.AUTH_LOGIN_ERROR,
            errors: {
                key: authTypes.AUTH_LOGIN_ERROR,
                errors: error.response.data.errors,
                message: error.response.data.message
            }
        });
    }
}

function* handleLogout() {
    yield call(authServices.clearAuthToken);
    yield put({ type: authTypes.AUTH_LOGOUT_SUCCESS });
    yield history.replace('/');
}

function* resetPassword({ email }) {
    try {
        yield put({ type: authTypes.AUTH_LOADING_STATE, state: true });

        yield call(authActions.requestPasswordReset, email);

        yield put({ type: authTypes.AUTH_LOADING_STATE, state: false });
        yield put({ type: authTypes.RESET_PASSWORD_SUCCESS, email });
    } catch (error) {
        yield put({
            type: authTypes.RESET_PASSWORD_ERROR,
            errors: {
                key: authTypes.RESET_PASSWORD_ERROR,
                errors: error.response.data.errors,
                message: error.response.data.message
            }
        });
        yield put({ type: authTypes.AUTH_LOADING_STATE, state: false });
    }
}

function* setNewPassword({ password, passwordConfirm, code }) {
    try {
        yield put({ type: authTypes.AUTH_SAVING_STATE, state: true });

        yield call(authActions.resetPassword, password, passwordConfirm, code);

        yield put({ type: authTypes.SET_NEW_PASSWORD_SUCCESS });
        yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });
    } catch (error) {
        // Display the error message modal
        yield put({
            type: authTypes.SET_NEW_PASSWORD_ERROR,
            errors: {
                key: authTypes.SET_NEW_PASSWORD_ERROR,
                errors: error.response.data.errors,
                message: error.response.data.message
            }
        });
        yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });
    }
}

export default [
    takeLatest(authTypes.AUTH_LOGIN, handleLogin),
    takeLatest(authTypes.AUTH_LOGOUT, handleLogout),
    takeLatest(authTypes.RESET_PASSWORD, resetPassword),
    takeLatest(authTypes.SET_NEW_PASSWORD, setNewPassword)
];
