import React from 'react';

import AddCommunityModal from '@/containers/Modals/AddCommunityModal';
import EditCommunityModal from '@/containers/Modals/EditCommunityModal';
import AssignLeaderModal from '@/containers/Modals/AssignLeaderModal';
import AddUserModal from '@/containers/Modals/AddUserModal';
import BatchAddUserModal from '@/containers/Modals/BatchAddUserModal';
import PromptBooleanModal from '@/containers/Modals/PromptBooleanModal';
import * as modalTypes from '../../constants/modals';
import AddUserToCommunityModal from '../../containers/Modals/AddUserToCommunityModal';
import ToolkitCategoriesModal from '../../containers/Modals/ToolkitCategoriesModal';
import PreviewToolkitModal from '../../containers/Modals/PreviewToolkitModal';
import ApiErrorModal from '../../containers/Modals/ApiErrorModal';

export const getModalFromType = (type) => {
    switch (type) {
        case modalTypes.ADD_COMMUNITY_MODAL:
            return <AddCommunityModal />
        case modalTypes.EDIT_COMMUNITY_MODAL:
            return <EditCommunityModal />
        case modalTypes.ASSIGN_LEADER_MODAL:
            return <AssignLeaderModal />
        case modalTypes.ADD_USER_MODAL:
            return <AddUserModal />
        case modalTypes.BATCH_ADD_USER_MODAL:
            return <BatchAddUserModal />
        case modalTypes.PROMPT_BOOLEAN_MODAL:
            return <PromptBooleanModal />
        case modalTypes.ADD_USER_TO_COMMUNITY_MODAL:
            return <AddUserToCommunityModal />
        case modalTypes.API_ERROR_MODAL:
            return <ApiErrorModal />;
        case modalTypes.TOOLKIT_CATEGORIES_MODAL:
            return <ToolkitCategoriesModal />;
        case modalTypes.PREVIEW_TOOLKIT_MODAL:
            return <PreviewToolkitModal />;
        default:
            return <div />
    }
}

export const ADD_COMMUNITY_MODAL = 'modal/ADD_COMMUNITY_MODAL';
export const EDIT_COMMUNITY_MODAL = 'modal/EDIT_COMMUNITY_MODAL';
export const ASSIGN_LEADER_MODAL = 'modal/ASSIGN_LEADER_MODAL';
export const ADD_USER_MODAL = 'modal/ADD_USER_MODAL';
export const BATCH_ADD_USER_MODAL = 'modal/BATCH_ADD_USER_MODAL';


// Generic modals - safe to reuse
export const PROMPT_BOOLEAN_MODAL = 'modal/PROMPT_BOOLEAN';
