import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Logo } from '../../static/images/logoMark.svg';
import LoginCard from '../../components/Auth/LoginCard/LoginCard';

import {
    validateLogin,
    getErrorMessage,
    parseApiErrors
} from '../../constants/validation';

import {
    selectAuthLoadingState,
    selectLoggedInUser,
    selectAuthApiErrors
} from '../../state/auth/reducers';
import * as authTypes from '../../state/auth/types';
import { getLocalStore } from '../../state/auth/services';

import authStyles from './authStyles';

const styles = makeStyles({
    ...authStyles
});

export default function Login() {
    const classes = styles();
    const dispatch = useDispatch();
    const history = useHistory();

    // The state (hook style)
    const [values, setValues] = useState({
        email: '',
        password: '',
        remember: false
    });
    const [errors, setErrors] = useState(null);

    // Redux state
    const authLoading = useSelector(state => selectAuthLoadingState(state));
    const loggedInUser = useSelector(state => selectLoggedInUser(state));
    const apiErrors = useSelector(state => selectAuthApiErrors(state));
    // const serverError = useSelector(state => state.auth.loginError);

    // Component did update
    useEffect(() => {
        if (loggedInUser !== '') {
            history.push('/communities');
        }
    }, [loggedInUser, history]);

    // Populate email if set in localstorage
    useEffect(() => {
        const savedEmail = getLocalStore('email');
        if (savedEmail) setValues({ ...values, email: savedEmail, remember: true });
    }, []);

    // Handles the change of email/password
    function handleTextChange(e) {
        const { name, value } = e.target;

        // If errors exist and the user updates to valid email - remove the error
        if (errors !== null && name === 'email') {
            const hasError = validateLogin(values);
            if (!hasError) setErrors(null);
        }

        setValues({
            ...values,
            [name]: value
        });
    }

    // Handles toggling of the checkbox
    function handleCheck(e) {
        const { checked } = e.target;
        setValues({
            ...values,
            remember: checked
        });
    }

    // Submit the request
    function handleSubmit() {
        // Check for errors before sending
        const errorMessages = validateLogin(values);
        if (errorMessages === undefined) {
            setErrors(null);
            // Everything is groovy, submit the payload
            dispatch({
                type: authTypes.AUTH_LOGIN,
                ...values
            });
        } else setErrors(errorMessages);
    }

    // Deconstruct for readability
    const { email, password, remember } = values;

    const computedErrors =
        errors || parseApiErrors(apiErrors, authTypes.AUTH_LOGIN_ERROR);

    return (
        <div className={classes.container}>
            <Logo className={classes.logo} />
            <LoginCard
                loading={authLoading}
                handleTextChange={handleTextChange}
                handleCheck={handleCheck}
                handleSubmit={handleSubmit}
                email={email}
                password={password}
                remember={remember}
                emailError={getErrorMessage(computedErrors, 'email')}
                passwordError={getErrorMessage(computedErrors, 'password')}
                globalError={apiErrors ? apiErrors.message : ''}
            />
            <Typography className={classes.bottomLink}>
                <Link to="/reset-password">Forgot password?</Link>
            </Typography>
        </div>
    );
}
