import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Autocomplete from '../../components/Autocomplete/Autocomplete';
import Button from '../../components/Button/Button';
import TextInput from '../../components/TextInput/TextInput';

import { selectUsers } from '../../state/users/reducers';
import {
    selectCommunitySavingState,
    selectCommunityApiErrors
} from '../../state/communities/reducers';

import * as modalTypes from '../../state/modal/types';
import * as communityTypes from '../../state/communities/types';
import * as userTypes from '../../state/users/types';

import { usePrevious } from '../../utils/customHooks';

import {
    validateCreateUserInCommunity,
    getErrorMessage,
    parseApiErrors
} from '../../constants/validation';

const styles = makeStyles({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 36
    },
    title: {
        fontSize: 24,
        marginBottom: 40
    },
    button: {
        width: 125
    }
});

const AddCommunityModal = () => {
    const dispatch = useDispatch();
    const classes = styles();

    // Form state
    const [title, setTitle] = React.useState('');
    const [newUser, setNewUser] = React.useState({
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        password: undefined,
    });

    const [isShowingUserForm, setIsShowingUserForm] = React.useState(false);
    const [communityAdmin, setCommunityAdmin] = React.useState();
    const [errors, setErrors] = React.useState(null);

    // Redux
    const saveState = useSelector(state => selectCommunitySavingState(state));
    const userSearchResults = useSelector(state => selectUsers(state));
    const apiErrors = useSelector(state => selectCommunityApiErrors(state));

    // Map users to autocomplete format
    const mappedResults = userSearchResults.map(a => ({
        key: a._id,
        label: `${a.firstName} ${a.lastName} (${a.email})`,
        createdOn: a.createdOn
    })).sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

    // Add create user button
    mappedResults.unshift({
        key: null,
        label: 'Create new user'
    })


    // A bit of magic required to close modal when appropriate
    const prevSaving = usePrevious(saveState);
    useEffect(() => {
        if (prevSaving && !saveState && !apiErrors) {
            dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        }
    }, [prevSaving, saveState, apiErrors]);

    const handleChange = e => {
        const { value } = e.target;
        setTitle(value);
    };

    const handleUserChange = (key, value) => {
        setNewUser({...newUser, [key]: value });
    }

    const handleSelect = id => {
        if (id) {
            setCommunityAdmin(id);
        } else {
            setIsShowingUserForm(!isShowingUserForm)
        }
    };

    // Handle submitting the form
    const submitForm = () => {
       
        let userErrors;
        const user = isShowingUserForm ? newUser : undefined
        if (user) {
            userErrors = validateCreateUserInCommunity(newUser);
            setErrors(userErrors);
        }
        if (!userErrors) {
            dispatch({
                type: communityTypes.CREATE_COMMUNITY,
                community: { title, communityAdmin, newUser: user }
            })
        }
    };

    // Hit API to search users
    const onSearchChange = search => {
        dispatch({
            type: userTypes.GET_USERS,
            page: 0,
            search
        });
    };


    const computedErrors = errors || parseApiErrors(
        apiErrors,
        parseApiErrors(apiErrors, communityTypes.CREATE_COMMUNITY_ERROR),
    );

    const disabled = !title.trim().length > 0 || communityAdmin === null;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography align="center" variant="h1" className={classes.title}>
                    Add community
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextInput
                    name="title"
                    label="Community name"
                    value={title}
                    onChange={handleChange}
                    error={Boolean(getErrorMessage(computedErrors, 'title'))}
                    errorMessage={getErrorMessage(computedErrors, 'title')}
                />
            </Grid>
            {!isShowingUserForm ?
                <Grid item xs={12}>
                    <Autocomplete
                        placeholder="Search for/select a user to make community admin"
                        suggestions={mappedResults}
                        onChangeText={onSearchChange}
                        onSelected={handleSelect}
                        error={Boolean(
                            getErrorMessage(computedErrors, 'communityAdmin')
                        )}
                        errorMessage={getErrorMessage(computedErrors, 'communityAdmin')}
                    />
                </Grid> :
                <>
                    <Grid item xs={6}>
                        <h6>Create new user</h6>
                    </Grid>
                    <Grid item xs={6}>
                        <small style={{textAlign: 'right', display: 'block'}}>
                            <button
                                style={{borderStyle: 'none', padding: 0, background: 'transparent' }}
                                type="button"
                                onClick={() => setIsShowingUserForm(false)} >
                                or select existing user
                            </button>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            name="email"
                            label="Email address"
                            value={newUser.email}
                            onChange={(e) => handleUserChange('email', e.target.value) }
                            error={Boolean(getErrorMessage(computedErrors, 'email'))}
                            errorMessage={getErrorMessage(computedErrors, 'email')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            name="firstName"
                            label="First name"
                            value={newUser.firstName}
                            onChange={(e) => handleUserChange('firstName', e.target.value) }
                            error={Boolean(getErrorMessage(computedErrors, 'firstName'))}
                            errorMessage={getErrorMessage(computedErrors, 'firstName')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            name="lastName"
                            label="Last name"
                            value={newUser.lastName}
                            onChange={(e) => handleUserChange('lastName', e.target.value) }
                            error={Boolean(getErrorMessage(computedErrors, 'lastName'))}
                            errorMessage={getErrorMessage(computedErrors, 'lastName')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            name="password"
                            label="password (make it a good one)"
                            value={newUser.password}
                            onChange={(e) => handleUserChange('password', e.target.value) }
                            error={Boolean(getErrorMessage(computedErrors, 'password'))}
                            errorMessage={getErrorMessage(computedErrors, 'password')}
                        />
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <div className={classes.buttonContainer}>
                    <Button
                        label="Add"
                        className={classes.button}
                        onClick={submitForm}
                        disabled={disabled || saveState}
                        loading={saveState}
                    />
                </div>                
            </Grid>
        </Grid>
    );
};

export default AddCommunityModal;
