import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import {withRouter} from 'react-router-dom';
import Fuse from 'fuse.js';
import {orderBy} from 'lodash';
import ListFilter from '../../components/ListFilter/ListFilter';
import * as toolkitTypes from '../../state/toolkits/types';
import * as toolkitCategoriesTypes from '../../state/toolkitCategories/types';
import {selectToolkits, selectToolkitsLoadingState} from '../../state/toolkits/reducers';
import Button from '../../components/Button/Button';
import ToolkitCard from '../../components/RowCards/ToolkitCard';
import Table from '../../components/CustomTable/Table';
import Checkbox from '../../components/Checkbox/Checkbox';
import AssignUserDrawer from '../../components/AssignUserDrawer/AssignUserDrawer';
import {selectToolkitCategories} from '../../state/toolkitCategories/reducers';

const fuseOpts = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['title', 'description']
};

class ToolkitList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            filter: '',
            showAssignUserDrawer: false,
            selectedToolkits: [],
        };
        const { getToolkits, getToolkitCategories } = this.props;
        getToolkits()
        getToolkitCategories()
    }

    openCreateToolkit = () => {
        const {history} = this.props;
        history.push('/toolkits/add');
    }

    openEditToolkit = toolkit => {
        const { history} = this.props;
        history.push(`/toolkits/${toolkit.id}/edit`);
    };

    searchToolkits = toolkits => {
        const { search } = this.state;
        const fuseInstance = new Fuse(toolkits, fuseOpts);
        return fuseInstance.search(search);
    };

    onSearchChange = e => {
        this.setState({ search: e });
    };

    onFilterChange = e => {
        const { value } = e.target;
        this.setState({ filter: value });
    };

    onFilterCategories = (toolkits) => {
        const {  filter } = this.state;


        return toolkits.filter(toolkit => toolkit.categories.some(t => {
            if (t === filter) {
                return true
            }

            return false;
        }))
    }

    render() {
        const { readOnly, toolkitCategories, toolkits, toolkitsLoadingState: loadingState } = this.props;
        const { search, filter, selectedToolkits, showAssignUserDrawer } = this.state;

        const filterOptions = orderBy(
            toolkitCategories.map(c => ({label: c.name, value: c.id})), ['value'], ['asc']
        )

        const hasSearch = search.trim().length > 0;
        const searchResults = hasSearch ? this.searchToolkits(toolkits) : toolkits;
        const computedToolkits = filter ? this.onFilterCategories(searchResults) : searchResults;

        return (
            <React.Fragment>
                <h1>Global Tools</h1>
                <ListFilter
                    readOnly={readOnly}
                    search={search}
                    filterOptions={filterOptions}
                    filter={filter}
                    onSearchChange={this.onSearchChange}
                    onFilterChange={this.onFilterChange}
                    onCreate={this.openCreateToolkit}
                    createLabel="Create new tool"
                    searchPlaceholder="Search all tools..."
                    filterByPlaceholder="Filter by category"
                    btnStyles={{width: '50%', borderRadius: 50}}
                    customBatchBtn={
                        <Button
                            disabled={selectedToolkits.length === 0}
                            iconPosition="start"
                            icon={<AddIcon />}
                            background="grey"
                            label={`Batch assign ${selectedToolkits.length} tools`}
                            style={{width: '50%', borderRadius: 50}}
                            onClick={() => this.setState({showAssignUserDrawer: true})}
                        />
                    }
                    btnIcon={<AddIcon />}
                />
                <div style={{
                    marginTop: '1rem',
                    marginLeft: '1rem',
                    background: '#f7f7f7',
                    width: '10rem',
                    borderRadius: 10,
                    paddingLeft: '1rem'
                }}
                >
                    <Checkbox
                        checked={filter ?
                            computedToolkits.some(t => selectedToolkits.includes(t.id)) :
                            toolkits.some(t => selectedToolkits.includes(t.id))
                        }
                        label={`${selectedToolkits.length} tools selected`}
                        onChange={() => {
                            const filteredToolkits = filter ? computedToolkits : toolkits;

                            if (filteredToolkits.some(t => selectedToolkits.includes(t.id))) {
                                this.setState({
                                    selectedToolkits:[]
                                })
                            } else {
                                this.setState({
                                    selectedToolkits: filteredToolkits.map(t => t.id)
                                })
                            }
                        }}
                    />
                </div>
                <div style={{marginTop: '1rem'}}>
                    <Table
                        enableCheckbox
                        isChecked={row => selectedToolkits.includes(row.id)}
                        onCheck={(row) => {
                            if (selectedToolkits.includes(row.id)) {
                                this.setState({
                                    selectedToolkits: selectedToolkits.filter(t => t !== row.id)
                                })
                            } else {
                                this.setState({
                                    selectedToolkits: [...selectedToolkits, row.id]
                                })
                            }
                        }}
                        onClick={row => (this.openEditToolkit(row))}
                        loading={loadingState}
                        data={computedToolkits}
                        rowCard={ToolkitCard}
                        readOnly={readOnly}
                        noDataMessage={hasSearch ? 'No toolkits found' :
                            <>
                                <p>No Toolkits</p>
                                <small style={
                                    {
                                        fontFamily: 'SFUIDisplay-SemiBold',
                                    }
                                }
                                >
                                    Start by clicking “Create new tool”
                                </small>
                            </>}
                    />
                </div>
                <AssignUserDrawer
                    selectedToolkits={selectedToolkits}
                    open={showAssignUserDrawer}
                    handleClose={() => this.setState({showAssignUserDrawer: false})}
                    onSubmit={() => {
                        this.setState({
                            selectedToolkits: [],
                            showAssignUserDrawer: false
                        })
                    }}
                />
            </React.Fragment>
        )
    }
}


ToolkitList.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    getToolkits: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    toolkits: PropTypes.object.isRequired,
    toolkitsLoadingState: PropTypes.bool.isRequired,
    getToolkitCategories: PropTypes.func.isRequired,
    toolkitCategories: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    toolkits: selectToolkits(state),
    toolkitsLoadingState: selectToolkitsLoadingState(state),
    toolkitCategories: selectToolkitCategories(state),
    loggedInUser: state.auth.loggedInUser
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getToolkits: () => ({
                type: toolkitTypes.GET_TOOLKITS
            }),
            getToolkitCategories: () => ({
                type: toolkitCategoriesTypes.GET_TOOLKIT_CATEGORIES
            }),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ToolkitList))
