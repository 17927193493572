import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import CardLoading from './CardLoading';

const styles = makeStyles({
    '@keyframes glow': {
        '0%, 100%': { opacity: 0.5 },
        '50%': { opacity: 1 }
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    pagination: {
        color: '#f7f7f7',
        height: 40,
        width: '25%',
        backgroundColor: '#f7f7f7',
        animation: '$glow 1.5s ease-in-out infinite'
    }
});

export default function UserListLoading() {
    const classes = styles();
    return (
        <>
            {[...new Array(10)].map((e, idx) => (
                <CardLoading key={`loading-${idx}`} />
            ))}
            <TableRow>
                <TableCell style={{ border: 0 }}>
                    <div className={classes.paginationContainer}>
                        <Typography className={classes.pagination}>
                            Loading
                        </Typography>
                    </div>
                </TableCell>
            </TableRow>
        </>
    );
}
