import { all } from 'redux-saga/effects';

import authSagas from './auth/sagas';
import communitySagas from './communities/sagas';
import userSagas from './users/sagas';
import toolkitSaga from './toolkits/sagas';
import toolkitCategorySaga from './toolkitCategories/sagas';
import teamSagas from './teams/sagas';

export default function* rootSaga() {
    yield all([
        ...authSagas,
        ...communitySagas,
        ...userSagas,
        ...toolkitSaga,
        ...toolkitCategorySaga,
        ...teamSagas
    ]);
}
