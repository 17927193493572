import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  themeName: 'Project Theme',
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: '"SFUIText-Reg", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"SFUIDisplay-Reg", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 34
    },
    h2: {
      fontFamily: '"SFUIDisplay-Reg", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 24
    }
  },
  palette: {
    primary: {
      main: '#072144'
    },
    secondary: {
      main: '#A5721A'
    },
    success: {
      main: '#4ab970'
    },
    warning: {
      main: '#4f5c6e'
    },
    error: {
      main: '#f20046'
    },
    disabled: {
      main: '#4f5c6e'
    },
    text: {
      primary: '#223f63',
      secondary: '#223f63'
    },
    background: {
      default: '#ffffff',
      paper: '#fefefe'
    }
  },
  overrides: {
    MuiTabs: {
      root: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1
      },
      indicator: {
        display: 'none'
      },
    },
    MuiTab: {
      
      root: {
        opacity: 0.5,
        "&$selected": { 
          opacity: 1,
          borderBottomStyle: 'none',
          borderRadius: 10,
          fontWeight: 'bold'
        }
      },
    }
  }
});

export default theme;
