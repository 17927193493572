import { takeLatest, call, put, select } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';
import * as snackTypes from '../snack/types';

import { selectSelectedCommunityId, selectCommunityUsers } from './reducers';

function* getCommunities({ page, search }) {
    try {
        yield put({ type: types.COMMUNITY_LOADING_STATE, state: true });
        const result = yield call(actions.getCommunities, page, search);
        const {
            data: { results, total }
        } = result;
        yield put({
            type: types.GET_COMMUNITIES_SUCCESS,
            communities: results,
            totalCommunities: total
        });
        yield put({ type: types.COMMUNITY_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.COMMUNITY_LOADING_STATE, state: false });
    }
}

function* getCommunity({ communityId }) {
    try {
        yield put({ type: types.COMMUNITY_LOADING_STATE, state: true });

        const community = yield call(actions.getCommunity, communityId);
        const { data: selectedCommunity } = community;

        const users = yield call(actions.getCommunityUsers, communityId);
        const { data: communityUsers } = users;

        yield put({
            type: types.GET_COMMUNITY_SUCCESS,
            selectedCommunity,
            communityUsers
        });

        yield put({ type: types.COMMUNITY_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.COMMUNITY_LOADING_STATE, state: false });
    }
}

function* setUserAdmin({ userId, communityId }) {
    try {
        const result = yield call(actions.addCommunityAdmin, communityId, userId);
        const {
            data: { users }
        } = result;

        yield put({
            type: types.SET_USER_ADMIN_SUCCESS,
            users
        });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Success!',
            open: true,
            props: { variant: 'success' }
        });
    } catch (error) {
        yield put({ type: types.SET_USER_ADMIN_ERROR, payload: error });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* removeUserAdmin({ userId, communityId }) {
    try {
        const result = yield call(actions.removeCommunityAdmin, communityId, userId);

        const {
            data: { users }
        } = result;
        yield put({ type: types.REMOVE_USER_ADMIN_SUCCESS, users });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Success!',
            open: true,
            props: { variant: 'success' }
        });
    } catch (error) {
        yield put({ type: types.REMOVE_LEADER_FROM_USER_ERROR, payload: error });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* updateCommunity({ communityId, title, description }) {
    try {
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: true });

        const result = yield call(actions.updateCommunity, communityId, {
            title,
            description
        });
        const { data } = result;
        yield put({ type: types.UPDATE_COMMUNITY_SUCCESS, selectedCommunity: data });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Community successfully updated',
            open: true,
            props: { variant: 'success' }
        });

        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    } catch (error) {
        // Send api errors to redux
        yield put({
            type: types.UPDATE_COMMUNITY_ERROR,
            errors: {
                key: types.UPDATE_COMMUNITY_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    }
}

function* removeUser({ userId }) {
    try {
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: true });
        const communityId = yield select(selectSelectedCommunityId);
        const currentUsers = yield select(selectCommunityUsers);

        const result = yield call(
            actions.removeUserFromCommunity,
            communityId,
            userId
        );
        const {
            data: { users }
        } = result;

        // Returns the community object that only holds user ids - filter current state to remove the user
        const updatedUsers = currentUsers.filter(user =>
            users.some(u => user._id === u)
        );

        yield put({ type: types.REMOVE_USER_SUCCESS, users: updatedUsers });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User successfully removed from community',
            open: true,
            props: { variant: 'success' }
        });
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    } catch (error) {
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({
            type: types.REMOVE_USER_ERROR,
            errors: {
                key: types.REMOVE_USER_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    }
}

function* createCommunity({ community }) {
    try {
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: true });
        const result = yield call(actions.createCommunity, community);
        const { data } = result;
        yield put({ type: types.CREATE_COMMUNITY_SUCCESS, community: data });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Community created successfully!',
            open: true,
            props: { variant: 'success' }
        });
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    } catch (error) {
        // Send api errors to API
        yield put({
            type: types.CREATE_COMMUNITY_ERROR,
            errors: {
                key: types.CREATE_COMMUNITY_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    }
}

function* inviteUser({ communityId, payload }) {
    try {
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: true });

        const result = yield call(
            actions.inviteUserToCommunity,
            communityId,
            payload
        );
        const {
            data: { user }
        } = result;
        yield put({ type: types.INVITE_USER_SUCCESS, user });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User has been invited to the community!',
            open: true,
            props: { variant: 'success' }
        });

        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    } catch (error) {
        // Send api errors to API
        yield put({
            type: types.INVITE_USER_ERROR,
            errors: {
                key: types.INVITE_USER_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    }
}

function* addLeadersToUser({ userId, leaders, previousLeaders }) {
    try {
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: true });
        const communityId = yield select(selectSelectedCommunityId);

        if (leaders.length > 0) {
            const result = yield call(
                actions.addLeaderToUser,
                communityId,
                userId,
                leaders
            );
            const { data } = result;
            yield put({ type: types.ADD_LEADERS_TO_USER_SUCCESS, user: data });
        }
        // API only supports removing leaders one at a time
        for (let index in previousLeaders) {
            if (!leaders.includes(previousLeaders[index])) {
                console.log('need to remove');
                const result = yield call(
                    actions.removeLeaderFromUser,
                    communityId,
                    userId,
                    previousLeaders[index]
                );
                const { data } = result;
                yield put({
                    type: types.REMOVE_LEADER_FROM_USER_SUCCESS,
                    user: data
                });
            } else {
                console.log('no need to remove');
            }
        }

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Leaders have been assigned to the user',
            open: true,
            props: { variant: 'success' }
        });

        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    } catch (error) {
        yield put({
            type: types.ADD_LEADERS_TO_USER_ERROR,
            errors: {
                key: types.ADD_LEADERS_TO_USER_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    }
}

function* batchUploadUsers({ formData }) {
    try {
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: true });

        const communityId = yield select(selectSelectedCommunityId);
        const result = yield call(actions.batchUpload, communityId, formData);
        const { data } = result;

        // Remove any duplicates from current community users and duplicates
        const currentCommunityUsers = yield select(selectCommunityUsers);
        const userSet = new Set(currentCommunityUsers.map(u => u._id));
        const merged = [
            ...currentCommunityUsers,
            ...data.newUsers,
            ...data.existingUsers.filter(user => !userSet.has(user._id))
        ];

        yield put({
            type: types.BATCH_UPLOAD_USERS_SUCCESS,
            batchResults: data,
            communityUsers: merged
        });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Users have been added to the community!',
            open: true,
            props: { variant: 'success' }
        });

        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    } catch (error) {
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
        yield put({
            type: types.BATCH_UPLOAD_USERS_ERROR,
            errors: {
                key: types.BATCH_UPLOAD_USERS_ERROR,
                errors: error.response.data.errors
            }
        });
        yield put({ type: types.COMMUNITY_SAVING_STATE, state: false });
    }
}

function* getCommunityUsers({ communityId, showProgress = false }) {
    try {
        const communityUsers = yield select(selectCommunityUsers);
        if (showProgress || !communityUsers.length) {
            yield put({ type: types.COMMUNITY_USERS_LOADING_STATE, state: true });
        }

        const { data } = yield call(actions.getCommunityUsers, communityId);
        yield put({ type: types.GET_COMMUNITY_USERS_SUCCESS, users: data });

        yield put({ type: types.COMMUNITY_USERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.COMMUNITY_USERS_LOADING_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* getCommunityLeaders({ communityId }) {
    try {
        yield put({ type: types.COMMUNITY_LEADERS_LOADING_STATE, state: true });

        const result = yield call(actions.getCommunityLeaders, communityId);
        const { data } = result;

        yield put({ type: types.GET_COMMUNITY_LEADERS_SUCCESS, leaders: data });

        yield put({
            type: types.COMMUNITY_LEADERS_LOADING_STATE,
            state: false
        });
    } catch (error) {
        yield put({ type: types.COMMUNITY_LEADERS_LOADING_STATE, state: true });
        yield put({
            type: snackTypes.SET_SNACK,
            content: error.response.data.message,
            open: true,
            props: { variant: 'error' }
        });
    }
}

export default [
    takeLatest(types.GET_COMMUNITIES, getCommunities),
    takeLatest(types.CREATE_COMMUNITY, createCommunity),
    takeLatest(types.GET_COMMUNITY, getCommunity),
    takeLatest(types.SET_USER_ADMIN, setUserAdmin),
    takeLatest(types.UPDATE_COMMUNITY, updateCommunity),
    takeLatest(types.REMOVE_USER, removeUser),
    takeLatest(types.INVITE_USER, inviteUser),
    takeLatest(types.ADD_LEADERS_TO_USER, addLeadersToUser),
    takeLatest(types.BATCH_UPLOAD_USERS, batchUploadUsers),
    takeLatest(types.REMOVE_USER_ADMIN, removeUserAdmin),
    takeLatest(types.GET_COMMUNITY_USERS, getCommunityUsers),
    takeLatest(types.GET_COMMUNITY_LEADERS, getCommunityLeaders),
];
