import { upsertArrayByKey } from '../../utils/helpers';
import * as TYPES from './types';

const initialState = {
    teams: [],
    loadingCommunities: [],
    loadingState: false
}


const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.TEAM_LOADING_STATE:
            return {
                ...state,
                loadingState: action.state
            };

        case TYPES.GET_ALL_TEAMS_SUCCESS:
            return {
                ...state,
                teams: action.teams
            };

        case TYPES.GET_TEAMS_SUCCESS:
            return {
                ...state,
                teams: upsertArrayByKey('_id', state.teams, action.payload)
            }

        case TYPES.GET_ALL_TEAMS_ERROR:
            return {
                ...state,
                apiErrors: action.errors
            };

        default:
            return {
                ...state
            }
    }
} 

export default teamReducer;

export const selectCommunityTeams = (state, id) => state.teams.teams.filter(t => t.community === id);
export const selectTeams = state => state.teams.teams;