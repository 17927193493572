import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';

// import useProfileImage from '../../hooks/useProfileImage';
import { selectMemberById } from '../../state/communities/reducers';

import defaultAvatar from '../../static/images/default-avatar.png';

const useStyles = makeStyles({
    wrap: ({ size }) => ({
        position: 'relative',
        height: size,
        width: size
    }),
    image: ({ size, profileImage, borderRadius, shadow, computedLoading }) => ({
        position: 'absolute',
        height: size,
        width: size,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: `url(${profileImage})`,
        borderRadius,
        boxShadow: shadow ? '0 2px 4px -2px rgba(0, 0, 0, 0.2)' : 'none',
        backgroundColor: '#ffffff',
        '&::after': {
            content: '\'\'',
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: computedLoading ? 'rgba(0,0,0,0.5)' : 'transparent',
            borderRadius
        }
    }),
    circular: {
        padding: 10
    }
});

const ProfileImage = ({ userId, style, className, size, borderRadius, shadow, onClick }) => {
    // const { image: profileImage, loading, isPlaceholder } = useProfileImage(userId);

    const member = useSelector(state => selectMemberById(state, userId));

    // const computedLoading = loading && isPlaceholder;
    const classes = useStyles({
        size,
        profileImage: member?.avatar?.md || defaultAvatar,
        borderRadius,
        shadow,
        computedLoading: false
    });

    return (
        <div className={clsx(classes.wrap, className)} style={style}>
            <div className={classes.image} onClick={onClick} role="presentation" />
        </div>
    );
    /*
  return (
    <div className={clsx(classes.wrap, className)} style={style}>
      <div className={classes.image} onClick={onClick} role="presentation" />
      {loading && isPlaceholder && (
        <CircularProgress
          className={classes.circular}
          size={size}
          color="secondary"
        />
      )}
    </div>
  );
  */
};

ProfileImage.propTypes = {
    userId: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    size: PropTypes.number,
    borderRadius: PropTypes.number,
    shadow: PropTypes.bool,
    onClick: PropTypes.func
};

ProfileImage.defaultProps = {
    style: null,
    className: '',
    size: 40,
    borderRadius: 10,
    shadow: true,
    onClick: null
};

export default ProfileImage;
