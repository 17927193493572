import { combineReducers } from 'redux';

import authReducer from './auth/reducers';
import modalReducer from './modal/reducers';
import communityReducer from './communities/reducers';
import userReducer from './users/reducers';
import snackReducer from './snack/reducers';
import toolkitReducer from './toolkits/reducers';
import toolkitCategoryReducer from './toolkitCategories/reducers';
import teams from './teams/reducers';

const rootReducer = combineReducers({
    auth: authReducer,
    modal: modalReducer,
    community: communityReducer,
    users: userReducer,
    snack: snackReducer,
    toolkit: toolkitReducer,
    toolkitCategory: toolkitCategoryReducer,
    teams
});

export default rootReducer;
