import React from 'react';
import { Route } from 'react-router-dom';

import AuthGuardian from './AuthGuardian';
import AppShell from '../containers/AppShell';

export default function AuthenticatedRoute(props) {
    const {
        component: Component,
        permissions: routePermissions,
        title: routeTitle,
        ...rest
    } = props;
    // Do all route specific permission checks here
    return (
        <Route
            exact
            {...rest}
            render={routeProps => (
                <AuthGuardian>
                    <AppShell routeTitle={routeTitle}>
                        <Component {...routeProps} />
                    </AppShell>
                </AuthGuardian>
            )}
        />
    );
}
