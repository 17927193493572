import * as types from './types';

const initialState = {
    loadingState: false,
    savingState: false,
    toolkitCategoryCreated: false,
    toolkitCategories: [],
    apiErrors: null,
    selectedToolkitCategory: undefined
};

const toolkitCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TOOLKIT_CATEGORY_LOADING_STATE:
            return {
                ...state,
                loadingState: action.state
            };
        case types.TOOLKIT_CATEGORY_SAVING_STATE:
            return {
                ...state,
                savingState: action.state
            };
        case types.CREATE_TOOLKIT_CATEGORY_SUCCESS:
            return {
                ...state,
                toolkitCategoryCreated: true,
                apiErrors: null
            };
        case types.EDIT_TOOLKIT_CATEGORY_SUCCESS:
            return {
                ...state,
                apiErrors: null
            };
        case types.DELETE_TOOLKIT_CATEGORY_SUCCESS:
            return {
                ...state,
                apiErrors: null
            };
        case types.GET_TOOLKIT_CATEGORIES_SUCCESS:
            return {
                ...state,
                toolkitCategories: action.toolkitCategories
            };
        case types.CREATE_TOOLKIT_CATEGORY_ERROR:
        case types.EDIT_TOOLKIT_CATEGORY_ERROR:
        case types.GET_TOOLKIT_CATEGORIES_ERROR:
        case types.DELETE_TOOLKIT_CATEGORY_ERROR:
            return {
                ...state,
                apiErrors: action.errors
            };
        default:
            return state;
    }
}

export default toolkitCategoryReducer

export const selectToolkitCategories = state => state.toolkitCategory.toolkitCategories;
export const selectToolkitCategoriesLoadingState = state => state.toolkitCategory.loadingState;
export const selectToolkitCategoriesSavingState = state => state.toolkitCategory.savingState;
export const selectSelectedToolkitCategory = state => state.toolkitCategory.selectedToolkitCategory;