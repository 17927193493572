import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import MomentUtils from '@date-io/moment';
import PageContainer from '@/components/PageContainer/PageContainer';
import MenuDrawer from '@/components/MenuDrawer/MenuDrawer';
import Modal from '@/components/Modal/Modal';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

export default function AppShell({ children, routeTitle }) {
    // Drawer, chat, modal here
    return (
        <div style={{ display: 'flex' }}>
            <CssBaseline />
            <MenuDrawer />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <PageContainer routeTitle={routeTitle}>{children}</PageContainer>
                <Modal />
            </MuiPickersUtilsProvider>
        </div>
    );
}
