import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { ReactComponent as Logo } from '../../static/images/logoMark.svg';
import { ReactComponent as LogoutIcon } from '../../static/images/icons/icon-logout.svg';

import routes from '../../constants/routes';
import * as authTypes from '../../state/auth/types';

const styles = makeStyles(theme => ({
    drawerRoot: {
        width: 200
    },
    drawerPaper: {
        width: 200,
        paddingTop: 19,
        background: theme.palette.background.dark
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#072144 !important',
        textDecoration: 'none'
    },
    list: {
        flex: 1
    },
    listItem: {
        paddingLeft: 30,
        paddingTop: 12,
        paddingBottom: 12,
        '&:hover': {
            color: '#d9a035 !important',
            '& svg': {
                color: '#d9a035 !important',
                '& path': {
                    fill: '#d9a035 !important'
                }
            }
        }
    },
    listItemActive: {
        color: '#d9a035 !important',
        '& a, svg': {
            color: '#d9a035 !important',
            '& path': {
                fill: '#d9a035 !important'
            }
        }
    },
    listItemIcon: {
        minWidth: 0,
        color: '#072144',
        paddingRight: 14,
        '& path': {
            fill: '#072144 !important'
        }
    },
    listItemText: {
        color: 'inherit',
        fontSize: '14px !important',
        flexGrow: 1
    },
    logout: {
        cursor: 'pointer',
        fontFamily: 'SFUIDisplay-Bold'
    }
}));

const MenuDrawer = () => {
    const classes = styles();
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    const logout = () => {
        dispatch({ type: authTypes.AUTH_LOGOUT });
    };

    return (
        <Drawer
            classes={{
                root: classes.drawerRoot,
                paper: classes.drawerPaper
            }}
            variant="permanent"
            anchor="left"
        >
            <Box
                display="flex"
                justifyContent="center"
                marginTop={4}
                marginBottom={4}
            >
                <Logo style={{ width: 40, height: 40 }} />
            </Box>
            <List className={classes.list}>
                {routes
                    .filter(route => route.navMenu)
                    .map((route, index) => {
                        const { icon: Icon } = route;
                        const itemClass = clsx({
                            [classes.listItem]: true,
                            [classes.listItemActive]: pathname.includes(route.path)
                        });
                        return (
                            <Link
                                to={route.path}
                                className={classes.link}
                                key={index}
                            >
                                <ListItem button className={itemClass}>
                                    <ListItemIcon
                                        classes={{
                                            root: classes.listItemIcon
                                        }}
                                    >
                                        <Icon style={{ fontSize: 14 }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary: classes.listItemText
                                        }}
                                        primary={route.title}
                                    />
                                </ListItem>
                            </Link>
                        );
                    })}
            </List>
            <List>
                <ListItem
                    button
                    onClick={logout}
                    className={clsx(classes.listItem, classes.link)}
                >
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        <LogoutIcon style={{ fontSize: 14 }} />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Log Out"
                    />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default MenuDrawer;
