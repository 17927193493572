import axios from 'axios';

export const getUser = async uuid => {
    try {
        const result = await axios.get(`/v1/users/${uuid}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const createUser = async payload => {
    try {
        const result = await axios.post('/v1/users', payload);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getUsers = async (skip, search, limit = 10, order = 'desc') => {
    try {
        const result = await axios.get('/v1/users', {
            params: { limit, skip: skip * 10, order, search }
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const assignOfficer = async userId => {
    try {
        const result = await axios.patch(`/v1/users/${userId}/roles`, {
            role: 'officer'
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeOfficer = async userId => {
    try {
        const result = await axios.delete(`/v1/users/${userId}/roles`, {
            data: {
                role: 'officer'
            }
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const archiveUser = async userId => {
    try {
        const result = await axios.delete(`/v1/users/${userId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getUserProfileImage = async userId => {
    try {
        const result = await axios.get(`/v1/users/${userId}/profilePicture`, {
            responseType: 'blob'
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};
