import React from "react";
import AuthBackground from "@/components/Auth/AuthBackground/AuthBackground";

export default function Route404() {
  return (
    <>
      <AuthBackground />
      <div
        style={{
          minHeight: '100vh',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          color: "#ffffff"
        }}
      >
        <h1>404</h1>
        <h4>Are you sure you're meant to be here?</h4>
      </div>
    </>
  );
}
