export const GET_COMMUNITIES = 'communities/GET_COMMUNITIES';
export const GET_COMMUNITIES_SUCCESS = 'communities/GET_COMMUNITIES_SUCCESS';
export const GET_COMMUNITIES_ERROR = 'communities/GET_COMMUNITIES_ERROR';

export const GET_COMMUNITY = 'communities/GET_COMMUNITY';
export const GET_COMMUNITY_SUCCESS = 'communities/GET_COMMUNITY_SUCCESS';
export const GET_COMMUNITY_ERROR = 'communities/GET_COMMUNITY_ERROR';

export const CREATE_COMMUNITY = 'communities/CREATE_COMMUNITY';
export const CREATE_COMMUNITY_SUCCESS = 'communities/CREATE_COMMUNITY_SUCCESS';
export const CREATE_COMMUNITY_ERROR = 'communities/CREATE_COMMUNITY_ERROR';

export const UPDATE_COMMUNITY = 'communities/UPDATE_COMMUNITY';
export const UPDATE_COMMUNITY_SUCCESS = 'communities/UPDATE_COMMUNITY_SUCCESS';
export const UPDATE_COMMUNITY_ERROR = 'communities/UPDATE_COMMUNITY_ERROR';

export const SET_USER_ADMIN = 'communities/SET_USER_ADMIN';
export const SET_USER_ADMIN_SUCCESS = 'communities/SET_USER_ADMIN_SUCCESS';
export const SET_USER_ADMIN_ERROR = 'communities/SET_USER_ADMIN_ERROR';

export const REMOVE_USER_ADMIN = 'communities/REMOVE_USER_ADMIN';
export const REMOVE_USER_ADMIN_SUCCESS = 'communities/REMOVE_USER_ADMIN_SUCCESS';
export const REMOVE_USER_ADMIN_ERROR = 'communities/REMOVE_USER_ADMIN_ERROR';

export const REMOVE_USER = 'communities/REMOVE_USER';
export const REMOVE_USER_SUCCESS = 'communities/REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'communities/REMOVE_USER_ERROR';

export const INVITE_USER = 'communities/INVITE_USER';
export const INVITE_USER_SUCCESS = 'communities/INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'communities/INVITE_USER_ERROR';

export const ADD_LEADERS_TO_USER = 'communities/ADD_LEADERS_TO_USER';
export const ADD_LEADERS_TO_USER_SUCCESS = 'communities/ADD_LEADERS_TO_USER_SUCCESS';
export const ADD_LEADERS_TO_USER_ERROR = 'communities/ADD_LEADERS_TO_USER_ERROR';

export const REMOVE_LEADER_FROM_USER = 'communities/REMOVE_LEADER_FROM_USER';
export const REMOVE_LEADER_FROM_USER_SUCCESS =
    'communities/REMOVE_LEADER_FROM_USER_SUCCESS';
export const REMOVE_LEADER_FROM_USER_ERROR =
    'communities/REMOVE_LEADER_FROM_USER_ERROR';

export const BATCH_UPLOAD_USERS = 'communities/BATCH_UPLOAD_USERS';
export const BATCH_UPLOAD_USERS_SUCCESS = 'communities/BATCH_UPLOAD_SUCCESS';
export const BATCH_UPLOAD_USERS_ERROR = 'communities/BATCH_UPLOAD_ERROR';
export const CLEAR_BATCH_RESULTS = 'communities/CLEAR_BATCH_RESULTS';

export const COMMUNITY_LOADING_STATE = 'communities/COMMUNITY_LOADING_STATE';
export const COMMUNITY_SAVING_STATE = 'communities/COMMUNITY_SAVING_STATE';
export const COMMUNITY_USERS_LOADING_STATE =
    'communities/COMMUNITY_USERS_LOADING_STATE';

export const GET_COMMUNITY_USERS = 'community/GET_COMMUNITY_USERS';
export const GET_COMMUNITY_USERS_SUCCESS = 'community/GET_COMMUNITY_USERS_SUCCESS';
export const GET_COMMUNITY_USERS_ERROR = 'community/GET_COMMUNITY_USERS_ERROR';

export const GET_COMMUNITY_LEADERS = 'community/GET_COMMUNITY_LEADERS';
export const GET_COMMUNITY_LEADERS_SUCCESS =
    'community/GET_COMMUNITY_LEADERS_SUCCESS';
export const GET_COMMUNITY_LEADERS_ERROR = 'community/GET_COMMUNITY_LEADERS_ERROR';

export const COMMUNITY_LEADERS_LOADING_STATE =
    'community/COMMUNITY_LEADERS_LOADING_STATE';

export const CLEAR_COMMUNITY_API_ERRORS = 'communities/CLEAR_COMMUNITY_API_ERRORS';