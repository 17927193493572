import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = makeStyles(theme => ({
  paper: {
    padding: '10px 20px',
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    backgroundColor: '#f7f7f7',
    'border-radius': '10px',
  },
  inputRoot: {
    marginLeft: 8,
    flex: 1
  },
  input: {
    padding: 0,
    fontSize: 14,
    color: '#838b96',
  }
}));


const SearchInput = props => {
  const classes = styles();
  const { placeholder, onChange, style, value, className } = props;
  const computedRootClass = className ? `${className} ${classes.paper}` : classes.paper;
  return (
    <div style={style} className={computedRootClass}>
      <InputBase

        value={value}
        classes={{
          root: classes.inputRoot,
          input: classes.input
        }}

        startAdornment={(
          <InputAdornment position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        )}
        
        className={classes.input}
        placeholder={placeholder}
        onChange={event => onChange(event.target.value)}
      />
    </div>
  )
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

SearchInput.defaultProps = {
  placeholder: '',
  style: null,
  value: null,
  className: null
};

export default SearchInput;
