import axios from 'axios';

export const envConfig = async () => {

    const isProd = 'NODE_ENV' in process.env && process.env.NODE_ENV === 'production';

    return new Promise(async resolve => {
        if (isProd) {
            const result = await axios.get('/config');
            const { data } = result;

            // 	// assign the variables to window.__env__
            window.__env__ = {
                BASE_URL: data.baseUrl,
                CMS_URL: data.cmsUrl,
                PORTAL_URL: data.portalUrl,
                SET_PASSWORD_ROUTE: data.setPasswordRoute,
                V2_BASE_URL: data.v2BaseUrl,
            };

            // FIXME: this is for a wrong project
            resolve({ isProd: true, merloEnv: data.merloEnv });
        } else {
            resolve({ isProd: false, merloEnv: process.env.REACT_APP_MERLO_ENV });
        }

    });
};

export default envConfig;