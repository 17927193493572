import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default function BackToParent({ text, route }) {
    return (
        <Link to={route} style={{ display: 'flex', alignItems: 'center', width: 'fit-content', color: '#072144' }}>
            <ChevronLeftIcon style={{ fontSize: 50 }} />
            <Typography style={{ fontSize: 14, fontFamily: 'SFUIDisplay-Bold' }}>{text}</Typography>
        </Link>
    );
}

BackToParent.propTypes = {
    route: PropTypes.string.isRequired,
    text: PropTypes.string
};

BackToParent.defaultProps = {
    text: 'Back'
};
