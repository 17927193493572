import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress, Chip, Checkbox, TableRow, TableCell, Typography, Paper, Button , Slide } from '@material-ui/core/';
import { useParams , Link } from 'react-router-dom';


import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { GET_TEAM_TOOLKITS, GET_ALL_TOOLKITS, ADD_TOOLKIT_TO_TEAM, REMOVE_TOOLKIT_FROM_TEAM } from '../../state/toolkits/types';
import { selectToolkitsForTeam } from '../../state/toolkits/reducers';
import { GET_TEAMS } from '../../state/teams/types';
import { selectCommunityTeams } from '../../state/teams/reducers';

const ToolkitRow = ({ title, isLoading, categories, _id, inCommunity, onChange }) => {

    const { uuid } = useParams();
    const dispatch = useDispatch();

    return (
        <TableRow>
            <TableCell style={{width: 50}}>
                { isLoading? <CircularProgress size={30} /> :
                    <Checkbox
                        checked={inCommunity}
                        onChange={(_, checked) => {
                            onChange(checked)
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
            </TableCell>
            <TableCell align="left" component="th" scope="row">
                <Link to={`/toolkits/${_id}`}>{title}</Link>
            </TableCell>
            <TableCell align="right">{categories.map(c => <Chip key={c} size="small" label={c}/>)}</TableCell>
        </TableRow>
    )
}


const TeamRow = ({ title, _id, onSelect }) => {

    return (
        <TableRow>
            <TableCell align="left" component="th" scope="row">
                {title}
            </TableCell>
            <TableCell align="right"><Button onClick={() => onSelect(_id)}>Edit Toolkits</Button></TableCell>
        </TableRow>
    )
}

const ToolkitTab = () => {
    const [ page, setPage ] = useState(0)

    const [ selectedTeam, setSelectedTeam ] = useState()

    const dispatch = useDispatch();

    const { uuid } = useParams();

    const { data, teams } = useSelector(state => ({
        data: selectedTeam ? selectToolkitsForTeam(state, selectedTeam) : [],
        teams: selectCommunityTeams(state, uuid)
    }))

    useEffect(() => {

        dispatch({ type: GET_TEAMS, payload: { communityUuid: uuid } });
        // Not great to do this here but codebase does not have a bootstrap function
        dispatch({ type: GET_ALL_TOOLKITS })
    }, [])

    useEffect(() => {
        if (selectedTeam) {
            dispatch({ type: GET_TEAM_TOOLKITS, payload: { communityId: uuid, teamId: selectedTeam } })
        }
    }, [selectedTeam])

 
    return (
      <>
        <Box marginTop={3} marginBottom={3}>
            <Typography>Select which toolkits are available to all users within the community</Typography>
        </Box>
        {/* {JSON.stringify(teams)} */}
        {/* <Table
            data={data}
            rowCard={Row}
            count={data.length}
            page={page}
            onPageChange={onPageChange}
        /> */}
        {/* <Box style={{overflow: 'hidden'}}>
        <Slide direction="left" in={selectedTeam} mountOnEnter unmountOnExit>
          <Box>
            <Typography onClick={()=> setSelectedTeam(!selectedTeam)}>HELLO</Typography>
          </Box>
        </Slide>
        <Slide direction="right" in={!selectedTeam} mountOnEnter unmountOnExit>
          <Box>
            <Typography onClick={()=> setSelectedTeam(!selectedTeam)}>HELLO</Typography>
          </Box> 
        </Slide>
        </Box> */}
        <Box position="relative">
            <Slide direction="right" in={!selectedTeam ? true : false}  mountOnEnter unmountOnExit>
                <Box position="absolute" style={{width: '100%', height: '100%'}}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Title</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teams.map((row) => (
                                    <TeamRow key={row._id} {...row} onSelect={(id) => setSelectedTeam(id)} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Slide>
 
            <Slide direction="left" in={selectedTeam ? true : false} mountOnEnter unmountOnExit >
                <Box position="absolute" style={{width: '100%', height: '100%'}}>
                    <Button
                        startIcon={<ChevronLeftIcon />}
                        onClick={() => setSelectedTeam(null)}>Back to teams
                    </Button>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: 50}} />
                                    <TableCell align="left">Title</TableCell>
                                    <TableCell align="right">Categories</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, id) => (
                                    <ToolkitRow
                                        key={`${id}-tool`}
                                        {...row}
                                        onChange={(checked) => {
                                            if (checked) {
                                                dispatch({ type: ADD_TOOLKIT_TO_TEAM, payload: { communityId: uuid, teamId: selectedTeam, toolkitId: row._id }})
                                            } else {
                                                dispatch({ type: REMOVE_TOOLKIT_FROM_TEAM, payload: { communityId: uuid, teamId: selectedTeam, toolkitId: row._id }})
                                            }
                                        }}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Slide>
        </Box>
        </>
    )
}

export default ToolkitTab;