import axios from 'axios';

import { refreshToken } from '@/state/auth/actions';
import { isTokenExpired } from './tokenHelpers';
import { setAuthToken } from '../state/auth/services';

// Do not send bearer token if hitting these routes
function needsAuthToken(config = {}) {
    const { url } = config;
    return (
        url === '/v1/auth/token/refresh' ||
        url === '/v1/auth/login' ||
        url === '/v1/auth/logout' ||
        url === '/v1/auth/password/request' ||
        url === '/v1/auth/password/reset'
    );
}

async function requestHandler(config) {
    let token = localStorage.getItem('idToken');
    if (!needsAuthToken(config)) {
        // if it's expired - refresh it!
        if (isTokenExpired(token)) {
            try {
                const { data: tokenData } = await refreshToken();
                setAuthToken(tokenData);
                const { idToken } = tokenData;
                token = idToken;
            } catch (error) {
                // They are unauthenticated - send them to login
                console.info('TCL: requestHandler -> error', error);
            }
        }

        const _config = { ...config };
        _config.headers.Authorization = `Bearer ${token}`;
        return _config;
    }
    return config;
}

export default function(isProd) {
    axios.defaults.baseURL = isProd
        ? window.__env__.BASE_URL
        : process.env.REACT_APP_BASE_URL;

    // set default headers
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    // set interceptor
    axios.interceptors.request.use(
        async config => requestHandler(config),
        error => Promise.reject(error)
    );
}
