import React, {useEffect, useRef, useState} from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {makeStyles} from '@material-ui/core/styles';
import Save from '@material-ui/icons/Save';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import TextInput from '../TextInput/TextInput';

const useViewStyles = makeStyles({
    root: {}
});

const useItemStyles = makeStyles(() => ({
    root: {},
    iconContainer: {},
    label: {
        padding: '0.5rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        marginBottom: '.5rem',
        fontFamily: 'SFUIText-Reg',
        fontSize: '1rem',
        backgroundColor: '#F7F7F7',
        borderRadius: 10
    },
    content: {
        marginLeft: '-1rem'
    },
    selected: {},
}));

const EditableToolkitCategoriesTree = ({nodes, onEditNode, onDeleteNode}) => {
    const classesView = useViewStyles();
    const classesItem = useItemStyles();
    const defaultExpanded = []
    const [category, setCategory] = useState(undefined)
    const [categoryName, setCategoryName] = useState('')

    // const treeItemRef = useRef();
    // useEffect(() => {
    //   const handleClick = e => {
    //     e.preventDefault()
    //     if(treeItemRef && treeItemRef.current){
    //       const ref = treeItemRef.current
    //       if(!ref.contains(e.target)){
    //         setCategory(undefined)
    //       }
    //     }
    //   };
    //
    //   document.addEventListener('click', handleClick);
    //
    //   return () => document.removeEventListener('click', handleClick);
    // }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setCategoryName(value)
    }

    const renderLabel = (node) => {
        return node === category ? (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'}}
            >
                <div style={{width: '82%', marginRight: '.5rem'}}>
                    <TextInput
                        value={categoryName}
                        name="categoryName"
                        placeholder="Name"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <a
                        href=""
                        onClick={e => {
                            e.preventDefault()
                            onDeleteNode(category)
                            setCategoryName('')
                            setCategory(undefined)
                        }}
                        style={{
                            fontSize: '.8rem',
                            textDecoration: 'none',
                            color: '#223F63'
                        }}
                    >
                        <DeleteOutline />
                    </a>
                    <a
                        href=""
                        onClick={e => {
                            e.preventDefault()
                            onEditNode({...category, name: categoryName});
                            setCategoryName('')
                            setCategory(undefined)
                        }}
                        style={{
                            fontSize: '.8rem',
                            textDecoration: 'none',
                            color: '#223F63'
                        }}
                    >
                        <Save />
                    </a>
                </div>
            </div>
        ) : (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'}}
            >
                <div
                    style={{
                        fontFamily: 'SFUIDisplay-SemiBold',
                        fontSize: '.8rem'
                    }}>
                    {node.name}
                </div>
                <a
                    href=""
                    onClick={e => {
                        e.preventDefault()
                        setCategoryName(node.name)
                        setCategory(node)
                    }}
                    style={{
                        fontSize: '.8rem',
                        textDecoration: 'none',
                        color: '#223F63'
                    }}
                >
          Edit
                </a>
            </div>
        )
    }

    return (
        <TreeView
            disableSelection
            classes={classesView}
            defaultExpanded={defaultExpanded}
            sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
            {nodes.length === 0 && <span>No categories yet.</span>}
            {nodes.map((parentNode, parentNodeIndex) => {
                const parentNodeId = `${parentNode.name}${parentNodeIndex}`
                defaultExpanded.push(parentNodeId)

                return (
                    <TreeItem
                        key={parentNodeId}
                        nodeId={parentNodeId}
                        classes={classesItem}
                        label={renderLabel(parentNode)}
                    >
                        {parentNode?.children && parentNode?.children.map((childrenNode, childrenNodeIndex) => {
                            const childrenNodeId = `${childrenNode.name}${childrenNodeIndex}`
                            defaultExpanded.push(childrenNodeId)

                            return (
                                <TreeItem
                                    key={childrenNodeId}
                                    classes={classesItem}
                                    nodeId={childrenNodeId}
                                    label={renderLabel(childrenNode)}
                                >
                                    {childrenNode?.children &&
                    childrenNode?.children.map((grandChildrenNode, grandChildrenNodeIndex) => {
                        const grandChildrenNodeId = `${grandChildrenNode.name}${grandChildrenNodeIndex}`
                        defaultExpanded.push(grandChildrenNodeId)

                        return (
                            <TreeItem
                                classes={classesItem}
                                key={grandChildrenNodeId}
                                nodeId={grandChildrenNodeId}
                                label={renderLabel(grandChildrenNode)}
                            />)
                    })}
                                </TreeItem>
                            )
                        })}
                    </TreeItem>
                )
            })}
        </TreeView>
    )
}

export default EditableToolkitCategoriesTree