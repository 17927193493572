import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Container from '@material-ui/core/Container';

import TextInput from '../../TextInput/TextInput';
import PasswordInput from '../../PasswordInput/PasswordInput';
import Checkbox from '../../Checkbox/Checkbox';
import Button from '../../Button/Button';

const styles = makeStyles(theme => ({
    paper: {
        maxWidth: 480,
        borderRadius: 40,
        padding: '68px 44px 49px 41px'
    },
    m20: {
        marginBottom: 20
    },
    textCenter: {
        textAlign: 'center'
    },
    loginButton: {
        width: 150
    },
    helperRoot: {
        color: '#f20046',
        fontSize: 14,
        fontWeight: 300
    },
    helperError: {
        color: '#f20046',
        textAlign: 'end',
        paddingRight: 7
    }
}));

export default function LoginCard({
    loading,
    email,
    password,
    remember,
    handleTextChange,
    handleCheck,
    handleSubmit,
    emailError,
    passwordError,
    globalError
}) {
    const classes = styles();

    const canSubmit = (email !== '' && password !== '') || loading;

    function onPressEnter(e) {
        if (e.key === 'Enter' && canSubmit) {
            handleSubmit();
        }
    }

    return (
        <Container maxWidth="sm">
            <Grid container onKeyPress={onPressEnter}>
                <Grid item xs={12} className={classes.m20}>
                    {globalError.length > 0 && (
                        <FormHelperText
                            classes={{
                                root: classes.helperRoot,
                                error: classes.helperError
                            }}
                        >
                            {globalError}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} className={classes.m20}>
                    <TextInput
                        name="email"
                        label="Email address"
                        disabled={loading}
                        value={email}
                        onChange={handleTextChange}
                        error={Boolean(emailError)}
                        errorMessage={emailError}
                    />
                </Grid>
                <Grid item xs={12} className={classes.m20}>
                    <PasswordInput
                        name="password"
                        label="Password"
                        disabled={loading}
                        value={password}
                        onChange={handleTextChange}
                    />
                </Grid>
                <Grid item xs={12} className={clsx(classes.textCenter, classes.m20)}>
                    <Checkbox
                        label="Remember me"
                        checked={remember}
                        onChange={handleCheck}
                        disabled={loading}
                        error={Boolean(passwordError)}
                        errorMessage={passwordError}
                    />
                </Grid>
                <Grid item xs={12} className={classes.textCenter}>
                    <Button
                        label="Log in"
                        loading={loading}
                        disabled={!canSubmit}
                        onKeyPress={onPressEnter}
                        onClick={handleSubmit}
                        className={classes.loginButton}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

LoginCard.propTypes = {
    loading: PropTypes.bool,
    email: PropTypes.string,
    password: PropTypes.string,
    remember: PropTypes.bool,
    handleTextChange: PropTypes.func.isRequired,
    handleCheck: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    emailError: PropTypes.string,
    passwordError: PropTypes.string,
    globalError: PropTypes.string
};

LoginCard.defaultProps = {
    loading: false,
    email: '',
    password: '',
    remember: false,
    emailError: '',
    passwordError: '',
    globalError: ''
};
