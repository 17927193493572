import axios from 'axios';

const API_V2_BASE_URL = process.env.REACT_APP_V2_BASE_URL;

// eslint-disable-next-line import/prefer-default-export
export const getCommunityTeams = async communityId => {
    const result = await axios.get(`/v1/communities/${communityId}/teams`)
    return result.data
}

export const getAllTeams = async (communityId, leaderReq = false) => {
    try {
        const url = `${API_V2_BASE_URL}/v1/teams`;
        const result = await axios.get(url);

        return result.data;
    } catch (error) {
        throw error;
    }
};