import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import Button from '../../components/Button/Button';

import * as modalTypes from '../../state/modal/types';

const styles = makeStyles({
    title: {
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 24,
        marginBottom: 10
    },
    text: {
        fontFamily: 'SFUIDisplay-Light',
        fontSize: 14
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 36
    }
});

const PromptBooleanModal = ({
    title,
    message,
    yesLabel,
    noLabel,
    onYes,
    onNo,
    savingSelector
}) => {
    const dispatch = useDispatch();
    const classes = styles();

    const handleNo = () => {
        if (onNo === null)
            return dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        return onNo();
    };

    const saving = savingSelector
        ? useSelector(state => savingSelector(state))
        : false;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography align="center" variant="h1" className={classes.title}>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" className={classes.text}>
                    {message}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.buttonContainer}>
                    <Button
                        label={noLabel}
                        background="grey"
                        style={{ color: '#077abe', marginRight: 10 }}
                        onClick={handleNo}
                    />
                    <Button
                        className={classes.actionButton}
                        label={yesLabel}
                        onClick={onYes}
                        disabled={saving}
                        loading={saving}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

PromptBooleanModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    yesLabel: PropTypes.string,
    onYes: PropTypes.func,
    noLabel: PropTypes.string,
    onNo: PropTypes.func,
    savingSelector: PropTypes.func
};

PromptBooleanModal.defaultProps = {
    yesLabel: 'Yes',
    noLabel: 'No',
    onNo: null,
    title: 'Are you sure?',
    message: 'This cannot be undone.',
    savingSelector: null,
    onYes: null
};

export default PromptBooleanModal;
