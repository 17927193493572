import {groupBy} from 'lodash';

/**
 * Flattens the nested categories into an array of strings.
 *
 * @param categories
 * @returns {*[]}
 */
export const flattenCategories = (categories) => {
    const categoriesList = []

    if (categories.length > 0) {
        for(let counter = 0; counter < categories.length; ++counter) {
            if (categories[counter].checked) {
                categoriesList.push(categories[counter].name)
            }

            if (categories[counter]?.children) {
                for(let childNodeCounter = 0; childNodeCounter < categories[counter].children.length; ++childNodeCounter) {
                    if (categories[counter].children[childNodeCounter].checked) {
                        categoriesList.push(categories[counter].children[childNodeCounter].name);
                    }

                    if (categories[counter].children[childNodeCounter].children) {
                        for(
                            let grandChildNodeCounter = 0;
                            grandChildNodeCounter < categories[counter].children[childNodeCounter].children.length;
                            ++grandChildNodeCounter
                        ) {
                            if (categories[counter].children[childNodeCounter].children[grandChildNodeCounter].checked) {
                                categoriesList.push(categories[counter].children[childNodeCounter].children[grandChildNodeCounter].name);
                            }
                        }
                    }
                }
            }
        }
    }

    return categoriesList
}

/**
 * Recursively parse stringified json toolkit content.
 * @param content
 * @returns {*}
 */
export const contentParser = (content) => {
    if (typeof content === 'string') {
        return contentParser(JSON.parse(content))
    }

    return content
}

/**
 * Group toolkit categories.
 * @param categories
 * @returns {*[]}
 */
export const groupCategories = (categories) => {
    let arr = []

    const grouped = groupBy(categories, 'parent')

    for (const groupedKey in grouped) {
        if (groupedKey === '') continue

        if (grouped[groupedKey].length > 0) {
            for (const gk in grouped) {
                if (gk === '') continue

                if (gk === groupedKey) continue

                for(let counter = 0; counter < grouped[groupedKey].length; counter++) {
                    if (grouped.hasOwnProperty(grouped[groupedKey][counter].id)) {
                        grouped[groupedKey][counter].children = grouped[grouped[groupedKey][counter].id]
                        delete grouped[grouped[groupedKey][counter].id]
                    }
                }
            }
        }
    }

    if ('' in grouped) {
        arr = grouped['']
        delete grouped[''];
    }

    return arr.map(tc => {
        tc.children = grouped[tc.id]
        delete grouped[tc.id]
        return tc
    })
}

/**
 * Generate node ids for toolkit categories.
 * @param toolkitCategories
 * @returns {*[]}
 */
export const getNodeIds = (toolkitCategories) => {
    const nodeIds = []

    groupCategories(toolkitCategories).map((parentNode, parentNodeIndex) => {
        const parentNodeId = `${parentNode.name}${parentNodeIndex}`
        nodeIds.push(parentNodeId)

        parentNode?.children && parentNode?.children.map((childrenNode, childrenNodeIndex) => {
            const childrenNodeId = `${childrenNode.name}${childrenNodeIndex}`
            nodeIds.push(childrenNodeId)

            childrenNode?.children &&
            childrenNode?.children.map((grandChildrenNode, grandChildrenNodeIndex) => {
                const grandChildrenNodeId = `${grandChildrenNode.name}${grandChildrenNodeIndex}`
                nodeIds.push(grandChildrenNodeId)
            });
        })
    })

    return nodeIds;
}