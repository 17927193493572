import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '../../components/Button/Button';
import TextInput from '../../components/TextInput/TextInput';
import * as modalTypes from '../../state/modal/types';
import * as communityTypes from '../../state/communities/types';

import {
    selectSelectedCommunity,
    selectCommunitySavingState,
    selectCommunityApiErrors
} from '../../state/communities/reducers';
import { usePrevious } from '../../utils/customHooks';

import {
    validateEditCommunity,
    getErrorMessage,
    parseApiErrors
} from '../../constants/validation';

const styles = makeStyles({
    modalTitle: {
        fontFamily: 'SFUIDisplay-Bold',
        fontSize: 24,
        marginBottom: 30
    },
    mb30: {
        marginBottom: 30
    },
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: 125
    }
});

const EditCommunityModal = props => {
    const classes = styles();
    const {
        closeModal,
        editCommunity,
        selectedCommunity,
        saveState,
        apiErrors,
        clearApiErrors
    } = props;

    const [errors, setErrors] = useState(null);
    const [values, setValues] = useState({
        title: selectedCommunity.title || '',
        description: selectedCommunity.description || ''
    });

    // Deconstruct the state!
    const { title, description } = values;

    useEffect(() => {
        if (selectedCommunity !== null) {
            clearApiErrors();
            setValues({
                title: selectedCommunity.title,
                description: selectedCommunity.description
            });
        }
    }, [selectedCommunity]);

    // A bit of magic required to close modal when appropriate
    const prevSaving = usePrevious(saveState);
    useEffect(() => {
        if (prevSaving && !saveState && !apiErrors) {
            closeModal();
        }
    }, [prevSaving, saveState, apiErrors]);

    const handleChange = e => {
        const { name, value } = e.target;

        // If they have submitted and had errors - check on change and update accordingly
        if (errors !== null) {
            const hasError = validateEditCommunity({ ...values, [name]: value });
            if (!hasError) setErrors(null);
            else setErrors(hasError);
        }

        setValues({
            ...values,
            [name]: value
        });
    };

    const submitForm = () => {
        // Validate the form
        const errorMessages = validateEditCommunity(values);
        if (errorMessages === undefined) {
            setErrors(null);
            editCommunity(selectedCommunity._id, title, description);
        } else setErrors(errorMessages);
    };

    const computedErrors =
        errors || parseApiErrors(apiErrors, communityTypes.UPDATE_COMMUNITY_ERROR);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography align="center" className={classes.modalTitle}>
                    Edit community details
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.mb30}>
                <TextInput
                    name="title"
                    label="Community name"
                    value={title}
                    onChange={handleChange}
                    error={Boolean(getErrorMessage(computedErrors, 'title'))}
                    errorMessage={getErrorMessage(computedErrors, 'title')}
                />
            </Grid>
            <Grid item xs={12} className={classes.mb30}>
                <TextInput
                    name="description"
                    label="Short description"
                    value={description}
                    multiline
                    rows={6}
                    onChange={handleChange}
                    error={Boolean(getErrorMessage(computedErrors, 'description'))}
                    errorMessage={getErrorMessage(computedErrors, 'description')}
                />
            </Grid>
            <Grid item xs={12} className={classes.flex}>
                <Button
                    className={classes.button}
                    label="Save"
                    onClick={submitForm}
                    disabled={saveState}
                    loading={saveState}
                />
            </Grid>
        </Grid>
    );
};

EditCommunityModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    editCommunity: PropTypes.func.isRequired,
    selectedCommunity: PropTypes.object.isRequired,
    saveState: PropTypes.bool.isRequired,
    apiErrors: PropTypes.array,
    clearApiErrors: PropTypes.func.isRequired
};

EditCommunityModal.defaultProps = {
    apiErrors: null
};

const mapStateToProps = state => ({
    selectedCommunity: selectSelectedCommunity(state),
    saveState: selectCommunitySavingState(state),
    apiErrors: selectCommunityApiErrors(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            }),
            editCommunity: (communityId, title, description) => ({
                type: communityTypes.UPDATE_COMMUNITY,
                communityId,
                title,
                description
            }),
            clearApiErrors: () => ({
                type: communityTypes.CLEAR_COMMUNITY_API_ERRORS
            })
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EditCommunityModal);
